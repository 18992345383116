import { getPositionProp$ } from "@/api"
import { TabButton } from "@/components/Tabs"
import { withBlurAndHide } from "@/utils/withLoading"
import { state, Subscribe, useStateObservable } from "@react-rxjs/core"
import React, { useState } from "react"
import { combineLatest, map, merge } from "rxjs"
import { usePositionContext } from "../../Position.context"
import { deltaCost$ } from "../state/cost"
import { collateralUsed$ } from "../state/leverage"
import { quantityDelta$, quantityEdit$ } from "../state/quantity"
import { areInputsValidAndHasDeltas$ } from "../state/validations"
import {
  EditTransactionSummary,
  EditTransactionSummary$,
} from "./EditTransactionSummary"
import { Payment } from "./Payment"
import {
  ResultingPositionSummary,
  ResultingPositionSummary$,
} from "./ResultingPositionSummary"

export enum SummaryTab {
  TRANSACTION = "Transaction Details",
  RESULTING = "Resulting Position",
}

const editPositionPayment$ = state((id: bigint) =>
  combineLatest([deltaCost$(id), getPositionProp$(id, "quote")]).pipe(
    map(([{ collateralUsed }, quote]) => {
      return (
        <div className="flex flex-col gap-3 text-base">
          <Payment
            amount={collateralUsed}
            currency={quote}
            testId="edit-position--payment"
          />
        </div>
      )
    }),
    withBlurAndHide(quantityEdit$(id)),
  ),
)

export const Summary$ = state((id: bigint) =>
  merge(
    areInputsValidAndHasDeltas$(id),
    EditTransactionSummary$(id),
    ResultingPositionSummary$(id),
    collateralUsed$(id),
    editPositionPayment$(id),
  ),
)

export const summaryState$ = state((id: bigint) =>
  combineLatest([areInputsValidAndHasDeltas$(id), quantityDelta$(id)]).pipe(
    map(([hasDelta, quantityDelta]) => {
      if (!hasDelta) return null
      else if (quantityDelta === 0n) return "equity"
      else if (quantityDelta > 0n) return "quantity_increase"
      else return "quantity_decrease"
    }),
  ),
)

export const EditPositionSummary: React.FC = () => {
  const { id } = usePositionContext()

  const isVisible = useStateObservable(areInputsValidAndHasDeltas$(id))

  const [selectedTab, setSelectedTab] = useState<SummaryTab>(
    SummaryTab.RESULTING,
  )
  return !isVisible ? null : (
    <div>
      <div className="flex gap-3 pb-3 mx-4">
        <TabButton
          testId="edit-summary--resulting-button"
          isActive={selectedTab === SummaryTab.RESULTING}
          className="text-sm pb-1 w-auto"
          onClick={() => setSelectedTab(SummaryTab.RESULTING)}
        >
          {SummaryTab.RESULTING}
        </TabButton>
        <TabButton
          testId="edit-summary--transaction-button"
          isActive={selectedTab === SummaryTab.TRANSACTION}
          className="text-sm pb-1 w-auto"
          onClick={() => setSelectedTab(SummaryTab.TRANSACTION)}
        >
          {SummaryTab.TRANSACTION}
        </TabButton>
      </div>
      <Subscribe>
        <div
          className={`${selectedTab === SummaryTab.RESULTING ? "" : "hidden"}`}
        >
          <ResultingPositionSummary />
        </div>
        <div
          className={`${
            selectedTab === SummaryTab.TRANSACTION ? "" : "hidden"
          }`}
        >
          <EditTransactionSummary />
        </div>
        {editPositionPayment$(id)}
      </Subscribe>
    </div>
  )
}
