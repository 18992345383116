import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { concat, exhaustMap, withLatestFrom } from "rxjs"

import { JsonRpcProvider } from "@json-rpc-tools/provider"
import { Network } from "../generated"
import { ChainId } from "../types"
import { client } from "./state"

const [requestSwitchNetwork$, onRequestSwitchNetwork] = createSignal<ChainId>()
export { onRequestSwitchNetwork }

const switchChain = async (
  chainId: ChainId,
  provider: JsonRpcProvider,
): Promise<null> => {
  const done = await provider
    .request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    })
    .then(() => true)
    .catch((error: any) => (error?.code === 4902 ? false : true))

  if (done) return null

  try {
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [Network[chainId].chainData],
    })
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    })
  } catch (_) {}
  return null
}

export const switchingToNetworksId$ = state(
  requestSwitchNetwork$.pipe(
    withLatestFrom(client.providerPromise),
    exhaustMap(([chainId, provider]) =>
      concat([chainId], switchChain(chainId, provider)),
    ),
  ),
  null,
)
