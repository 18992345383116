import { CurrencyId, Instrument, Side } from "@/api"
import { NoChange } from "@/App/Positions/Position/PositionView/Shared"
import { classNames } from "@/utils/classNames"
import {
  FormatCurrencyOptions,
  formatKnownCurrency,
} from "@/utils/currency-utils"
import { invertIfShort, invertNumber } from "@/utils/inverted-pairs-utis"
import { ReactNode } from "react"
import { CurrencyIcon } from "./CurrencyIcon"

type CurrencyDisplayProps = {
  value: bigint | null
  currencyId: CurrencyId
  icon?: boolean
  className?: string
  prefix?: string
  suffix?: string | number
  testId?: string
  formatOptions?: Partial<FormatCurrencyOptions>
}

export const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({
  value,
  currencyId,
  className,
  formatOptions,
  prefix,
  suffix,
  testId,
  icon,
}) => {
  return (
    <div
      data-testid={testId || "currency-display"}
      className={classNames(
        "inline-flex flex-row whitespace-nowrap justify-end",
        className,
      )}
    >
      {icon && <CurrencyIcon currency={currencyId} className="mr-2" />}
      {prefix ? <span>{prefix}</span> : null}
      <span>
        {`${formatKnownCurrency(value, currencyId, formatOptions)}${
          suffix || ""
        }`}
        {!icon && (
          <span className="text-fontColor-500">{`${" " + currencyId}`}</span>
        )}
      </span>
    </div>
  )
}

export const renderNumber = (
  value: bigint,
  {
    base,
    quote,
    side,
    decimals,
  }: Pick<Instrument, "base" | "quote" | "side" | "decimals">,
) => {
  if (side === Side.Long)
    return formatKnownCurrency(value, quote, { nDecimals: decimals })
  return formatKnownCurrency(invertNumber(value, quote, base), base, {
    nDecimals: decimals,
  })
}

export const renderWithCcy = (
  value: bigint,
  ccy: CurrencyId,
  decimals?: number,
) => (
  <div className="flex gap-1 justify-end">
    <span>
      {formatKnownCurrency(value, ccy, {
        padToDecimals: false,
        nDecimals: decimals,
      })}
    </span>
    <span className="text-fontColor-500">{" " + ccy}</span>
  </div>
)

export const renderNumberWithInversion = (
  value: bigint,
  {
    base,
    quote,
    side,
    decimals,
  }: Pick<Instrument, "base" | "quote" | "side" | "decimals">,
  testId?: string,
) => {
  const [res, ccy] = invertIfShort(value, { base, quote, side })
  return (
    <CurrencyDisplay
      formatOptions={{ nDecimals: decimals }}
      value={res}
      currencyId={ccy}
      testId={testId}
    />
  )
}

export const formatDelta = (
  delta: bigint,
  ccy: CurrencyId,
  testId: string,
  formatOptions: Partial<FormatCurrencyOptions> = {},
  noChange?: ReactNode,
) => {
  if (delta === 0n && noChange !== undefined)
    return <NoChange testId={testId} />

  return (
    <CurrencyDisplay
      value={delta}
      currencyId={ccy}
      prefix={delta > 0 ? "+" : ""}
      testId={testId}
      formatOptions={formatOptions}
    />
  )
}
