import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App/App"
import "./index.css"

const root = createRoot(document.getElementById("root")!)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)

// only enabled on localhost
if (import.meta.url.indexOf("localhost") > 0) {
  const devClass = "devtools-debug"
  const originalClasses = document.body.className

  // add devtools-debug class to the body when a shortcut is pressed
  document.addEventListener("keydown", (event) => {
    if (
      (event.ctrlKey || event.altKey) &&
      event.shiftKey &&
      event.code === "KeyD"
    ) {
      const hasDevClass = document.body.className.endsWith(devClass)
      document.body.className = hasDevClass
        ? originalClasses
        : `${originalClasses} ${devClass}`
      console.log("import.meta", hasDevClass, document.body.className)
    }
  })
}
