import { PositionExpiryStatus } from "@/api"
import { Tooltip } from "@/components/Tooltip"
import { tooltipMapper } from "@/components/tooltipContent"
import { usePositionContext } from "./Position.context"
import { ExpiredPositionActions, PositionActions } from "./PositionActions"
import { PositionCell, PositionRow, TableHeader } from "./PositionList"
import {
  closingPriceJsx$,
  CurrencyPair,
  entryPriceJsx$,
  Equity,
  expiredClosingPrice$,
  fees$,
  leverageJsx$,
  liquidationPrice$,
  marginJsx$,
  markPrice$,
  PositionSide,
  quantityJsx$,
} from "./PositionView/Shared"

export const ActivePositionRow: React.FC<{
  status: PositionExpiryStatus
  children?: React.ReactNode
}> = ({ status, children }) => {
  const { id, instrument } = usePositionContext()
  const isActive = status === PositionExpiryStatus.Active

  return (
    <PositionRow testId="position--active">
      <PositionCell className=" md:sticky text-left">
        <span data-testid="position--id">{"#" + id}</span>
      </PositionCell>
      <PositionCell className="md:sticky left-[-1px] text-left w-[140px]">
        <CurrencyPair id={id} />
        <div className="flex gap">
          {leverageJsx$(id)}
          <PositionSide testId="position--side" side={instrument.side} />
        </div>
      </PositionCell>
      <PositionCell testId="position--size">{quantityJsx$(id)}</PositionCell>
      <PositionCell testId="position--equity-pnl">
        <Equity id={id} status={status} />
      </PositionCell>
      <PositionCell testId="position--entryPrice">
        {entryPriceJsx$(id)}
      </PositionCell>
      <PositionCell testId="position--markPrice">{markPrice$(id)}</PositionCell>
      <PositionCell testId="position--liqPrice">
        {liquidationPrice$(id)}
      </PositionCell>
      <PositionCell>{marginJsx$(id)}</PositionCell>
      <PositionCell className="sticky right-[-1px] text-right rounded-r-lg">
        {isActive ? <PositionActions /> : <ExpiredPositionActions />}
        {children}
      </PositionCell>
      <PositionCell className="hidden">
        {isActive ? closingPriceJsx$(id) : expiredClosingPrice$(id)}
      </PositionCell>
      <PositionCell className="hidden">{fees$(id)}</PositionCell>
    </PositionRow>
  )
}

export const ActivePositionHeaders: React.FC<{}> = () => {
  return (
    <>
      <TableHeader className="text-left">ID</TableHeader>
      <TableHeader className="md:sticky left-[-1px] text-left">
        Position
      </TableHeader>
      <TableHeader>Size</TableHeader>
      <TableHeader>
        <Tooltip message={tooltipMapper("equity")}>Equity</Tooltip>/
        <Tooltip message={tooltipMapper("pnl")}>PnL (ROI%)</Tooltip>
      </TableHeader>
      <TableHeader>
        <Tooltip message={tooltipMapper("entryPrice")}>Entry Price</Tooltip>
      </TableHeader>
      <TableHeader>
        <Tooltip message={tooltipMapper("markPrice")}>Mark Price</Tooltip>
      </TableHeader>
      <TableHeader>
        <Tooltip message={tooltipMapper("liquidationPrice")}>
          Liq. Price
        </Tooltip>
      </TableHeader>
      <TableHeader>
        <Tooltip message={tooltipMapper("margin")}>Margin %</Tooltip>
      </TableHeader>
      <TableHeader className="sticky right-[-1px] text-center">
        <span className="sr-only 2xl:not-sr-only">Actions</span>
      </TableHeader>
    </>
  )
}
