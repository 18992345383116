import { Base, Instrument, Quote } from "@/api"
import { createContext, useContext } from "react"

const positionContext = createContext<{
  base: Base
  quote: Quote
  id: bigint
  instrument: Instrument
}>({} as any)
export const usePositionContext = () => useContext(positionContext)

export const PositionContextProvider = positionContext.Provider
