import { withPrefix } from "@/utils/test-utils"

export const Margin: React.FC<{
  margin: number
  minMargin: number
  showSuffix?: boolean
  testIdPrefix?: string
}> = ({ margin, minMargin, showSuffix = true, testIdPrefix }) => {
  return (
    <div className="flex flex-row items-center">
      <span
        data-testid={withPrefix("margin", testIdPrefix)}
        className="mr-1"
      >{`${margin}%`}</span>
      {showSuffix ? (
        <span
          data-testid={withPrefix("margin-min", testIdPrefix)}
          className="text-fontColor-500 text-xs"
        >
          {`(min: ${minMargin}%)`}
        </span>
      ) : null}
    </div>
  )
}
