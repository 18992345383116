import {
  Base,
  Instrument,
  InstrumentId,
  instruments$,
  Maturity,
  Quote,
  Side,
} from "@/api"
import { latestBlockTimestamp$ } from "@/api/chain/common"
import { SECONDS_IN_DAY } from "@/utils/constants"
import { mapDistinct } from "@/utils/observable-utils"
import { ObservableType } from "@/utils/types"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { combineLatest, map, startWith, switchMap } from "rxjs"

export type Pair = {
  id: string
  base: Base
  quote: Quote
  Long: InstrumentId[]
  Short: InstrumentId[]
}

const pairs$ = state(
  instruments$.pipe(
    map((instruments) => {
      return Object.values(instruments).reduce((acc, instrument) => {
        const { quote, base, side, id } = instrument
        const pair = side === "Long" ? `${base}/${quote}` : `${quote}/${base}`
        const obj = acc[pair] || { id: pair, base, quote, Long: [], Short: [] }
        const arr = obj[side] || []
        return {
          ...acc,
          [pair]: {
            ...obj,
            [side]: [...arr, id],
          },
        }
      }, {} as Record<string, Pair>)
    }),
  ),
)

const [selectedPairChanged$, onSelectedPairChanged] =
  createSignal<keyof ObservableType<typeof pairs$>>()
export const selectedPair$ = state(
  combineLatest([pairs$, selectedPairChanged$.pipe(startWith(""))]).pipe(
    map(([pairs, selectedPair]) =>
      pairs[selectedPair] ? pairs[selectedPair] : pairs[Object.keys(pairs)[0]],
    ),
  ),
)

export { onSymbolChange, onSelectedPairChanged, onSideChanged }
export { userSearch$, onUserSearchChange }

const [userSearchChanged$, onUserSearchChange] = createSignal<string>()
const userSearch$ = state(userSearchChanged$, "")

export const filteredPairs$ = state(
  userSearch$.pipe(
    switchMap((search) =>
      pairs$.pipe(
        map((pairs) =>
          Object.values(pairs).filter((pair) =>
            pair.id.toLowerCase().includes(search.toLowerCase()),
          ),
        ),
      ),
    ),
  ),
)

const invertSide = (side: Side): Side => (side === "Short" ? "Long" : "Short")
const [_side$, onSideChanged] = createSignal<Side>()
const selectedSide$ = state(_side$, "Long")

export const sideAndInstrumentsToQuote$ = state(
  combineLatest([selectedSide$, selectedPair$]).pipe(
    map(([selectedSide, selectedPair]) => {
      const side =
        selectedPair[selectedSide].length > 0
          ? selectedSide
          : invertSide(selectedSide)
      const instrumentsToQuote = selectedPair[side]
      return { side, instrumentsToQuote }
    }),
  ),
)

export const side$ = state(
  sideAndInstrumentsToQuote$.pipe(map(({ side }) => side)),
)
export const instrumentsToQuote$ = state(
  sideAndInstrumentsToQuote$.pipe(
    map(({ instrumentsToQuote }) => instrumentsToQuote),
  ),
)

const [symbolChanged$, onSymbolChange] = createSignal<InstrumentId>()

// If the currently selected instrument is no longer available for trading (expired/expiring),
// we switch to a defaulted instrument (index 0). If the currently selected instrument is not one of
// the expiring instruments, we respect the users selection and don't switch.
export const instrument$ = state(
  combineLatest([
    instrumentsToQuote$,
    symbolChanged$.pipe(startWith(null)),
  ]).pipe(
    map(([instruments, selectedSymbol]) => {
      const allowedSymbols = new Set(instruments)
      if (!selectedSymbol || !allowedSymbols.has(selectedSymbol))
        return Instrument[instruments[0]]
      return Instrument[selectedSymbol]
    }),
  ),
)

export const secondsTilExpiry$ = state((symbol: InstrumentId) =>
  latestBlockTimestamp$.pipe(
    map(
      (chainTimestamp) =>
        Maturity[Instrument[symbol].maturity].timestamp - chainTimestamp,
    ),
  ),
)

export const daysUntilExpiry$ = state((symbol: InstrumentId) =>
  secondsTilExpiry$(symbol).pipe(
    mapDistinct((secondsTilExpiry) =>
      Math.floor(secondsTilExpiry / SECONDS_IN_DAY),
    ),
  ),
)
