import { HistoryItem } from "@/api/graphql/positions"
import {
  SummaryRow,
  SummaryTable,
} from "@/App/Positions/Position/PositionEdit/Summary/SummaryTable"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { divideCurrency } from "@/utils/currency-utils"
import { formatDate } from "@/utils/mappers"

const testId = (field: string) => "create-transaction-summary--" + field

export const CreateTransactionSummary: React.FC<HistoryItem> = ({
  openQuantity,
  openCost,
  blockTimestamp,
  cashflowQuote,
  feeQuote,
  instrument: { base, quote },
}) => {
  return (
    <>
      <SummaryTable>
        <SummaryRow title="Date and time">
          <div data-testid={testId("date")}>{formatDate(blockTimestamp)}</div>
        </SummaryRow>
        <SummaryRow title="Quantity">
          <CurrencyDisplay
            value={openQuantity}
            currencyId={base}
            testId={testId("quantity")}
          />
        </SummaryRow>
        <SummaryRow title="Collateral">
          <CurrencyDisplay
            value={cashflowQuote}
            currencyId={quote}
            testId={testId("collateral")}
          />
        </SummaryRow>
        <SummaryRow title="Entry Price">
          <CurrencyDisplay
            value={divideCurrency(
              { value: openCost, currency: quote },
              { value: openQuantity, currency: base },
            )}
            currencyId={quote}
            testId={testId("entryPrice")}
          />
        </SummaryRow>
        <SummaryRow title="Fee">
          <CurrencyDisplay
            value={feeQuote}
            currencyId={quote}
            testId={testId("fee")}
          />
        </SummaryRow>
      </SummaryTable>
      <div className="text-ml flex flex-row gap-2 justify-center">
        You deposited
        <CurrencyDisplay value={cashflowQuote} currencyId={quote} />
      </div>
    </>
  )
}
