import { getPosition$ } from "@/api"
import { ApprovalType, useApprovalType } from "@/App/state/approvals"
import { withBlurryOverlay } from "@/components/BlurryOverlay"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import { PrimaryButton } from "@/components/PrimaryButton"
import { SecondaryButton } from "@/components/SecondaryButton"
import {
  liftSuspense,
  RemoveSubscribe,
  state,
  useStateObservable,
} from "@react-rxjs/core"
import React, { Suspense } from "react"
import { map, merge, switchMap } from "rxjs"
import { usePositionContext } from "../Position.context"
import {
  PositionEditLeverageSlider,
  PositionEditLeverageSlider$,
} from "./LeverageSlider"
import { PositionHeader } from "./PositionHeader"
import { Quantity, Quantity$ } from "./Quantity"
import {
  isSubmittingEditApproval$,
  onUserSubmitEditApproval,
} from "./state/approvals"
import { EditType, onEditOrCancel, positionEditFlow$ } from "./state/base"
import { formChangeEvents$, formErrors$ } from "./state/errors"
import { isClosingPosition$ } from "./state/quantity"
import {
  isFormValid$,
  isSubmitting$,
  needsApproval$,
  onUserSubmitEdit,
} from "./state/submitting"
import { ClosingPositionSummary, EditPositionSummary } from "./Summary"
import { ClosingPositionSummary$ } from "./Summary/ClosingPositionSummary"
import { EditBalanceTooLowErrorMessage } from "./Summary/EditBalanceTooLowErrorMessage"
import { Summary$ } from "./Summary/EditPositionSummary"

const SubmittingOverlay = withBlurryOverlay(() => {
  const { id } = usePositionContext()
  const isSubmittingHistoryItem = useStateObservable(isSubmitting$(id))
  const isSubmittingApproval = useStateObservable(isSubmittingEditApproval$(id))
  return isSubmittingHistoryItem || isSubmittingApproval
})

const ApproveSpending: React.FC<{ disabled: boolean; id: bigint }> = ({
  disabled,
  id,
}) => {
  const isSubmittingApproval = useStateObservable(isSubmittingEditApproval$(id))

  return (
    <PrimaryButton
      testId="position-edit--approve-spending"
      className="w-full"
      disabled={disabled || isSubmittingApproval}
      onClick={onUserSubmitEditApproval}
    >
      {isSubmittingApproval ? "Awaiting Approval" : "Approve Spending"}
    </PrimaryButton>
  )
}

const SubmittingButton: React.FC<{ title: string }> = ({ title }) => {
  const { id } = usePositionContext()
  const isFormValid = useStateObservable(isFormValid$(id))
  const isSubmitting = useStateObservable(isSubmitting$(id))

  const approvalType = useApprovalType()
  const needsApproval =
    useStateObservable(needsApproval$(id)) &&
    approvalType !== ApprovalType.Permit

  return needsApproval ? (
    <ApproveSpending disabled={!isFormValid} id={id} />
  ) : (
    <PrimaryButton
      className="w-full"
      testId="position-edit--submit"
      disabled={!isFormValid}
      onClick={onUserSubmitEdit}
    >
      {isSubmitting ? "Awaiting Transaction..." : title}
    </PrimaryButton>
  )
}

const cantRetrieveQuote$ = state(
  (positionId: bigint) =>
    formErrors$(positionId, "deltaCost").pipe(
      map((cost) => Boolean(cost && cost.error)),
    ),
  false,
)

export const PositionEdit$ = (positionId: bigint) =>
  positionEditFlow$(positionId).pipe(
    switchMap((type) =>
      type === EditType.none
        ? []
        : merge(
            getPosition$(positionId),
            Quantity$(positionId),
            PositionEditLeverageSlider$(positionId),
            isSubmittingEditApproval$(positionId),
            Summary$(positionId),
            ClosingPositionSummary$(positionId),

            formErrors$(positionId, "deltaCost"),
            formChangeEvents$(positionId),

            cantRetrieveQuote$(positionId),
          ),
    ),
    liftSuspense(),
  )

const UnableToRetrieveQuoteErrorMessage: React.FC = () => {
  const { id } = usePositionContext()

  const cantRetrieveQuote = useStateObservable(cantRetrieveQuote$(id))

  return (
    <FormMessage
      testId="edit-position--cannot-retrieve-quote"
      iconType={IconType.Error}
      visible={Boolean(cantRetrieveQuote)}
    >
      <p>
        We were unable to retrieve a quote. This could be due to insufficient
        liquidity, or an unexpected error in the contract.
      </p>
    </FormMessage>
  )
}

export const PositionEdit: React.FC = () => {
  const { id, instrument } = usePositionContext()
  const isClosing = useStateObservable(isClosingPosition$(id))

  return (
    <RemoveSubscribe>
      <Suspense fallback={null}>
        <div className="bg-backgrounds-200 rounded-t-xl inline-block w-full">
          <SubmittingOverlay>
            <div className="font-primary rounded-lg m-4 max-w-[350px] flex flex-col gap-3">
              <PositionHeader
                instrument={instrument}
                testIdPrefix="position-edit"
              />
              <Quantity />
              {isClosing ? null : <PositionEditLeverageSlider />}
            </div>
            <div className="m-4 mb-0">
              {isClosing ? <ClosingPositionSummary /> : <EditPositionSummary />}
            </div>
            <div className="mx-4 gap-2 flex-col flex">
              <EditBalanceTooLowErrorMessage />
              <UnableToRetrieveQuoteErrorMessage />
            </div>
            <div className="m-4 gap-2 flex flex-col">
              <SubmittingButton
                title={isClosing ? "Close Position" : "Update Position"}
              />
              <CancelButton />
            </div>
          </SubmittingOverlay>
        </div>
      </Suspense>
    </RemoveSubscribe>
  )
}

export const PositionClose: React.FC = () => {
  const { instrument } = usePositionContext()
  return (
    <RemoveSubscribe>
      <Suspense fallback={null}>
        <div className="bg-backgrounds-200 rounded-t-lg inline-block w-full">
          <SubmittingOverlay>
            <div className="font-primary rounded-lg m-4 max-w-[350px]">
              <PositionHeader
                instrument={instrument}
                testIdPrefix="position-close"
              />
              <div className="mt-4">
                <ClosingPositionSummary />
              </div>
            </div>
            <div className="m-4 gap-2 flex flex-col">
              <SubmittingButton title="Close Position" />
              <CancelButton />
            </div>
          </SubmittingOverlay>
        </div>
      </Suspense>
    </RemoveSubscribe>
  )
}

const CancelButton = () => {
  const { id } = usePositionContext()
  return (
    <SecondaryButton
      className="w-full"
      testId="position-edit--cancel-button"
      onClick={() => {
        onEditOrCancel(id, EditType.none)
      }}
    >
      Cancel
    </SecondaryButton>
  )
}
