import { Observable } from "rxjs"
import { RunHelpers, TestScheduler } from "rxjs/testing"

export const dependsOn =
  <I>(obs: Observable<I>, emitter: (value: I) => void) =>
  <T>(source$: Observable<T>): Observable<T> =>
    new Observable<T>((observer) => {
      const sub = obs.subscribe(emitter)
      sub.add(source$.subscribe(observer))
      return sub
    })

const scheduler = () =>
  new TestScheduler((actual, expected) => {
    expect(actual).toEqual(expected)
  })

export const marbleTest = (name: string, cb: (args: RunHelpers) => void) =>
  it(name, () => {
    scheduler().run(({ expectObservable: expectObservableO, ...rest }) => {
      const defaultUnsub = `^${Array(1000).join("-")}!`
      const expectObservable = (x: any, unsub = defaultUnsub) =>
        expectObservableO(x, unsub)
      cb({ ...rest, expectObservable })
    })
  })

export const withPrefix = (str: string, prefix?: string) => {
  return prefix ? prefix + "--" + str : str
}
