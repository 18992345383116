import { getOpenPositionIds$ } from "@/api"
import { Loading } from "@/components/Loading"
import { classNames } from "@/utils/classNames"
import { createSignalState } from "@/utils/observable-utils"
import { Subscribe, SUSPENSE, useStateObservable } from "@react-rxjs/core"
import { combineKeys } from "@react-rxjs/utils"
import { Suspense } from "react"
import { map, merge, switchMap } from "rxjs"
import {
  TransactionHistoryHeaders,
  TransactionHistoryTable,
} from "../TradeHistory/components/blotter/TradeHistory"
import { TransactionHistoryFilter } from "../TradeHistory/components/TransactionHistoryFilter"
import {
  latestTransactionBlock$,
  TransactionHistory$,
} from "../TradeHistory/state"
import { ActivePositionHeaders } from "./Position/ActivePosition"
import { ActivePosition, position$ } from "./Position/Position"
import { PositionList } from "./Position/PositionList"

const [selectedTab$, selectTab] = createSignalState<
  "Positions" | "HistoryItem History"
>("Positions")

const PositionsInner: React.FC = () => {
  const ids = useStateObservable(getOpenPositionIds$)
  const selected = useStateObservable(selectedTab$)

  const openPositions =
    selected === "Positions" ? (
      <div className="h-full">
        <PositionList tableHeader={<ActivePositionHeaders />}>
          {ids.length === 0 ? (
            <NoPositionsMessage />
          ) : (
            ids.map((id) => (
              <Subscribe key={id.toString()} fallback={loadingPosition}>
                <ActivePosition id={id} />
              </Subscribe>
            ))
          )}
        </PositionList>
      </div>
    ) : null

  const history =
    selected === "HistoryItem History" ? (
      <div className="h-full">
        <div className="absolute right-0 1 top-0 text-base text-white z-[2] mt-2 flex flex-end">
          <TransactionHistoryFilter />
        </div>
        <PositionList tableHeader={<TransactionHistoryHeaders />}>
          <Suspense fallback={loadingPosition}>
            <TransactionHistoryTable />
          </Suspense>
        </PositionList>
      </div>
    ) : null

  return (
    <div className="flex flex-col h-full min-w-full rounded-lg overflow-x-hidden relative">
      <div className="flex gap-4 h-[40px]">
        <SelectTab
          selected={selected === "Positions"}
          label={"Open positions"}
          count={ids.length}
          testId="blotter-tab--open-positions"
          onClick={() => selectTab("Positions")}
        />
        <SelectTab
          selected={selected === "HistoryItem History"}
          label={"Transaction history"}
          testId="blotter-tab--transaction-history"
          onClick={() => selectTab("HistoryItem History")}
        />
      </div>
      <div className="h-[calc(100%-40px)]">
        {openPositions}
        {history}
      </div>
    </div>
  )
}

export const Positions$ = merge(
  combineKeys(
    getOpenPositionIds$.pipe(map((ids) => (ids === SUSPENSE ? [] : ids))),
    position$,
  ),
  selectedTab$.pipe(
    switchMap((selected) =>
      selected === "Positions" ? [] : TransactionHistory$,
    ),
  ),
  latestTransactionBlock$,
)

export const Positions: React.FC = () => {
  return (
    <div
      data-testid="positions"
      className={classNames(
        "flex mx-auto h-full w-full overflow-x-hidden text-fontColor-0",
      )}
    >
      <Suspense
        fallback={
          <div
            className="mt-20"
            style={{
              width: "140px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Loading size={40} />
          </div>
        }
      >
        <PositionsInner />
      </Suspense>
    </div>
  )
}

const NoPositionsMessage: React.FC = () => {
  return (
    <tr>
      <td
        data-testid="no-open-positions"
        className="whitespace-nowrap pl-4 pt-1 font-primary text-left"
      >
        No open positions
      </td>
    </tr>
  )
}

const loadingPosition = (
  <tr
    data-testid="loading-position"
    className="relative rounded-lg isolate overflow-hidden"
  >
    <td className="rounded-b-lg font-secondary font-normal">
      <div className="flex flex-row h-10 items-center justify-center">
        <Loading size={20} />
      </div>
    </td>
  </tr>
)

const SelectTab: React.FC<{
  selected: boolean
  label: string
  testId: string
  count?: number
  onClick: () => void
}> = ({ selected, label, count, onClick, testId }) => {
  return (
    <div
      className={classNames(
        selected
          ? "text-fontColor-0 border-b-2 border-functional-buy-500"
          : "text-fontColor-400 hover:text-functional-buy-500",
        "py-2 mb-2 px-1 text-sm cursor-pointer",
      )}
      data-testid={testId}
      onClick={onClick}
    >
      <span>{`${label} ${count ? `(${count})` : ""}`}</span>
    </div>
  )
}
