import { CurrencyInput } from "@/components/CurrencyInput"
import { FormMessage } from "@/components/messaging/FormMessage"
import {
  marketImpactAboveLimit,
  MarketImpactWarning,
} from "@/components/messaging/MarketImpactWarning"
import { IconType } from "@/components/NotificationIcon"
import { InputValidationState } from "@/components/NumberInput"
import { PrimaryButton } from "@/components/PrimaryButton"
import { formatKnownCurrency } from "@/utils/currency-utils"
import { absolute } from "@/utils/maths-utils"
import { mapDistinct, unsuspended } from "@/utils/observable-utils"
import { roundToTwo } from "@/utils/rounding"
import { withBlur } from "@/utils/withLoading"
import { state } from "@react-rxjs/core"
import { combineLatest, map, merge, withLatestFrom } from "rxjs"
import { costData$, marketImpact$ } from "./state"
import { formErrors$ } from "./state/error/formErrors"
import {
  onIsQuantityBlurred,
  onQuantityChanged,
  quantity$,
} from "./state/inputs"
import { instrument$ } from "./state/instrument"
import { isBelowMinQuantity$, minQuantity$ } from "./state/validations"
import { eqOrderSizeTestId, minQtyErrorTestId, minQtyTestId } from "./testIds"

const minQtyFormatted$ = state(
  minQuantity$.pipe(
    withLatestFrom(instrument$),
    mapDistinct(([minQty, { base }]) => (
      <span data-testid={minQtyTestId}>
        {roundToTwo(formatKnownCurrency(minQty, base))}
      </span>
    )),
    withBlur(),
  ),
)

const spotCost$ = state(
  costData$.pipe(
    map(({ spotCost, quote }) => (
      <span data-testid={eqOrderSizeTestId}>
        {`(${quote}: ${formatKnownCurrency(absolute(spotCost), quote)})`}
      </span>
    )),
    withBlur(),
  ),
)

// errors take precedence over warnings
const deriveValidationState = (
  isBelowMin: boolean,
  marketImpactAboveLimit: boolean,
) => {
  if (isBelowMin) return InputValidationState.Error
  if (marketImpactAboveLimit) return InputValidationState.Warning
  return InputValidationState.Valid
}

const quantityInputJsx$ = state(
  combineLatest([
    quantity$,
    instrument$,
    isBelowMinQuantity$,
    marketImpact$,
    formErrors$("noLiquidity"),
  ]).pipe(
    map(([quantity, { base }, isBelowMin, marketImpact, noLiquidityError]) => {
      const validationState = deriveValidationState(
        isBelowMin,
        marketImpactAboveLimit(marketImpact),
      )
      const label = <span>Order Size {spotCost$}</span>
      return (
        <CurrencyInput
          htmlFor="quantity"
          label={label}
          value={quantity}
          onChange={onQuantityChanged}
          onBlur={() => onIsQuantityBlurred(true)}
          onFocus={() => onIsQuantityBlurred(false)}
          currency={base}
          testIdPrefix="create-ticket-quantity"
          validationState={validationState}
          disabled={noLiquidityError?.error}
        />
      )
    }),
  ),
  null,
)

const marketImpactWarning$ = state(
  combineLatest([marketImpact$, formErrors$("marketImpact")]).pipe(
    map(([marketImpact, errors]) => (
      <MarketImpactWarning
        testId="create-ticket"
        marketImpact={marketImpact}
        errorFetching={Boolean(errors?.error)}
      />
    )),
  ),
  null,
)

const minQtyWarning$ = state(
  combineLatest([minQuantity$, isBelowMinQuantity$]).pipe(
    map(([minQuantity, isBelowMin]) => (
      <FormMessage
        iconType={IconType.Error}
        className="mt-3"
        testId={minQtyErrorTestId}
        visible={isBelowMin}
      >
        <div className="flex justify-between items-center w-full">
          <span>Below min order size: {minQtyFormatted$}</span>
          <PrimaryButton
            className="h-6 w-fit"
            data-testid={minQtyErrorTestId + "-fix-btn"}
            onClick={() => {
              onQuantityChanged(minQuantity)
            }}
          >
            <span className="text-sm">Click to fix</span>
          </PrimaryButton>
        </div>
      </FormMessage>
    )),
    unsuspended,
  ),
)

export const Quantity$ = merge(
  quantityInputJsx$,
  minQtyWarning$,
  marketImpactWarning$,
  minQtyFormatted$,
  spotCost$,
)

export const Quantity = () => {
  return (
    <div data-testid="create-ticket--quantity" className="flex flex-col gap-2">
      {quantityInputJsx$}
      {minQtyWarning$}
      {marketImpactWarning$}
    </div>
  )
}
