import { getPositionProp$ } from "@/api"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { combineLatest, concat, map, pipe, switchMap, take } from "rxjs"
import { EditType, positionEditFlow$ } from "./base"
import { quantityChanged$ } from "./inputs"

const quantityEdit$ = state((positionId: bigint) =>
  concat(
    positionEditFlow$(positionId)
      .pipe(
        switchMap((type) =>
          type === EditType.edit
            ? getPositionProp$(positionId, "quantity")
            : [0n],
        ),
      )
      .pipe(take(1)),
    quantityChanged$(positionId).pipe(mapDistinct((e) => e.value)),
  ),
)

export { quantityEdit$ }

export enum QuantityValidity {
  VALID = "Valid",
  NIL = "Nil",
  ZERO = "Zero",
}

export type QuantityState =
  | { state: QuantityValidity.NIL }
  | { state: QuantityValidity.ZERO }
  | { state: QuantityValidity.VALID; value: bigint }

export const quantityState$ = state((positionId: bigint) => {
  return quantityEdit$(positionId).pipe(
    map((value): QuantityState => {
      if (value === null) return { state: QuantityValidity.NIL }
      if (value === 0n) return { state: QuantityValidity.ZERO }
      return { state: QuantityValidity.VALID, value }
    }),
  )
})

export const quantityDelta$ = state((positionId: bigint) =>
  combineLatest([
    quantityState$(positionId),
    getPositionProp$(positionId, "quantity"),
  ]).pipe(
    mapDistinct(([quantityEdit, currentQuantity]) => {
      if (quantityEdit.state !== QuantityValidity.VALID) return 0n
      return quantityEdit.value - currentQuantity
    }),
  ),
)

export const isClosingPosition$ = state(
  pipe(
    quantityState$,
    mapDistinct(({ state }) => state === QuantityValidity.ZERO),
  ),
  false,
)
