import { isGnosisSafe } from "@/api"
import { bind } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"

export enum ApprovalType {
  Permit = "Permit",
  TransactionExact = "TransactionExact",
  TransactionInfinite = "TransactionInfinite",
}
const [_approvalType$, _setApprovalType] = createSignal<ApprovalType>()

const localStorageKey = "preferredApprovalMethod"
export const setApprovalType = (type: ApprovalType) => {
  localStorage.setItem(localStorageKey, type)
  _setApprovalType(type)
}

const getPreferredApprovalType = () => {
  const item = localStorage.getItem(localStorageKey) as ApprovalType | null
  return item === null ? ApprovalType.Permit : item
}

export const [useApprovalType, approvalType$] = bind(
  _approvalType$,
  isGnosisSafe() ? ApprovalType.TransactionExact : getPreferredApprovalType(),
)
