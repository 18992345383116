import { classNames } from "@/utils/classNames"
import { DefaultedStateObservable, useStateObservable } from "@react-rxjs/core"
import { NumberInput } from "./NumberInput"

export const SlippageInput: React.FC<{
  slippage$: DefaultedStateObservable<number>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  testIdPrefix: string
  className?: string
}> = ({ slippage$, onChange, testIdPrefix, className }) => {
  const slippageTolerance = useStateObservable(slippage$)
  const maxValue = 5
  const step = 0.01

  return (
    <div className={classNames("justify-end items-end", className)}>
      <NumberInput
        htmlFor="slippage"
        value={slippageTolerance}
        onChange={onChange}
        min={0}
        max={maxValue}
        step={step}
        suffix={"%"}
        testIdPrefix={testIdPrefix}
        className="bg-backgrounds-200 rounded-lg"
      />
    </div>
  )
}

export const Slippage: React.FC<{
  slippage$: DefaultedStateObservable<number>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ slippage$, onChange }) => {
  const slippageTolerance = useStateObservable(slippage$)
  const maxValue = 5
  const step = 0.01

  return (
    <div className="flex flex-row gap-6 pb-4">
      <div className="w-1/4 flex-auto max-w-[100px]">
        <NumberInput
          htmlFor="slippage"
          label="Slippage"
          value={slippageTolerance}
          onChange={onChange}
          min="0"
          max={5}
          step={step}
          suffix={"%"}
        />
      </div>
      <input
        type="range"
        min="0"
        max={maxValue}
        step={step}
        className="text-secondary-00 flex-auto"
        value={slippageTolerance}
        onChange={onChange}
      />
    </div>
  )
}
