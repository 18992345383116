import { solidityFn, solidityError } from "@unstoppablejs/solidity-bindings";
import { uint,Tuple,Struct,int,uint24,uint128,bool } from "solidity-codecs";
import { bytes32str } from "@/api/utils";

const a = [uint] as [positionId: typeof uint];
const b = Struct({deliveryCost:uint,deliveryFee:uint});
const c = Tuple(b);
const d = Struct({positionId:uint,quantity:int,collateralSlippage:uint,uniswapFee:uint24});
const e = [d, uint] as [params: typeof d, leverage: typeof uint];
const f = Struct({spotCost:int,cost:int,financingCost:int,debtDelta:int,collateralUsed:int,minCollateral:int,maxCollateral:int,underlyingDebt:uint,underlyingCollateral:uint,liquidationRatio:uint,fee:uint,minDebt:uint128,baseLendingLiquidity:uint,quoteLendingLiquidity:uint,needsBatchedCall:bool});
const g = Tuple(f);
const h = Struct({symbol:bytes32str,quantity:uint,collateralSlippage:uint,uniswapFee:uint24});
const i = [h, uint] as [params: typeof h, leverage: typeof uint];
const j = [uint, uint24] as [positionId: typeof uint, uniswapFee: typeof uint24];
const k = Struct({spotCost:uint,underlyingDebt:uint,underlyingCollateral:uint,liquidationRatio:uint,liquidating:bool});
const l = Tuple(k);
const m = {};

export const deliveryCostForPosition = solidityFn("deliveryCostForPosition", a, c, 2);
export const modifyCostForPositionWithLeverage = solidityFn("modifyCostForPositionWithLeverage", e, g, 2);
export const openingCostForPositionWithLeverage = solidityFn("openingCostForPositionWithLeverage", i, g, 2);
export const positionStatus = solidityFn("positionStatus", j, l, 2);



export const InsufficientLiquidity = solidityError("InsufficientLiquidity", m);


export interface IContangoQuoter {
  functions: typeof deliveryCostForPosition | typeof modifyCostForPositionWithLeverage | typeof openingCostForPositionWithLeverage | typeof positionStatus,
  events: never,
  errors: typeof InsufficientLiquidity
}