import type { CurrencyId } from "@/api"
import { Currency } from "@/api/generated"
import {
  formatKnownCurrency,
  getDecimalSeparator,
  parseCurrency,
} from "@/utils/currency-utils"
import { useReducer } from "react"

interface State {
  value: bigint | null
  currency: CurrencyId
  text: string
}

interface Reset {
  type: "reset"
  payload: Omit<State, "text">
}
interface TextChanged {
  type: "change"
  payload: Omit<State, "currency">
}

function init({ value, currency }: Reset["payload"]): State {
  return {
    value,
    currency,
    text: formatKnownCurrency(value, currency, { nDecimals: Infinity }),
  }
}

function reducer(state: State, { type, payload }: Reset | TextChanged): State {
  // prettier-ignore
  return type === "change"
    ? { ...state, ...payload }
    : state.value === payload.value && state.currency === payload.currency
      ? state
      : init(payload)
}

function isPendingZero(value: string, currency: CurrencyId, separator: string) {
  const [, decimalPart] = value.split(separator)
  return (
    decimalPart &&
    decimalPart.length <= Currency[currency].precision &&
    decimalPart.endsWith("0")
  )
}

export const useCurrencyInput = (
  value: bigint | null,
  currency: CurrencyId,
  onChange?: (value: bigint | null) => void,
): [
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined,
] => {
  const [state, dispatch] = useReducer(reducer, { value, currency }, init)
  if (state.value !== value || state.currency !== currency) {
    dispatch({ type: "reset", payload: { value, currency } })
  }

  return [
    state.text,
    onChange &&
      (({
        target: { value: eventValue },
      }: React.ChangeEvent<HTMLInputElement>) => {
        let value: bigint | null
        let separator: string | null
        try {
          separator = getDecimalSeparator(eventValue)
          value = parseCurrency(eventValue, currency)
        } catch (_) {
          return
        }

        if (value !== state.value) {
          const formatOptions = {
            nDecimals: Infinity,
            ...(separator && { decimalSeparator: separator }),
          }
          dispatch({
            type: "change",
            payload: {
              value,
              text:
                eventValue.length > state.text.length
                  ? formatKnownCurrency(value, currency, formatOptions)
                  : eventValue,
            },
          })
          onChange(value)
          return
        }

        if (
          eventValue.length < state.text.length ||
          (separator && isPendingZero(eventValue, currency, separator))
        ) {
          dispatch({
            type: "change",
            payload: {
              value,
              text: eventValue,
            },
          })
        }
      }),
  ]
}
