export const WalletErrorType = {
  noProvider: 1,
  overriddenProvider: 2,
  disconnected: 3,
  noAccounts: 4,
  unsupportedNetwork: 5,
} as const

export type WalletErrorType =
  typeof WalletErrorType[keyof typeof WalletErrorType]

export class WalletError extends Error {
  type: WalletErrorType
  constructor(type: WalletErrorType) {
    super()
    this.type = type
  }
}
