import {
  allowance$,
  balance$,
  Currency,
  network$,
  NetworkInstruments,
  Quote,
} from "@/api"
import { CurrencyIcon } from "@/components/CurrencyIcon"
import { loading } from "@/components/Loading"
import { formatKnownCurrency } from "@/utils/currency-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { mapRecord } from "@/utils/record-utils"
import { state } from "@react-rxjs/core"
import { combineKeys } from "@react-rxjs/utils"
import { ReactNode, Suspense } from "react"
import { map, merge } from "rxjs"

const currenciesByChainId = mapRecord(NetworkInstruments, (instruments) => {
  const ccys = new Set(instruments.map((instrument) => instrument.quote))
  return [...ccys]
})

const networkQuotes$ = network$.pipeState(
  map(({ chainData: { chainId } }) => currenciesByChainId[chainId]),
)

const formattedBalance$ = state((ccy: Quote) =>
  balance$(ccy).pipe(
    mapDistinct((balance) => (
      <div data-testid={`balance--${ccy}`}>
        {formatKnownCurrency(balance, ccy)}
      </div>
    )),
  ),
)

const formattedAllowance$ = state((ccy: Quote) =>
  allowance$(ccy).pipe(
    mapDistinct((allowance) => {
      // The correct condition here would be to do 'allowance === MAX_256' ?
      // but as it turns out, USDC on arbitrum does not follow the convention of treating 2 ^ 256 -1
      // as a special number and not subtract spending from the allowance in that case. DAI does however.
      return allowance / Currency[ccy].precisionMultiplier > 1_000_000_000n
        ? "Infinite"
        : formatKnownCurrency(allowance, ccy)
    }),
  ),
)

const Balance: React.FC<{ ccy: Quote }> = ({ ccy }) => (
  <span className="inline-flex items-center text-sm font-medium font-secondary pr-4">
    <CurrencyIcon
      height={20}
      width={20}
      className="inline-block mr-2"
      currency={ccy}
    />
    {formattedBalance$(ccy)}
  </span>
)

const Allowance: React.FC<{ ccy: Quote }> = ({ ccy }) => (
  <span
    className="inline-flex items-center text-sm font-medium font-secondary pr-4"
    data-testid={`wallet-allowance-${ccy.toLowerCase()}`}
  >
    <CurrencyIcon
      height={22}
      width={22}
      className="inline-block mr-2"
      currency={ccy}
    />
    {formattedAllowance$(ccy)}
  </span>
)

const balances$ = networkQuotes$.pipeState(
  map((networkQuotes) =>
    networkQuotes.map((ccy) => <Balance ccy={ccy} key={ccy} />),
  ),
)

const allowances$ = networkQuotes$.pipeState(
  map((networkQuotes) =>
    networkQuotes
      .filter((ccy) => ccy !== "ETH")
      .map((ccy) => <Allowance ccy={ccy} key={ccy} />),
  ),
)

export const Balances: React.FC<{ fallback?: ReactNode }> = ({ fallback }) => (
  <Suspense fallback={fallback ?? null}>{balances$}</Suspense>
)

export const Allowances: React.FC = () => (
  <Suspense fallback={loading}>{allowances$}</Suspense>
)

export const Balances$ = merge(
  balances$,
  allowances$,
  combineKeys(networkQuotes$, formattedBalance$),
  combineKeys(networkQuotes$, formattedAllowance$),
)
