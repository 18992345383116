import { Base, Instrument, Maturity, OpeningCostError } from "@/api"
import { openingCostForPositionWithLeverage } from "@/api/chain/contracts/IContangoQuoter"
import { map, pipe } from "rxjs"
import { SECONDS_IN_HOUR, SECONDS_IN_YEAR } from "./constants"
import { roundTo } from "./rounding"

type CostDataReturnType = ReturnType<
  typeof openingCostForPositionWithLeverage.decoder
> & {
  uniswapFee: bigint
}

export const mapResponses = (fnName = "unknown") =>
  pipe(
    map((responses: Array<CostDataReturnType | OpeningCostError>) => {
      const response = responses
        .filter((x): x is CostDataReturnType => x !== null)
        .filter((x) => !(x instanceof OpeningCostError))
        .sort(({ cost: cost1 }, { cost: cost2 }) => Number(cost2 - cost1))[0]

      if (!response) {
        if (responses[0]) throw responses[0]
        // todo: validate if the below path is a possibility anymore
        throw new Error(`No valid responses to function call: ${fnName}`)
      }
      return response
    }),
  )

export const baseToMinQty = (base: Base) => {
  switch (base) {
    case "ETH":
      return BigInt(1e18)
    case "DAI":
      return BigInt(1000e18)
    case "USDC":
      return BigInt(1000e6)
    default:
      return BigInt(1e18)
  }
}

export const basisRateEquivalent = (
  basisRate: number,
  secondsTilExpiry: number,
  interval: "1h" | "4h" | "8h" | "365d",
) => {
  switch (interval) {
    case "1h":
      return roundTo((SECONDS_IN_HOUR / secondsTilExpiry) * basisRate, 6)
    case "4h":
      return roundTo(((SECONDS_IN_HOUR * 4) / secondsTilExpiry) * basisRate, 5)
    case "8h":
      return roundTo(((SECONDS_IN_HOUR * 8) / secondsTilExpiry) * basisRate, 5)
    case "365d":
      return roundTo((SECONDS_IN_YEAR / secondsTilExpiry) * basisRate, 2)
  }
}

export const formatExpiryDate = (instrument: Instrument) => {
  const date = new Date(Maturity[instrument.maturity].timestamp * 1000)
  return date.toUTCString().substring(4, 16)
}

export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  return date.toLocaleDateString() + " " + date.toLocaleTimeString()
}
