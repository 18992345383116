import { Instrument, Side } from "@/api"
import { CurrencyIcon } from "@/components/CurrencyIcon"
import React from "react"

export const PositionHeader: React.FC<{
  instrument: Instrument
  testIdPrefix: string
}> = ({ instrument: { base, quote, side, maturity }, testIdPrefix }) => {
  const ccy1 = side === Side.Long ? base : quote
  const ccy2 = side === Side.Short ? base : quote
  const colour =
    side === Side.Long ? "text-functional-buy-500" : "text-functional-sell-500"
  return (
    <div className="flex flex-row gap-2 px-4 py-1">
      <div className="flex-1">
        <p
          className="text-lg"
          data-testid={`${testIdPrefix}--header`}
        >{`${ccy1}/${ccy2} ${maturity}`}</p>
        <p
          className={"text-sm " + colour}
          data-testid={`${testIdPrefix}--sub-header`}
        >
          {side}
        </p>
      </div>
      <div className="flex flex-row shrink">
        <CurrencyIcon width={34} height={34} currency={base} />
        <CurrencyIcon
          width={34}
          height={34}
          className="-ml-[12px]"
          currency={quote}
        />
      </div>
    </div>
  )
}
