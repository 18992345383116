import { HistoryItem } from "@/api/graphql/positions"
import {
  SummaryRow,
  SummaryTable,
} from "@/App/Positions/Position/PositionEdit/Summary/SummaryTable"
import { posDivider } from "@/App/Positions/Position/PositionView/Shared"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { invertIfShort } from "@/utils/inverted-pairs-utis"
import { formatDate } from "@/utils/mappers"
import { absolute } from "@/utils/maths-utils"
import { withPrefix } from "@/utils/test-utils"

const id = (name: string) => withPrefix(name, "close-transaction-summary")

export const DeliveredTransactionSummary: React.FC<HistoryItem> = ({
  fillPrice,
  instrument,
  blockTimestamp,
  fillCost,
  feeQuoteAcc,
  fillSize,
}) => {
  const { base, quote } = instrument

  const [exitPrice, exitPriceCurrency] = invertIfShort(fillPrice, instrument)

  return (
    <div className="flex flex-col">
      <SummaryTable>
        <SummaryRow title="Date and Time" testId={id("date")}>
          {formatDate(blockTimestamp)}
        </SummaryRow>
        <SummaryRow title="Position Size" testId={id("size")}>
          <CurrencyDisplay currencyId={base} value={-fillSize} />
        </SummaryRow>
        <SummaryRow title="Price" testId={id("price")}>
          <CurrencyDisplay
            currencyId={exitPriceCurrency}
            value={absolute(exitPrice)}
          />
        </SummaryRow>
        <SummaryRow title="Fees" testId={id("closingFee")}>
          <CurrencyDisplay currencyId={quote} value={feeQuoteAcc} />
        </SummaryRow>
        {posDivider}
        <SummaryRow title="You Paid" testId={id("paid")}>
          <CurrencyDisplay currencyId={quote} value={fillCost + feeQuoteAcc} />
        </SummaryRow>
      </SummaryTable>
      <div className="flex gap-1 mx-auto py-3">
        You Received
        <CurrencyDisplay
          currencyId={base}
          value={-fillSize}
          testId={id("received")}
        />
      </div>
    </div>
  )
}
