import { Instrument, InstrumentId } from "@/api"
import { CurrencyDisplay, formatDelta } from "@/components/CurrencyDisplay"
import { ProtocolIcon } from "@/components/CurrencyIcon"
import { Tooltip } from "@/components/Tooltip"
import { classNames } from "@/utils/classNames"
import { formatKnownCurrency } from "@/utils/currency-utils"
import { invertIfShort, invertNumber } from "@/utils/inverted-pairs-utis"
import { basisRateEquivalent, formatExpiryDate } from "@/utils/mappers"
import { withNullFrom } from "@/utils/observable-utils"
import { withBlurAndHide } from "@/utils/withLoading"
import { Transition } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import {
  liftSuspense,
  state,
  SUSPENSE,
  useStateObservable,
} from "@react-rxjs/core"
import { combineKeys } from "@react-rxjs/utils"
import {
  combineLatest,
  map,
  merge,
  pairwise,
  startWith,
  withLatestFrom,
} from "rxjs"
import { TooltipKey, tooltipMapper } from "../../components/tooltipContent"
import { defaultDiv, defaultSpan, renderBasisRate } from "./common"
import {
  isExpanded$,
  isQtyNullAndBlurred$,
  quantity$,
  setIsExpanded,
  validQuantity$,
} from "./state"
import {
  daysUntilExpiry$,
  instrument$,
  instrumentsToQuote$,
  onSymbolChange,
  secondsTilExpiry$,
} from "./state/instrument"
import { basisRateBySymbol$, costData$, costDataBySymbol$ } from "./state/price"
import {
  arrowTestId,
  basisRateTestId,
  basisTestId,
  entryPriceTestId,
  maturitySelectTestId,
  priceBreakdown,
  spotPriceTestId,
} from "./testIds"

const spotPriceJsx$ = state(
  costData$.pipe(
    map(({ spotPrice, instrument, decimals }) => {
      const [value, ccy] = invertIfShort(spotPrice, instrument)
      return (
        <CurrencyDisplay
          value={value}
          currencyId={ccy}
          testId={spotPriceTestId}
          formatOptions={{ nDecimals: decimals }}
        />
      )
    }),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(spotPriceTestId),
)

const entryPriceJsx$ = state(
  costData$.pipe(
    map(({ price, instrument, decimals }) => {
      const [value, ccy] = invertIfShort(price, instrument)
      return (
        <div className="flex gap-1" data-testid={entryPriceTestId}>
          <span className="text-functional-buy-500">
            {formatKnownCurrency(value, ccy, { nDecimals: decimals })}
          </span>
          <span className="text-fontColor-500">{" " + ccy}</span>
        </div>
      )
    }),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(entryPriceTestId),
)

const basis$ = state((id: InstrumentId) =>
  costDataBySymbol$(id).pipe(
    map(({ spotPrice, price, decimals, side, base, quote }) => {
      let basis = price - spotPrice
      let ccy = quote
      if (side === "Short") {
        basis =
          invertNumber(price, quote, base) -
          invertNumber(spotPrice, quote, base)
        ccy = base
      }
      return { basis, ccy, decimals }
    }),
  ),
)

const basisJsx$ = state(
  (id: InstrumentId) =>
    basis$(id).pipe(
      map(({ basis, ccy }) =>
        formatDelta(basis, ccy, basisTestId, {
          nDecimals: Instrument[id].decimals,
        }),
      ),
      withBlurAndHide(quantity$),
    ),
  defaultSpan(basisTestId),
)

const basisRateJsx$ = state(
  (id: InstrumentId) =>
    basisRateBySymbol$(id).pipe(
      map((basisRate) => renderBasisRate(basisRate, basisRateTestId(id))),
      withBlurAndHide(quantity$),
      startWith(defaultSpan(basisRateTestId(id))),
    ),
  null,
)

// TODO refactor
const equivalents$ = state((id: InstrumentId) =>
  basisRateBySymbol$(id).pipe(
    withNullFrom(quantity$),
    liftSuspense(),
    pairwise(),
    withLatestFrom(secondsTilExpiry$(id)),
    map(([values, secondsTilExpiry]) => {
      const [, curr] = values
      const className = curr === SUSPENSE ? "blur-sm" : ""
      const [one, two] = values.map((x) => (x === SUSPENSE ? null : x))
      if (!(one || two)) return null
      return (["365d", "8h", "1h"] as const).map((interval) => (
        <div key={interval} className="flex justify-between">
          <div className="flex gap-2">
            <div className="self-center rounded-full h-1 w-1 bg-functional-buy-500" />
            {interval}
          </div>
          {curr ? (
            <span className={className}>{`${basisRateEquivalent(
              two || one || 0,
              secondsTilExpiry,
              interval,
            )}%`}</span>
          ) : null}
        </div>
      ))
    }),
  ),
)

const MaturityOptionDropdown: React.FC<{ instrument: Instrument }> = ({
  instrument,
}) => {
  const daysTilExpiry = useStateObservable(daysUntilExpiry$(instrument.id))

  return (
    <div className="flex flex-col px-6 pb-3 text-sm text-fontColor-500">
      <div className="flex flex-col gap-1">
        <div className="flex gap-1 flex-col">
          <span className="text-fontColor-600">Rate if held to settlement</span>
          <div className="flex justify-between mb-2">
            <span>{`${daysTilExpiry}d`}</span>
            <div className="flex text-fontColor-0 gap-1">
              {basisJsx$(instrument.id)}
              <span>≈</span>
              {basisRateJsx$(instrument.id)}
            </div>
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <span className="text-fontColor-600">Funding rate equivalents</span>
          {equivalents$(instrument.id)}
        </div>
      </div>
    </div>
  )
}

const basisRateAndArrow$ = state(
  (id: InstrumentId) =>
    combineLatest([isExpanded$(id), basisRateBySymbol$(id)]).pipe(
      map(([isExpanded, basisRate]) => {
        return (
          <div className="flex items-center gap-2">
            {renderBasisRate(basisRate, basisRateTestId(id))}
            <div
              onClick={(e) => {
                e.stopPropagation()
                setIsExpanded(id, !isExpanded)
              }}
              data-testid={arrowTestId(id)}
              className="h-5 w-5 border-[1.5px] border-backgrounds-300 rounded-xl"
            >
              {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </div>
        )
      }),
      withBlurAndHide(quantity$),
      startWith(defaultDiv(basisRateTestId(id), arrowTestId(id))),
    ),
  null,
)

const MaturityOption: React.FC<{ symbol: InstrumentId }> = ({ symbol }) => {
  const selectedSymbol = useStateObservable(instrument$).id
  const instrument = Instrument[symbol]
  const isSelected = selectedSymbol === symbol
  const isExpanded = useStateObservable(isExpanded$(symbol))

  const dotStyles = isSelected
    ? "bg-functional-buy-500"
    : "border border-backgrounds-400"

  return (
    <div
      className={classNames(
        "flex flex-col rounded-2xl cursor-pointer",
        isSelected ? "bg-backgrounds-100" : "text-fontColor-600",
        isExpanded && !isSelected ? "bg-backgrounds-100" : "",
      )}
    >
      <div
        className={classNames(
          "flex hover:bg-backgrounds-100 items-center px-4 justify-between h-10 rounded-2xl",
        )}
        onClick={(e) => {
          e.preventDefault()
          onSymbolChange(symbol)
        }}
        data-testid={maturitySelectTestId(symbol)}
      >
        <span
          data-testid={`select-maturity--${symbol}`}
          className="flex gap-2 font-medium"
        >
          {Dot(dotStyles)}
          {formatExpiryDate(instrument)}
        </span>
        {basisRateAndArrow$(symbol)}
      </div>
      <Transition show={isExpanded}>
        <MaturityOptionDropdown instrument={instrument} />
      </Transition>
    </div>
  )
}

const PriceBreakdown = () => {
  const validQuantity = useStateObservable(validQuantity$)
  const { id } = useStateObservable(instrument$)
  return (
    <div
      data-testid={priceBreakdown}
      className={classNames(
        "flex flex-col gap-3 px-4",
        validQuantity ? "" : "hidden",
      )}
    >
      <div className="flex justify-between">
        {labelJsx("Spot Price", "spotPrice", "uniswap")}
        {spotPriceJsx$}
      </div>
      <div className="flex justify-between">
        {labelJsx("Basis", "basis", "yield")}
        {basisJsx$(id)}
      </div>
      <div className="flex justify-between">
        {labelJsx("Entry Price", "price", "contango")}
        {entryPriceJsx$}
      </div>
    </div>
  )
}

export const PriceQuote$ = merge(
  instrument$,
  instrumentsToQuote$,
  entryPriceJsx$,
  isQtyNullAndBlurred$,
  combineKeys(instrumentsToQuote$, secondsTilExpiry$),
  combineKeys(instrumentsToQuote$, daysUntilExpiry$),
  combineKeys(instrumentsToQuote$, equivalents$),
  combineKeys(instrumentsToQuote$, basisJsx$),
  combineKeys(instrumentsToQuote$, basisRateAndArrow$),
  combineKeys(instrumentsToQuote$, basisRateJsx$),
)

export const PriceQuote: React.FC = () => {
  const instrumentsToQuote = useStateObservable(instrumentsToQuote$)

  return (
    <div className="flex flex-col gap-4 text-sm font-normal font-primary">
      <div className="flex flex-col gap-1">
        <div className="flex justify-between text-fontColor-500 px-4 py-2">
          <span>Select Expiry</span>
          <span>Basis Rate</span>
        </div>
        {instrumentsToQuote.map((symbol) => (
          <MaturityOption key={symbol} symbol={symbol} />
        ))}
      </div>
      <PriceBreakdown />
      <div className="w-full border-[1.5px] border-backgrounds-300" />
    </div>
  )
}

const labelJsx = (
  label: string,
  tooltipKey: TooltipKey,
  protocol?: "yield" | "contango" | "uniswap",
) => (
  <span className="flex flex-row gap-1">
    {protocol ? <ProtocolIcon protocol={protocol} /> : null}
    <Tooltip
      className="self-center text-fontColor-500"
      message={tooltipMapper(tooltipKey)}
    >
      {label}
    </Tooltip>
  </span>
)

const Dot = (className?: string) => (
  <div className={classNames("self-center rounded-full h-2 w-2", className)} />
)
