import { balance$ } from "@/api"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import { filterOutSuspense, mapDistinct } from "@/utils/observable-utils"
import { withBlurAndHide } from "@/utils/withLoading"
import { state, useStateObservable } from "@react-rxjs/core"
import { combineLatest, map, merge, switchMap } from "rxjs"
import { defaultSpan } from "./common"
import { quantity$ } from "./state"
import { formErrors$ } from "./state/error/formErrors"
import { instrument$ } from "./state/instrument"
import { costData$ } from "./state/price"
import { depositAmountTestId } from "./testIds"

const quoteBalance$ = state(
  instrument$.pipe(switchMap(({ quote }) => balance$(quote))),
)

export const hasEnoughBalance$ = state(
  combineLatest([costData$, quoteBalance$]).pipe(
    mapDistinct(([{ collateralUsed }, balance]) => balance >= collateralUsed),
  ),
  true,
)

const amount$ = state(
  costData$.pipe(
    map(({ collateralUsed, instrument }) => (
      <span className="flex justify-center py-2 gap-1">
        You Deposit
        <CurrencyDisplay
          value={collateralUsed}
          currencyId={instrument.quote}
          testId={depositAmountTestId}
        />
      </span>
    )),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(depositAmountTestId),
)

const errorToDisplay$ = state(
  combineLatest([
    formErrors$("cannotRetrieveQuote"),
    formErrors$("insufficientLiquidity"),
    formErrors$("noLiquidity"),
    hasEnoughBalance$.pipe(filterOutSuspense()),
  ]).pipe(
    filterOutSuspense(),
    mapDistinct(
      ([
        cannotRetrieveQuote,
        insufficientLiquidityError,
        noLiquidityError,
        hasEnoughBalance,
      ]) => {
        if (noLiquidityError?.error)
          return {
            origin: noLiquidityError.origin,
            text: "No liquidity remaining for the selected instrument. Please try selecting a different maturity or currency pair.",
          }
        if (insufficientLiquidityError?.error)
          return {
            origin: insufficientLiquidityError.origin,
            text: "Insufficient liquidity. Please try reducing your quantity or selecting a different maturity.",
          }
        if (cannotRetrieveQuote?.error)
          return {
            origin: cannotRetrieveQuote.origin,
            text: "We were unable to retrieve a quote due to an unexpected error in the contract.",
          }

        if (!hasEnoughBalance)
          return {
            origin: "insufficientBalance",
            text: "Insufficient wallet balance for this trade",
          }
        return null
      },
    ),
  ),
  null,
)

export const YouPay$ = merge(
  amount$,
  hasEnoughBalance$,
  formErrors$("cannotRetrieveQuote"),
  formErrors$("noLiquidity"),
  formErrors$("insufficientLiquidity"),
  formErrors$("insufficientBalance"),
  errorToDisplay$,
)

const ErrorMessage: React.FC = () => {
  const errorToDisplay = useStateObservable(errorToDisplay$)
  return (
    <FormMessage
      testId={`create-ticket-error--${errorToDisplay?.origin || "no-error"}`}
      iconType={IconType.Error}
      visible={Boolean(errorToDisplay?.origin)}
    >
      <p>{errorToDisplay?.text}</p>
    </FormMessage>
  )
}

export const YouPay = () => {
  return (
    <div className="flex flex-col text-base">
      {amount$}
      <ErrorMessage />
    </div>
  )
}
