import { solidityFn, solidityEvent, overloadedFn } from "@unstoppablejs/solidity-bindings";
import { address,uint,Tuple,Vector,bytes } from "solidity-codecs";

const a = [address, uint, uint] as [owner: typeof address, from: typeof uint, to: typeof uint];
const b = Vector(uint);
const c = Tuple(b);
const d = [address, address, uint] as [from: typeof address, to: typeof address, tokenId: typeof uint];
const e = Tuple();
const f = [address, address, uint, bytes] as [from: typeof address, to: typeof address, tokenId: typeof uint, data: typeof bytes];
const g = {from: address, to: address, tokenId: uint};

export const positions = solidityFn("positions", a, c, 1);
const safeTransferFromName = "safeTransferFrom";
const safeTransferFrom0 = solidityFn(safeTransferFromName, d, e, 2);
// manually modified file to export this directly. There's an issue with the overloadedFn that needs to be fixed
export const safeTransferFrom1 = solidityFn(safeTransferFromName, f, e, 2);
export const safeTransferFrom = overloadedFn(safeTransferFrom0, safeTransferFrom1);


export const Transfer = solidityEvent(g, e, "Transfer");




export interface ContangoPositionNFT {
  functions: typeof positions | typeof safeTransferFrom | typeof safeTransferFrom1,
  events: typeof Transfer,
  errors: never
}