export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Bytes: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID']['output'];
  orders: Array<Order>;
  positions: Array<Position>;
  referrals: Array<ReferralCounter>;
};


export type AccountOrdersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Order_Filter>;
};


export type AccountPositionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Position_Filter>;
};


export type AccountReferralsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReferralCounter_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferralCounter_Filter>;
};

export type Account_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  orders_?: InputMaybe<Order_Filter>;
  positions_?: InputMaybe<Position_Filter>;
  referrals_?: InputMaybe<ReferralCounter_Filter>;
};

export enum Account_OrderBy {
  Id = 'id',
  Orders = 'orders',
  Positions = 'positions',
  Referrals = 'referrals'
}

export type Asset = {
  __typename?: 'Asset';
  decimals: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  openInterest: Scalars['BigDecimal']['output'];
  symbol: Scalars['String']['output'];
  totalVolume: Scalars['BigDecimal']['output'];
};

export type Asset_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Asset_Filter>>>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  openInterest?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  openInterest_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Asset_Filter>>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_gt?: InputMaybe<Scalars['String']['input']>;
  symbol_gte?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_lt?: InputMaybe<Scalars['String']['input']>;
  symbol_lte?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  totalVolume?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalVolume_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum Asset_OrderBy {
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  OpenInterest = 'openInterest',
  Symbol = 'symbol',
  TotalVolume = 'totalVolume'
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int']['input'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
};

export enum Currency {
  Base = 'Base',
  None = 'None',
  Quote = 'Quote'
}

export type HistoryItem = {
  __typename?: 'HistoryItem';
  blockNumber: Scalars['BigInt']['output'];
  blockTimestamp: Scalars['BigInt']['output'];
  cashflowBase: Scalars['BigDecimal']['output'];
  cashflowBaseAcc: Scalars['BigDecimal']['output'];
  cashflowCcy: Currency;
  cashflowQuote: Scalars['BigDecimal']['output'];
  cashflowQuoteAcc: Scalars['BigDecimal']['output'];
  closedCost: Scalars['BigDecimal']['output'];
  dateTime: Scalars['String']['output'];
  equityBase: Scalars['BigDecimal']['output'];
  equityBaseAcc: Scalars['BigDecimal']['output'];
  equityQuote: Scalars['BigDecimal']['output'];
  equityQuoteAcc: Scalars['BigDecimal']['output'];
  feeBase: Scalars['BigDecimal']['output'];
  feeBaseAcc: Scalars['BigDecimal']['output'];
  feeCcy: Currency;
  feeQuote: Scalars['BigDecimal']['output'];
  feeQuoteAcc: Scalars['BigDecimal']['output'];
  fillCost: Scalars['BigDecimal']['output'];
  fillPrice: Scalars['BigDecimal']['output'];
  fillSize: Scalars['BigDecimal']['output'];
  id: Scalars['ID']['output'];
  openCost: Scalars['BigDecimal']['output'];
  openQuantity: Scalars['BigDecimal']['output'];
  owner: Account;
  position: Position;
  prevTransactionHash?: Maybe<Scalars['Bytes']['output']>;
  realisedPnLBase: Scalars['BigDecimal']['output'];
  realisedPnLQuote: Scalars['BigDecimal']['output'];
  spotPrice?: Maybe<Scalars['BigDecimal']['output']>;
  transactionHash: Scalars['Bytes']['output'];
  type: HistoryItemType;
};

export enum HistoryItemType {
  Close = 'Close',
  Delivery = 'Delivery',
  Liquidation = 'Liquidation',
  Modification = 'Modification',
  Open = 'Open'
}

export type HistoryItem_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<HistoryItem_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  cashflowBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowBaseAcc_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBaseAcc_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowCcy?: InputMaybe<Currency>;
  cashflowCcy_in?: InputMaybe<Array<Currency>>;
  cashflowCcy_not?: InputMaybe<Currency>;
  cashflowCcy_not_in?: InputMaybe<Array<Currency>>;
  cashflowQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowQuoteAcc_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuoteAcc_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  closedCost?: InputMaybe<Scalars['BigDecimal']['input']>;
  closedCost_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  closedCost_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  closedCost_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  closedCost_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  closedCost_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  closedCost_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  closedCost_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dateTime?: InputMaybe<Scalars['String']['input']>;
  dateTime_contains?: InputMaybe<Scalars['String']['input']>;
  dateTime_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_ends_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_gt?: InputMaybe<Scalars['String']['input']>;
  dateTime_gte?: InputMaybe<Scalars['String']['input']>;
  dateTime_in?: InputMaybe<Array<Scalars['String']['input']>>;
  dateTime_lt?: InputMaybe<Scalars['String']['input']>;
  dateTime_lte?: InputMaybe<Scalars['String']['input']>;
  dateTime_not?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_contains?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  dateTime_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_starts_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  equityBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityBaseAcc_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBaseAcc_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityQuoteAcc_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuoteAcc_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeBaseAcc_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBaseAcc_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeCcy?: InputMaybe<Currency>;
  feeCcy_in?: InputMaybe<Array<Currency>>;
  feeCcy_not?: InputMaybe<Currency>;
  feeCcy_not_in?: InputMaybe<Array<Currency>>;
  feeQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeQuoteAcc_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuoteAcc_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  fillCost?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillCost_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillCost_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillCost_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  fillCost_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillCost_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillCost_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillCost_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  fillPrice?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillPrice_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillPrice_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillPrice_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  fillPrice_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillPrice_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillPrice_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  fillSize?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillSize_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillSize_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillSize_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  fillSize_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillSize_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillSize_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  fillSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  openCost?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  openCost_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  openQuantity?: InputMaybe<Scalars['BigDecimal']['input']>;
  openQuantity_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openQuantity_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openQuantity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  openQuantity_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openQuantity_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openQuantity_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  openQuantity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<HistoryItem_Filter>>>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Account_Filter>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  position_?: InputMaybe<Position_Filter>;
  position_contains?: InputMaybe<Scalars['String']['input']>;
  position_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  position_ends_with?: InputMaybe<Scalars['String']['input']>;
  position_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_gt?: InputMaybe<Scalars['String']['input']>;
  position_gte?: InputMaybe<Scalars['String']['input']>;
  position_in?: InputMaybe<Array<Scalars['String']['input']>>;
  position_lt?: InputMaybe<Scalars['String']['input']>;
  position_lte?: InputMaybe<Scalars['String']['input']>;
  position_not?: InputMaybe<Scalars['String']['input']>;
  position_not_contains?: InputMaybe<Scalars['String']['input']>;
  position_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  position_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  position_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_starts_with?: InputMaybe<Scalars['String']['input']>;
  position_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  prevTransactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  prevTransactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  prevTransactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  realisedPnLBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  spotPrice?: InputMaybe<Scalars['BigDecimal']['input']>;
  spotPrice_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  spotPrice_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  spotPrice_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  spotPrice_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  spotPrice_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  spotPrice_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  spotPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  type?: InputMaybe<HistoryItemType>;
  type_in?: InputMaybe<Array<HistoryItemType>>;
  type_not?: InputMaybe<HistoryItemType>;
  type_not_in?: InputMaybe<Array<HistoryItemType>>;
};

export enum HistoryItem_OrderBy {
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  CashflowBase = 'cashflowBase',
  CashflowBaseAcc = 'cashflowBaseAcc',
  CashflowCcy = 'cashflowCcy',
  CashflowQuote = 'cashflowQuote',
  CashflowQuoteAcc = 'cashflowQuoteAcc',
  ClosedCost = 'closedCost',
  DateTime = 'dateTime',
  EquityBase = 'equityBase',
  EquityBaseAcc = 'equityBaseAcc',
  EquityQuote = 'equityQuote',
  EquityQuoteAcc = 'equityQuoteAcc',
  FeeBase = 'feeBase',
  FeeBaseAcc = 'feeBaseAcc',
  FeeCcy = 'feeCcy',
  FeeQuote = 'feeQuote',
  FeeQuoteAcc = 'feeQuoteAcc',
  FillCost = 'fillCost',
  FillPrice = 'fillPrice',
  FillSize = 'fillSize',
  Id = 'id',
  OpenCost = 'openCost',
  OpenQuantity = 'openQuantity',
  Owner = 'owner',
  OwnerId = 'owner__id',
  Position = 'position',
  PositionCashflowBase = 'position__cashflowBase',
  PositionCashflowQuote = 'position__cashflowQuote',
  PositionEquityBase = 'position__equityBase',
  PositionEquityQuote = 'position__equityQuote',
  PositionFeesBase = 'position__feesBase',
  PositionFeesQuote = 'position__feesQuote',
  PositionId = 'position__id',
  PositionLatestTransactionHash = 'position__latestTransactionHash',
  PositionMoneyMarket = 'position__moneyMarket',
  PositionNumber = 'position__number',
  PositionOpenCost = 'position__openCost',
  PositionQuantity = 'position__quantity',
  PositionRealisedPnLBase = 'position__realisedPnLBase',
  PositionRealisedPnLQuote = 'position__realisedPnLQuote',
  PrevTransactionHash = 'prevTransactionHash',
  RealisedPnLBase = 'realisedPnLBase',
  RealisedPnLQuote = 'realisedPnLQuote',
  SpotPrice = 'spotPrice',
  TransactionHash = 'transactionHash',
  Type = 'type'
}

export type Instrument = {
  __typename?: 'Instrument';
  base: Asset;
  id: Scalars['ID']['output'];
  openInterest: Scalars['BigDecimal']['output'];
  orders: Array<Order>;
  positions: Array<Position>;
  quote: Asset;
  symbol: Scalars['String']['output'];
  totalVolume: Scalars['BigDecimal']['output'];
};


export type InstrumentOrdersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Order_Filter>;
};


export type InstrumentPositionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Position_Filter>;
};

export type Instrument_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Instrument_Filter>>>;
  base?: InputMaybe<Scalars['String']['input']>;
  base_?: InputMaybe<Asset_Filter>;
  base_contains?: InputMaybe<Scalars['String']['input']>;
  base_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  base_ends_with?: InputMaybe<Scalars['String']['input']>;
  base_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  base_gt?: InputMaybe<Scalars['String']['input']>;
  base_gte?: InputMaybe<Scalars['String']['input']>;
  base_in?: InputMaybe<Array<Scalars['String']['input']>>;
  base_lt?: InputMaybe<Scalars['String']['input']>;
  base_lte?: InputMaybe<Scalars['String']['input']>;
  base_not?: InputMaybe<Scalars['String']['input']>;
  base_not_contains?: InputMaybe<Scalars['String']['input']>;
  base_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  base_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  base_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  base_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  base_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  base_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  base_starts_with?: InputMaybe<Scalars['String']['input']>;
  base_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  openInterest?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  openInterest_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  openInterest_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Instrument_Filter>>>;
  orders_?: InputMaybe<Order_Filter>;
  positions_?: InputMaybe<Position_Filter>;
  quote?: InputMaybe<Scalars['String']['input']>;
  quote_?: InputMaybe<Asset_Filter>;
  quote_contains?: InputMaybe<Scalars['String']['input']>;
  quote_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  quote_ends_with?: InputMaybe<Scalars['String']['input']>;
  quote_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  quote_gt?: InputMaybe<Scalars['String']['input']>;
  quote_gte?: InputMaybe<Scalars['String']['input']>;
  quote_in?: InputMaybe<Array<Scalars['String']['input']>>;
  quote_lt?: InputMaybe<Scalars['String']['input']>;
  quote_lte?: InputMaybe<Scalars['String']['input']>;
  quote_not?: InputMaybe<Scalars['String']['input']>;
  quote_not_contains?: InputMaybe<Scalars['String']['input']>;
  quote_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  quote_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  quote_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  quote_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  quote_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  quote_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  quote_starts_with?: InputMaybe<Scalars['String']['input']>;
  quote_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_gt?: InputMaybe<Scalars['String']['input']>;
  symbol_gte?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_lt?: InputMaybe<Scalars['String']['input']>;
  symbol_lte?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  totalVolume?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalVolume_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolume_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum Instrument_OrderBy {
  Base = 'base',
  BaseDecimals = 'base__decimals',
  BaseId = 'base__id',
  BaseName = 'base__name',
  BaseOpenInterest = 'base__openInterest',
  BaseSymbol = 'base__symbol',
  BaseTotalVolume = 'base__totalVolume',
  Id = 'id',
  OpenInterest = 'openInterest',
  Orders = 'orders',
  Positions = 'positions',
  Quote = 'quote',
  QuoteDecimals = 'quote__decimals',
  QuoteId = 'quote__id',
  QuoteName = 'quote__name',
  QuoteOpenInterest = 'quote__openInterest',
  QuoteSymbol = 'quote__symbol',
  QuoteTotalVolume = 'quote__totalVolume',
  Symbol = 'symbol',
  TotalVolume = 'totalVolume'
}

export enum MoneyMarket {
  Aave = 'Aave',
  Compound = 'Compound',
  Yield = 'Yield'
}

export type Order = {
  __typename?: 'Order';
  blockNumber: Scalars['BigInt']['output'];
  blockTimestamp: Scalars['BigInt']['output'];
  cashflow: Scalars['BigDecimal']['output'];
  cashflowCcy: Currency;
  dateTime: Scalars['String']['output'];
  deadline: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  instrument: Instrument;
  limitPrice: Scalars['BigDecimal']['output'];
  owner: Account;
  placedBy: Account;
  position?: Maybe<Position>;
  quantity: Scalars['BigDecimal']['output'];
  side: Side;
  sortKey: Scalars['String']['output'];
  triggerPrice?: Maybe<Scalars['BigDecimal']['output']>;
  type: OrderType;
};

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderType {
  Limit = 'Limit',
  StopLoss = 'StopLoss',
  TakeProfit = 'TakeProfit'
}

export type Order_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  cashflow?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowCcy?: InputMaybe<Currency>;
  cashflowCcy_in?: InputMaybe<Array<Currency>>;
  cashflowCcy_not?: InputMaybe<Currency>;
  cashflowCcy_not_in?: InputMaybe<Array<Currency>>;
  cashflow_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflow_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflow_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflow_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflow_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflow_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflow_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dateTime?: InputMaybe<Scalars['String']['input']>;
  dateTime_contains?: InputMaybe<Scalars['String']['input']>;
  dateTime_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_ends_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_gt?: InputMaybe<Scalars['String']['input']>;
  dateTime_gte?: InputMaybe<Scalars['String']['input']>;
  dateTime_in?: InputMaybe<Array<Scalars['String']['input']>>;
  dateTime_lt?: InputMaybe<Scalars['String']['input']>;
  dateTime_lte?: InputMaybe<Scalars['String']['input']>;
  dateTime_not?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_contains?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  dateTime_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  dateTime_starts_with?: InputMaybe<Scalars['String']['input']>;
  dateTime_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  deadline?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deadline_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_not?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  instrument?: InputMaybe<Scalars['String']['input']>;
  instrument_?: InputMaybe<Instrument_Filter>;
  instrument_contains?: InputMaybe<Scalars['String']['input']>;
  instrument_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_ends_with?: InputMaybe<Scalars['String']['input']>;
  instrument_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_gt?: InputMaybe<Scalars['String']['input']>;
  instrument_gte?: InputMaybe<Scalars['String']['input']>;
  instrument_in?: InputMaybe<Array<Scalars['String']['input']>>;
  instrument_lt?: InputMaybe<Scalars['String']['input']>;
  instrument_lte?: InputMaybe<Scalars['String']['input']>;
  instrument_not?: InputMaybe<Scalars['String']['input']>;
  instrument_not_contains?: InputMaybe<Scalars['String']['input']>;
  instrument_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  instrument_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  instrument_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  instrument_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_starts_with?: InputMaybe<Scalars['String']['input']>;
  instrument_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  limitPrice?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitPrice_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitPrice_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitPrice_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  limitPrice_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitPrice_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitPrice_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Account_Filter>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  placedBy?: InputMaybe<Scalars['String']['input']>;
  placedBy_?: InputMaybe<Account_Filter>;
  placedBy_contains?: InputMaybe<Scalars['String']['input']>;
  placedBy_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  placedBy_ends_with?: InputMaybe<Scalars['String']['input']>;
  placedBy_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  placedBy_gt?: InputMaybe<Scalars['String']['input']>;
  placedBy_gte?: InputMaybe<Scalars['String']['input']>;
  placedBy_in?: InputMaybe<Array<Scalars['String']['input']>>;
  placedBy_lt?: InputMaybe<Scalars['String']['input']>;
  placedBy_lte?: InputMaybe<Scalars['String']['input']>;
  placedBy_not?: InputMaybe<Scalars['String']['input']>;
  placedBy_not_contains?: InputMaybe<Scalars['String']['input']>;
  placedBy_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  placedBy_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  placedBy_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  placedBy_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  placedBy_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  placedBy_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  placedBy_starts_with?: InputMaybe<Scalars['String']['input']>;
  placedBy_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  position_?: InputMaybe<Position_Filter>;
  position_contains?: InputMaybe<Scalars['String']['input']>;
  position_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  position_ends_with?: InputMaybe<Scalars['String']['input']>;
  position_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_gt?: InputMaybe<Scalars['String']['input']>;
  position_gte?: InputMaybe<Scalars['String']['input']>;
  position_in?: InputMaybe<Array<Scalars['String']['input']>>;
  position_lt?: InputMaybe<Scalars['String']['input']>;
  position_lte?: InputMaybe<Scalars['String']['input']>;
  position_not?: InputMaybe<Scalars['String']['input']>;
  position_not_contains?: InputMaybe<Scalars['String']['input']>;
  position_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  position_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  position_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_starts_with?: InputMaybe<Scalars['String']['input']>;
  position_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  quantity_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  side?: InputMaybe<Side>;
  side_in?: InputMaybe<Array<Side>>;
  side_not?: InputMaybe<Side>;
  side_not_in?: InputMaybe<Array<Side>>;
  sortKey?: InputMaybe<Scalars['String']['input']>;
  sortKey_contains?: InputMaybe<Scalars['String']['input']>;
  sortKey_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sortKey_ends_with?: InputMaybe<Scalars['String']['input']>;
  sortKey_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sortKey_gt?: InputMaybe<Scalars['String']['input']>;
  sortKey_gte?: InputMaybe<Scalars['String']['input']>;
  sortKey_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sortKey_lt?: InputMaybe<Scalars['String']['input']>;
  sortKey_lte?: InputMaybe<Scalars['String']['input']>;
  sortKey_not?: InputMaybe<Scalars['String']['input']>;
  sortKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  sortKey_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sortKey_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sortKey_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sortKey_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sortKey_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sortKey_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sortKey_starts_with?: InputMaybe<Scalars['String']['input']>;
  sortKey_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggerPrice?: InputMaybe<Scalars['BigDecimal']['input']>;
  triggerPrice_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  triggerPrice_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  triggerPrice_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  triggerPrice_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  triggerPrice_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  triggerPrice_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  triggerPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  type?: InputMaybe<OrderType>;
  type_in?: InputMaybe<Array<OrderType>>;
  type_not?: InputMaybe<OrderType>;
  type_not_in?: InputMaybe<Array<OrderType>>;
};

export enum Order_OrderBy {
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  Cashflow = 'cashflow',
  CashflowCcy = 'cashflowCcy',
  DateTime = 'dateTime',
  Deadline = 'deadline',
  Id = 'id',
  Instrument = 'instrument',
  InstrumentId = 'instrument__id',
  InstrumentOpenInterest = 'instrument__openInterest',
  InstrumentSymbol = 'instrument__symbol',
  InstrumentTotalVolume = 'instrument__totalVolume',
  LimitPrice = 'limitPrice',
  Owner = 'owner',
  OwnerId = 'owner__id',
  PlacedBy = 'placedBy',
  PlacedById = 'placedBy__id',
  Position = 'position',
  PositionCashflowBase = 'position__cashflowBase',
  PositionCashflowQuote = 'position__cashflowQuote',
  PositionEquityBase = 'position__equityBase',
  PositionEquityQuote = 'position__equityQuote',
  PositionFeesBase = 'position__feesBase',
  PositionFeesQuote = 'position__feesQuote',
  PositionId = 'position__id',
  PositionLatestTransactionHash = 'position__latestTransactionHash',
  PositionMoneyMarket = 'position__moneyMarket',
  PositionNumber = 'position__number',
  PositionOpenCost = 'position__openCost',
  PositionQuantity = 'position__quantity',
  PositionRealisedPnLBase = 'position__realisedPnLBase',
  PositionRealisedPnLQuote = 'position__realisedPnLQuote',
  Quantity = 'quantity',
  Side = 'side',
  SortKey = 'sortKey',
  TriggerPrice = 'triggerPrice',
  Type = 'type'
}

export type Position = {
  __typename?: 'Position';
  cashflowBase: Scalars['BigDecimal']['output'];
  cashflowQuote: Scalars['BigDecimal']['output'];
  equityBase: Scalars['BigDecimal']['output'];
  equityQuote: Scalars['BigDecimal']['output'];
  feesBase: Scalars['BigDecimal']['output'];
  feesQuote: Scalars['BigDecimal']['output'];
  history: Array<HistoryItem>;
  id: Scalars['ID']['output'];
  instrument: Instrument;
  latestTransactionHash?: Maybe<Scalars['Bytes']['output']>;
  moneyMarket: MoneyMarket;
  number: Scalars['BigInt']['output'];
  openCost: Scalars['BigDecimal']['output'];
  orders: Array<Order>;
  owner: Account;
  quantity: Scalars['BigDecimal']['output'];
  realisedPnLBase: Scalars['BigDecimal']['output'];
  realisedPnLQuote: Scalars['BigDecimal']['output'];
};


export type PositionHistoryArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<HistoryItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HistoryItem_Filter>;
};


export type PositionOrdersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Order_Filter>;
};

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  cashflowBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  cashflowQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  cashflowQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  equityQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  equityQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feesBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feesBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feesQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feesQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feesQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  history_?: InputMaybe<HistoryItem_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  instrument?: InputMaybe<Scalars['String']['input']>;
  instrument_?: InputMaybe<Instrument_Filter>;
  instrument_contains?: InputMaybe<Scalars['String']['input']>;
  instrument_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_ends_with?: InputMaybe<Scalars['String']['input']>;
  instrument_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_gt?: InputMaybe<Scalars['String']['input']>;
  instrument_gte?: InputMaybe<Scalars['String']['input']>;
  instrument_in?: InputMaybe<Array<Scalars['String']['input']>>;
  instrument_lt?: InputMaybe<Scalars['String']['input']>;
  instrument_lte?: InputMaybe<Scalars['String']['input']>;
  instrument_not?: InputMaybe<Scalars['String']['input']>;
  instrument_not_contains?: InputMaybe<Scalars['String']['input']>;
  instrument_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  instrument_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  instrument_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  instrument_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  instrument_starts_with?: InputMaybe<Scalars['String']['input']>;
  instrument_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  latestTransactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  latestTransactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  latestTransactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  moneyMarket?: InputMaybe<MoneyMarket>;
  moneyMarket_in?: InputMaybe<Array<MoneyMarket>>;
  moneyMarket_not?: InputMaybe<MoneyMarket>;
  moneyMarket_not_in?: InputMaybe<Array<MoneyMarket>>;
  number?: InputMaybe<Scalars['BigInt']['input']>;
  number_gt?: InputMaybe<Scalars['BigInt']['input']>;
  number_gte?: InputMaybe<Scalars['BigInt']['input']>;
  number_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  number_lt?: InputMaybe<Scalars['BigInt']['input']>;
  number_lte?: InputMaybe<Scalars['BigInt']['input']>;
  number_not?: InputMaybe<Scalars['BigInt']['input']>;
  number_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  openCost?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  openCost_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  openCost_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  orders_?: InputMaybe<Order_Filter>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Account_Filter>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  quantity_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  quantity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLBase?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLBase_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLBase_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLQuote?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  realisedPnLQuote_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  realisedPnLQuote_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum Position_OrderBy {
  CashflowBase = 'cashflowBase',
  CashflowQuote = 'cashflowQuote',
  EquityBase = 'equityBase',
  EquityQuote = 'equityQuote',
  FeesBase = 'feesBase',
  FeesQuote = 'feesQuote',
  History = 'history',
  Id = 'id',
  Instrument = 'instrument',
  InstrumentId = 'instrument__id',
  InstrumentOpenInterest = 'instrument__openInterest',
  InstrumentSymbol = 'instrument__symbol',
  InstrumentTotalVolume = 'instrument__totalVolume',
  LatestTransactionHash = 'latestTransactionHash',
  MoneyMarket = 'moneyMarket',
  Number = 'number',
  OpenCost = 'openCost',
  Orders = 'orders',
  Owner = 'owner',
  OwnerId = 'owner__id',
  Quantity = 'quantity',
  RealisedPnLBase = 'realisedPnLBase',
  RealisedPnLQuote = 'realisedPnLQuote'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  historyItem?: Maybe<HistoryItem>;
  historyItems: Array<HistoryItem>;
  instrument?: Maybe<Instrument>;
  instruments: Array<Instrument>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  referralCounter?: Maybe<ReferralCounter>;
  referralCounters: Array<ReferralCounter>;
  underlyingPosition?: Maybe<UnderlyingPosition>;
  underlyingPositions: Array<UnderlyingPosition>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type QueryAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Asset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Asset_Filter>;
};


export type QueryHistoryItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryHistoryItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<HistoryItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<HistoryItem_Filter>;
};


export type QueryInstrumentArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInstrumentsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Instrument_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Instrument_Filter>;
};


export type QueryOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};


export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type QueryReferralCounterArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralCountersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReferralCounter_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralCounter_Filter>;
};


export type QueryUnderlyingPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUnderlyingPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UnderlyingPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UnderlyingPosition_Filter>;
};

export type ReferralCounter = {
  __typename?: 'ReferralCounter';
  account: Account;
  asset: Asset;
  id: Scalars['ID']['output'];
  rebates: Scalars['BigDecimal']['output'];
  rewards: Scalars['BigDecimal']['output'];
};

export type ReferralCounter_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  and?: InputMaybe<Array<InputMaybe<ReferralCounter_Filter>>>;
  asset?: InputMaybe<Scalars['String']['input']>;
  asset_?: InputMaybe<Asset_Filter>;
  asset_contains?: InputMaybe<Scalars['String']['input']>;
  asset_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  asset_ends_with?: InputMaybe<Scalars['String']['input']>;
  asset_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  asset_gt?: InputMaybe<Scalars['String']['input']>;
  asset_gte?: InputMaybe<Scalars['String']['input']>;
  asset_in?: InputMaybe<Array<Scalars['String']['input']>>;
  asset_lt?: InputMaybe<Scalars['String']['input']>;
  asset_lte?: InputMaybe<Scalars['String']['input']>;
  asset_not?: InputMaybe<Scalars['String']['input']>;
  asset_not_contains?: InputMaybe<Scalars['String']['input']>;
  asset_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  asset_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  asset_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  asset_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  asset_starts_with?: InputMaybe<Scalars['String']['input']>;
  asset_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<ReferralCounter_Filter>>>;
  rebates?: InputMaybe<Scalars['BigDecimal']['input']>;
  rebates_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  rebates_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  rebates_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  rebates_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  rebates_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  rebates_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  rebates_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  rewards?: InputMaybe<Scalars['BigDecimal']['input']>;
  rewards_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  rewards_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  rewards_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  rewards_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  rewards_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  rewards_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  rewards_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum ReferralCounter_OrderBy {
  Account = 'account',
  AccountId = 'account__id',
  Asset = 'asset',
  AssetDecimals = 'asset__decimals',
  AssetId = 'asset__id',
  AssetName = 'asset__name',
  AssetOpenInterest = 'asset__openInterest',
  AssetSymbol = 'asset__symbol',
  AssetTotalVolume = 'asset__totalVolume',
  Id = 'id',
  Rebates = 'rebates',
  Rewards = 'rewards'
}

export enum Side {
  Buy = 'Buy',
  Sell = 'Sell'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  historyItem?: Maybe<HistoryItem>;
  historyItems: Array<HistoryItem>;
  instrument?: Maybe<Instrument>;
  instruments: Array<Instrument>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  referralCounter?: Maybe<ReferralCounter>;
  referralCounters: Array<ReferralCounter>;
  underlyingPosition?: Maybe<UnderlyingPosition>;
  underlyingPositions: Array<UnderlyingPosition>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type SubscriptionAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Asset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Asset_Filter>;
};


export type SubscriptionHistoryItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionHistoryItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<HistoryItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<HistoryItem_Filter>;
};


export type SubscriptionInstrumentArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInstrumentsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Instrument_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Instrument_Filter>;
};


export type SubscriptionOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};


export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type SubscriptionReferralCounterArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralCountersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReferralCounter_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralCounter_Filter>;
};


export type SubscriptionUnderlyingPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUnderlyingPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UnderlyingPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UnderlyingPosition_Filter>;
};

export type UnderlyingPosition = {
  __typename?: 'UnderlyingPosition';
  id: Scalars['ID']['output'];
  position: Position;
};

export type UnderlyingPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UnderlyingPosition_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<UnderlyingPosition_Filter>>>;
  position?: InputMaybe<Scalars['String']['input']>;
  position_?: InputMaybe<Position_Filter>;
  position_contains?: InputMaybe<Scalars['String']['input']>;
  position_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  position_ends_with?: InputMaybe<Scalars['String']['input']>;
  position_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_gt?: InputMaybe<Scalars['String']['input']>;
  position_gte?: InputMaybe<Scalars['String']['input']>;
  position_in?: InputMaybe<Array<Scalars['String']['input']>>;
  position_lt?: InputMaybe<Scalars['String']['input']>;
  position_lte?: InputMaybe<Scalars['String']['input']>;
  position_not?: InputMaybe<Scalars['String']['input']>;
  position_not_contains?: InputMaybe<Scalars['String']['input']>;
  position_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  position_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  position_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  position_starts_with?: InputMaybe<Scalars['String']['input']>;
  position_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum UnderlyingPosition_OrderBy {
  Id = 'id',
  Position = 'position',
  PositionCashflowBase = 'position__cashflowBase',
  PositionCashflowQuote = 'position__cashflowQuote',
  PositionEquityBase = 'position__equityBase',
  PositionEquityQuote = 'position__equityQuote',
  PositionFeesBase = 'position__feesBase',
  PositionFeesQuote = 'position__feesQuote',
  PositionId = 'position__id',
  PositionLatestTransactionHash = 'position__latestTransactionHash',
  PositionMoneyMarket = 'position__moneyMarket',
  PositionNumber = 'position__number',
  PositionOpenCost = 'position__openCost',
  PositionQuantity = 'position__quantity',
  PositionRealisedPnLBase = 'position__realisedPnLBase',
  PositionRealisedPnLQuote = 'position__realisedPnLQuote'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']['output']>;
  /** The block number */
  number: Scalars['Int']['output'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']['output']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String']['output'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']['output'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}
