import {
  account$,
  allowance$,
  Base,
  followTransactionWithoutPermit,
  getDeliveryCostForPosition$,
  getPositionProp$,
  network$,
  Quote,
  TransactionStatusType,
} from "@/api"
import { approveSpending } from "@/api/chain"
import { ApprovalType, approvalType$ } from "@/App/state/approvals"
import { MAX_256 } from "@/utils/constants"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import {
  combineLatest,
  exhaustMap,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs"

const [isSubmittingDeliveryApproval$, onSubmittingDeliveryApproval] =
  createSignal<bigint>()
export { onSubmittingDeliveryApproval }

export const approvalNeeded$ = state(
  (id: bigint) =>
    combineLatest([
      getPositionProp$(id, "quote").pipe(
        switchMap((quote) =>
          quote === "ETH" ? of(MAX_256) : allowance$(quote),
        ),
      ),
      getDeliveryCostForPosition$(id),
    ]).pipe(map(([allowance, { deliveryCost }]) => allowance < deliveryCost)),
  false,
)

const followApproveSpending$ = state((id: bigint) =>
  isSubmittingDeliveryApproval$.pipe(
    withLatestFrom(
      approvalType$,
      getDeliveryCostForPosition$(id),
      getPositionProp$(id, "quote") as Observable<Exclude<Base | Quote, "ETH">>,
      account$,
      network$,
    ),
    exhaustMap(
      ([
        ,
        approvalType,
        { deliveryCost },
        quote,
        account,
        {
          addresses: { contango },
        },
      ]) => {
        const amountToApprove =
          approvalType === ApprovalType.TransactionExact
            ? deliveryCost
            : MAX_256
        return followTransactionWithoutPermit(
          approveSpending,
          quote,
          account,
          contango,
          amountToApprove,
        )
      },
    ),
  ),
)

export const isSubmittingApproval$ = state(
  (id: bigint) =>
    followApproveSpending$(id).pipe(
      mapDistinct(
        ({ type }) =>
          type !== TransactionStatusType.Completed &&
          type !== TransactionStatusType.Rejected,
      ),
    ),
  false,
)
