import { Currency, Quote } from "@/api"

export const roundDown = (value: bigint, quote: Quote) => {
  if (value < 0n) value = roundUp(value * -1n, quote) * -1n

  const { precision, displayDecimals } = Currency[quote]
  const truncator = 10n ** BigInt(precision - displayDecimals)

  return (value / truncator) * truncator
}

export const roundUp = (value: bigint, quote: Quote) => {
  if (value < 0n) value = roundDown(value * -1n, quote) * -1n

  const { precision, displayDecimals } = Currency[quote]
  const truncator = 10n ** BigInt(precision - displayDecimals - 1)
  let truncated = value / truncator
  const lastDigit = truncated % 10n
  truncated += lastDigit === 0n ? 0n : 10n - lastDigit
  return truncated * truncator
}

export const roundToTwo = (num: number | string) =>
  +(Math.round(Number(num + "e+2")) + "e-2")

export const roundTo = (num: number, decimals: number) => {
  const res = +(Math.round(Number(num + `e+${decimals}`)) + `e-${decimals}`)
  return Number.isNaN(res) ? 0 : res
}
