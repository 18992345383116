import {
  getModifyCostByLeverage$,
  getPosition$,
  getPositionStatus$,
  maxLeverageAvailableBuffer,
} from "@/api"
import { LeverageEdit } from "@/components/Leverage"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import {
  calculateLeverage,
  calculateMaxOpeningLeverage,
} from "@/utils/financial-utils"
import { justWait, unsuspended } from "@/utils/observable-utils"
import { withPrefix } from "@/utils/test-utils"
import { state, useStateObservable } from "@react-rxjs/core"
import { Fragment } from "react"
import { concat, map, merge, switchMap, withLatestFrom } from "rxjs"
import { usePositionContext } from "../Position.context"
import {
  formChangeEvents$,
  formErrors$,
  hasCriticalError$,
  onEditFormError,
} from "./state/errors"
import { leverageInput$, onLeverageInputChanged } from "./state/inputs"
import { hasLeverageDelta$, leverageValue$ } from "./state/leverage"
import { quantityDelta$ } from "./state/quantity"

const testIdPrefix = "edit-position"

export const leverageBoundariesForCurrentQuantity$ = state(
  (positionId: bigint) =>
    quantityDelta$(positionId).pipe(
      withLatestFrom(getPositionStatus$(positionId), getPosition$(positionId)),
      switchMap(([qtyDelta, { liquidationRatio }, { ltvRatio }]) => {
        const maxOpeningLeverage = calculateMaxOpeningLeverage(
          liquidationRatio,
          ltvRatio,
        )
        return concat(
          justWait(200),
          getModifyCostByLeverage$(
            positionId,
            qtyDelta,
            calculateLeverage(1e6, liquidationRatio),
          ).pipe(map((cost) => ({ ...cost, maxOpeningLeverage }))),
        )
      }),
      map(
        ({
          minDebt,
          underlyingCollateral,
          underlyingDebt,
          maxOpeningLeverage,
        }) => ({
          lowerBound: calculateLeverage(minDebt, underlyingCollateral),
          upperBound: Math.min(
            calculateLeverage(underlyingDebt, underlyingCollateral),
            maxOpeningLeverage,
          ),
          maxOpeningLeverage,
        }),
      ),
      onEditFormError(
        positionId,
        "leverageBoundariesForQuantity",
      )(formChangeEvents$(positionId)),
    ),
)

export const PositionEditLeverageSlider$ = state((positionId: bigint) =>
  unsuspended(
    merge(
      leverageValue$(positionId),
      hasLeverageDelta$(positionId),
      leverageInput$(positionId),
      leverageBoundariesForCurrentQuantity$(positionId),
      hasCriticalError$(positionId),
      formErrors$(positionId, "deltaCost"),
    ),
  ),
)

const MaxLeverageUnavailable = () => {
  const { id } = usePositionContext()
  const { upperBound, maxOpeningLeverage } = useStateObservable(
    leverageBoundariesForCurrentQuantity$(id),
  )
  const hasCriticalError = useStateObservable(hasCriticalError$(id))
  const cantRetrieveQuote = useStateObservable(formErrors$(id, "deltaCost"))
  const maxLeverageUnavailable =
    upperBound + maxLeverageAvailableBuffer < maxOpeningLeverage

  return (
    <FormMessage
      className={maxLeverageUnavailable ? "" : "hidden"}
      testId={withPrefix("max-leverage-unavailable", testIdPrefix)}
      iconType={IconType.Warning}
      visible={
        maxLeverageUnavailable &&
        !(hasCriticalError || cantRetrieveQuote.error !== null)
      }
    >
      <span>
        The maximum leverage for this instrument is unavailable. This can be due
        to insufficient liquidity or exceeding debt limits.
      </span>
    </FormMessage>
  )
}

export const PositionEditLeverageSlider: React.FC = () => {
  const { id } = usePositionContext()

  const value = useStateObservable(leverageValue$(id))

  const leverageBoundaries = useStateObservable(
    leverageBoundariesForCurrentQuantity$(id),
  )

  const props = {
    min: 1,
    max: leverageBoundaries.maxOpeningLeverage,
    value,
    testIdPrefix,
    lowerBound: leverageBoundaries.lowerBound,
    upperBound: leverageBoundaries.upperBound,
  }

  return (
    <Fragment key={`leverage-slider-${id.toString()}`}>
      <LeverageEdit
        {...props}
        onChange={(leverage: number) => {
          onLeverageInputChanged(id, leverage)
        }}
      />
      <MaxLeverageUnavailable />
    </Fragment>
  )
}
