import { Side } from "@/api"
import { BuySellTabButton } from "@/components/Tabs"
import { useStateObservable } from "@react-rxjs/core"
import { merge } from "rxjs"
import { CurrencyPairDropdown } from "./CurrencyPairDropdown"
import {
  filteredPairs$,
  onSideChanged,
  selectedPair$,
  side$,
} from "./state/instrument"
import { onResetForm } from "./state/reset"

const tabOnClick = (side: Side) => () => {
  onResetForm()
  onSideChanged(side)
}

export const DefineInstrument$ = merge(selectedPair$, side$, filteredPairs$)

export const DefineInstrument = () => {
  const selectedPair = useStateObservable(selectedPair$)
  const side = useStateObservable(side$)

  return (
    <div
      className="flex flex-col text-secondary-01 gap-3"
      data-testid="create-ticket--define-instrument"
    >
      <CurrencyPairDropdown selectedPair={selectedPair} />
      <div className="flex gap-1">
        <BuySellTabButton
          className={
            side === "Short" ? "bg-functional-sell-500" : "bg-backgrounds-100"
          }
          isDisabled={selectedPair.Short.length === 0}
          onClick={tabOnClick("Short")}
          testId={`side-${Side.Short}`}
        >
          Sell / Short
        </BuySellTabButton>
        <BuySellTabButton
          className={
            side === "Long" ? "bg-functional-buy-500" : "bg-backgrounds-100"
          }
          isDisabled={selectedPair.Long.length === 0}
          onClick={tabOnClick("Long")}
          testId={`side-${Side.Long}`}
        >
          Buy / Long
        </BuySellTabButton>
      </div>
    </div>
  )
}
