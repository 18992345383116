const tooltipContent = {
  price: "The forward price per unit of base currency.",
  limit: "Worst price you could get due to slippage.",
  spotPrice:
    "The current spot price of the selected instrument, taken from Uniswap.",
  basisRate: {
    text: "Relative value between the entry price and the spot price. If positive the market is in contango, if negative the market is in backwardation.",
    formula: "basis rate = (entry price – spot price) / spot price",
  },
  basis: {
    text: "The absolute difference between the entry price and the spot price. If positive the market is in contango, if negative the market is in backwardation.",
    formula: "basis = entry price - spot price",
  },
  positionValue: {
    text: "",
    formula: "position value = price × quantity",
  },
  leverage: {
    text: "Value by which your equity is multiplied to increase your position exposure. The max value indicates the threshold above which your position is eligible for liquidation.",
    formula: "leverage = 1 / margin",
  },
  margin: {
    text: "Relative value between debt and collateral. The min value indicates the threshold below which your position is eligible for liquidation.",
    formula: "margin = (collateral – debt) / collateral",
  },
  fee: "The transaction fee paid to trade on Contango.",
  expiryDate: "Date at which you can get the base currency delivered.",
  entryPrice:
    "The price at which you are guaranteed to be able to buy each unit of base currency at expiry.",
  exitPrice: "Price you would get if the position was closed now",
  equity: {
    text: "The money you will receive if you were to close the position now",
    formula: "equity = deposits - withdrawals + unrealised PnL",
  },
  fees: "Fees paid up until now to the protocol.",
  totalFees: "Existing fees + estimated fee of closing",
  pnl: {
    text: "The unrealised profit/loss of the position",
    formula:
      "PnL = size * (exit price - entry price) - accrued fees - fees of closing",
  },
  grossPnL: {
    text: "Profit/Loss before fees",
    formula: "profit/loss = position size * (exit price - entry price)",
  },
  collateral: "Deposits - Withdrawals",
  slippage:
    "Your transaction will revert if the price changes unfavorably by more than this percentage",
  amountToReceive: "Estimated amount to be received on the user wallet",
  liquidationPrice:
    "When the mark price reaches this value, your position will be eligible for liquidation.",
  deliveryCost: "Cost of repaying debt",
  deliveryQuantity: "Amount you will receive from physical delivery.",
  markPrice: `
  Mark Price refers to an estimated true value of a contract. It takes into consideration the fair value of an asset to prevent unnecessary liquidations during a volatile market.
  Mark Price is used as the basis for margin/leverage calculations, it is also the price which drives liquidation.
  The Mark Price is derived using a combination of spot + interest rate oracles.
  `,
} as const

export const tooltipMapper = (key: keyof typeof tooltipContent) => {
  const property = tooltipContent[key]
  return typeof property === "string" ? (
    property
  ) : (
    <div>
      <span>{property.text}</span>
      <span className="block pt-2 text-sm italic">{property.formula}</span>
    </div>
  )
}

export type TooltipKey = Parameters<typeof tooltipMapper>["0"]
