import { classNames } from "@/utils/classNames"
import { CheckIcon } from "@heroicons/react/solid"

export const Checkbox: React.FC<{
  checked: boolean
  onChange: Function
  disabled?: boolean
}> = ({ checked, onChange, disabled }) => {
  return (
    <button
      onClick={() => onChange(!checked)}
      disabled={disabled}
      className={classNames(
        checked ? "bg-accents-500" : "bg-backgrounds-0",
        "w-5 h-5 rounded border border-accents-500 flex flex-row justify-center items-center",
      )}
    >
      <CheckIcon
        className={classNames(
          checked ? "" : "invisible",
          "w-5 h-5 text-secondary-01",
        )}
      />
    </button>
  )
}
