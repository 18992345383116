import {
  account$,
  allowance$,
  createPosition,
  network$,
  setHasPendingPosition,
} from "@/api"
import {
  followTransaction,
  TransactionStatus,
  TransactionStatusType,
} from "@/api/utils"
import { MAX_256 } from "@/utils/constants"
import { decimalProduct } from "@/utils/financial-utils"
import { isTxPending } from "@/utils/isTxPending"
import { absolute } from "@/utils/maths-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import {
  combineLatest,
  exhaustMap,
  filter,
  ignoreElements,
  map,
  merge,
  share,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs"
import { hasEnoughBalance$ } from "../YouPay"
import { slippage$ } from "./inputs"
import { instrument$ } from "./instrument"
import { costData$ } from "./price"
import { onResetForm } from "./reset"
import { validQuantity$ } from "./validations"

const [userSubmitPosition$, onUserSubmitPosition] = createSignal()
export { onUserSubmitPosition }

export const needsApproval$ = state(
  combineLatest([
    costData$,
    instrument$.pipe(
      switchMap(({ quote }) =>
        allowance$(quote).pipe(
          map((allowance) => ({
            quote,
            allowance: quote === "ETH" ? MAX_256 : allowance,
          })),
        ),
      ),
    ),
  ]).pipe(
    map(([{ collateralUsed }, { allowance }]) => collateralUsed > allowance),
  ),
  false,
)

const createPositionArgs$ = combineLatest([
  instrument$,
  validQuantity$,
  costData$,
  needsApproval$,
]).pipe(
  withLatestFrom(account$, network$, slippage$),
  mapDistinct(
    ([
      [
        { id, quote },
        quantity,
        { uniswapFee, collateralUsed, baseLendingLiquidity, cost },
        needsApproval,
      ],
      account,
      network,
      slippage,
    ]) => {
      if (quantity === null) return null

      const spending = {
        token: quote,
        value: needsApproval || quote === "ETH" ? collateralUsed : 0n,
        chainId: network.chainData.chainId,
      }
      return [
        spending,
        account,
        id,
        account,
        quantity,
        absolute(decimalProduct(cost, 1 + slippage / 100)),
        collateralUsed,
        spending.token === "ETH" ? network.addresses.contango : account,
        baseLendingLiquidity,
        uniswapFee,
      ] as const
    },
  ),
  share(),
)

export const isFormValid$ = state(
  combineLatest([
    createPositionArgs$.pipe(mapDistinct(Boolean)),
    hasEnoughBalance$,
  ]).pipe(mapDistinct(([args, hasBalance]) => args && hasBalance)),
  false,
)

const followcreatePosition$ = followTransaction(createPosition)

const submit$ = state(
  userSubmitPosition$.pipe(
    withLatestFrom(createPositionArgs$),
    exhaustMap(([, args]) => {
      if (!args) return []
      return followcreatePosition$(...args)
    }),
    tap((x) => {
      if (TransactionStatusType.Submitted === x.type) {
        setHasPendingPosition(true)
      }
      if (TransactionStatusType.Completed === x.type && !x.ok) {
        setHasPendingPosition(false)
      }
    }),
  ),
)

const resetForm$ = submit$.pipe(
  filter(
    (
      status,
    ): status is TransactionStatus & {
      type: TransactionStatusType.Completed
    } => status.type === TransactionStatusType.Completed && status.ok,
  ),
  tap(onResetForm),
  ignoreElements(),
)

export const isSubmitting$ = state(
  merge(submit$, resetForm$).pipe(mapDistinct(isTxPending)),
  false,
)
