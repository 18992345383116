import { gasPrice$, network$, newHeads$ } from "@/api"
import { latestBlockTimestamp$ } from "@/api/chain/common"
import { formatCurrency } from "@/utils/currency-utils"
import { justWait, mapDistinct } from "@/utils/observable-utils"
import { withSuspense } from "@/utils/withSuspense"
import { state, StateObservable, useStateObservable } from "@react-rxjs/core"
import { switchMapSuspended } from "@react-rxjs/utils"
import { concat, map, merge, switchMap } from "rxjs"

const GWEI_PRECISION = 9
const formattedGasPrice$ = state(
  gasPrice$.pipe(
    mapDistinct((value) =>
      formatCurrency(value, GWEI_PRECISION, { nDecimals: 2 }),
    ),
  ),
)

enum Health {
  GOOD,
  SO_SO,
  BAD,
}
const healthLight$ = concat(
  [Health.GOOD],
  justWait(16_000),
  [Health.SO_SO],
  justWait(7_000),
  [Health.BAD],
)

const currentBlock$ = state(
  network$.pipe(
    switchMapSuspended(() => newHeads$.pipe(map((x) => x.blockNumber))),
  ),
)

const health$: StateObservable<Health> = state(
  currentBlock$.pipe(switchMap(() => healthLight$)),
)

const healthColors: Record<Health, string> = {
  [Health.GOOD]: "text-secondary-05",
  [Health.SO_SO]: "text-secondary-06",
  [Health.BAD]: "text-secondary-07",
}

export const NetworkStatus$ = merge(
  currentBlock$,
  formattedGasPrice$,
  health$,
  latestBlockTimestamp$,
)
export const NetworkStatus = withSuspense(null, () => {
  const health = useStateObservable(health$)
  const latestBlockTime = useStateObservable(latestBlockTimestamp$)

  return (
    <div className="bg-backgrounds-0 border border-backgrounds-400 rounded-full px-4 py-1 font-primary text-sm text-fontColor-100">
      <span data-testid="latest-block-date">
        {new Date(latestBlockTime * 1000).toLocaleString()}
      </span>{" "}
      {formattedGasPrice$} gwei -{" "}
      <span
        data-testid="network-status--block-number"
        className={healthColors[health]}
      >
        {currentBlock$}
      </span>
    </div>
  )
})
