import { solidityFn } from "@unstoppablejs/solidity-bindings";
import { address,Tuple,uint,uint8,bytes32 } from "solidity-codecs";

const a = [address] as [owner: typeof address];
const b = Tuple(uint);
const c = [address, address, uint, uint, uint8, bytes32, bytes32] as [owner: typeof address, spender: typeof address, amount: typeof uint, deadline: typeof uint, v: typeof uint8, r: typeof bytes32, s: typeof bytes32];
const d = Tuple();

export const nonces = solidityFn("nonces", a, b, 1);
export const permit = solidityFn("permit", c, d, 2);




export interface IERC2612 {
  functions: typeof nonces | typeof permit,
  events: never
}