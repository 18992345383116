import { getPosition$, getPositionProp$ } from "@/api"
import {
  CurrencyDisplay,
  formatDelta,
  renderNumberWithInversion,
} from "@/components/CurrencyDisplay"
import { ProtocolIcon } from "@/components/CurrencyIcon"
import { SlippageInput } from "@/components/Slippage"
import { invertNumber } from "@/utils/inverted-pairs-utis"
import { withPrefix } from "@/utils/test-utils"
import { withBlur } from "@/utils/withLoading"
import { state, useStateObservable } from "@react-rxjs/core"
import { combineLatest, map, merge, withLatestFrom } from "rxjs"
import { usePositionContext } from "../../Position.context"
import { posDivider } from "../../PositionView/Shared"
import { deltaCost$, newOpenCost$ } from "../state/cost"
import { onSlippageChanged, slippage$ } from "../state/inputs"
import { quantityDelta$ } from "../state/quantity"
import { summaryState$ } from "./EditPositionSummary"
import { SummaryRow, SummaryTable } from "./SummaryTable"

export const transactionFee$ = state((positionId: bigint) =>
  deltaCost$(positionId).pipeState(map(({ fee }) => fee)),
)

const getTestId = (val: string) => withPrefix(val, "edit-transaction-summary")

const formattedQuantityDelta$ = state((id: bigint) =>
  combineLatest([quantityDelta$(id), getPosition$(id)]).pipe(
    map(([delta, { base, decimals }]) => {
      if (delta === 0n) return "No change"
      return formatDelta(delta, base, getTestId("quantity"), {
        nDecimals: decimals,
        padToDecimals: false,
      })
    }),
    withBlur(),
  ),
)

const deltaOpenCost$ = state((id: bigint) =>
  combineLatest([newOpenCost$(id), getPositionProp$(id, "openCost")]).pipe(
    withLatestFrom(getPositionProp$(id, "quote")),
    map(([[cost, prev], quote]) => {
      const delta = cost + prev
      return formatDelta(delta, quote, getTestId("deltaValue"))
    }),
    withBlur(),
  ),
)

const spotPriceFormatted$ = state((id: bigint) =>
  deltaCost$(id).pipe(
    map(({ spotPrice, ...instrument }) =>
      renderNumberWithInversion(spotPrice, instrument, getTestId("spotPrice")),
    ),
    withBlur(),
  ),
)

const deltaPriceFormatted$ = state((id: bigint) =>
  deltaCost$(id).pipe(
    map(({ entryPrice, ...instrument }) =>
      renderNumberWithInversion(entryPrice, instrument, getTestId("price")),
    ),
    withBlur(),
  ),
)

const deltaBasis$ = state((id: bigint) =>
  deltaCost$(id).pipe(
    map(({ spotPrice, entryPrice, side, base, quote, decimals }) => {
      let basis = entryPrice - spotPrice
      let ccy = quote
      if (side === "Short") {
        basis =
          invertNumber(entryPrice, quote, base) -
          invertNumber(spotPrice, quote, base)
        ccy = base
      }
      return formatDelta(basis, ccy, getTestId("basis"), {
        nDecimals: decimals,
      })
    }),
    withBlur(),
  ),
)

const transactionFeeFormatted$ = state((positionId: bigint) =>
  transactionFee$(positionId).pipeState(
    withLatestFrom(getPositionProp$(positionId, "quote")),
    map(([fee, quote]) => (
      <CurrencyDisplay
        value={fee}
        currencyId={quote}
        testId={getTestId("fee")}
        formatOptions={{ padToDecimals: false }}
      />
    )),
    withBlur(),
  ),
)

export const EditTransactionSummary$ = state((id: bigint) =>
  merge(
    transactionFeeFormatted$(id),
    formattedQuantityDelta$(id),
    spotPriceFormatted$(id),
    deltaBasis$(id),
    deltaPriceFormatted$(id),
    slippage$(id),
    deltaOpenCost$(id),
  ),
)

export const EditTransactionSummary: React.FC = () => {
  const { id } = usePositionContext()
  const summaryState = useStateObservable(summaryState$(id))

  return (
    <SummaryTable>
      {summaryState === "quantity_increase" ? (
        <>
          <SummaryRow title="Order Size">
            {formattedQuantityDelta$(id)}
          </SummaryRow>
          {posDivider}
          <div className="flex gap-1">
            <ProtocolIcon protocol="uniswap" />
            <SummaryRow tooltipKey="spotPrice" title="Spot Price">
              {spotPriceFormatted$(id)}
            </SummaryRow>
          </div>
          <div className="flex gap-1">
            <ProtocolIcon protocol="yield" />
            <SummaryRow tooltipKey="basis" title="Basis">
              {deltaBasis$(id)}
            </SummaryRow>
          </div>
          <div className="flex gap-1">
            <ProtocolIcon protocol="contango" />
            <SummaryRow tooltipKey="entryPrice" title="Entry Price">
              {deltaPriceFormatted$(id)}
            </SummaryRow>
          </div>
          {posDivider}
        </>
      ) : null}
      {summaryState === "quantity_decrease" ? (
        <>
          <SummaryRow title="Order Size">
            {formattedQuantityDelta$(id)}
          </SummaryRow>
          {posDivider}
          <div className="flex gap-1">
            <ProtocolIcon protocol="uniswap" />
            <SummaryRow tooltipKey="spotPrice" title="Spot Price">
              {spotPriceFormatted$(id)}
            </SummaryRow>
          </div>
          <div className="flex gap-1">
            <ProtocolIcon protocol="yield" />
            <SummaryRow tooltipKey="basis" title="Basis">
              {deltaBasis$(id)}
            </SummaryRow>
          </div>
          <div className="flex gap-1">
            <ProtocolIcon protocol="contango" />
            <SummaryRow tooltipKey="exitPrice" title="Exit Price">
              {deltaPriceFormatted$(id)}
            </SummaryRow>
          </div>
          {posDivider}
        </>
      ) : null}
      <SummaryRow title="Fee">{transactionFeeFormatted$(id)}</SummaryRow>
      <SummaryRow tooltipKey="positionValue" title="Position Value">
        {deltaOpenCost$(id)}
      </SummaryRow>
      {posDivider}
      <SummaryRow
        tooltipKey="slippage"
        title="Slippage Tolerance"
        className="py-5"
      >
        <div className="w-[7rem]">
          <SlippageInput
            slippage$={slippage$(id)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onSlippageChanged(id, event)
            }
            testIdPrefix="edit-position-slippage"
          />
        </div>
      </SummaryRow>
    </SummaryTable>
  )
}
