import { getModifyCostByLeverage$, getPosition$ } from "@/api"
import { divideCurrency } from "@/utils/currency-utils"
import { absolute } from "@/utils/maths-utils"
import { justWait } from "@/utils/observable-utils"
import { sinkSuspense, state, SUSPENSE } from "@react-rxjs/core"
import { combineLatest, concat, map, of, switchMap, withLatestFrom } from "rxjs"
import { formChangeEvents$, onEditFormError } from "./errors"
import { leverageValue$ } from "./leverage"
import { quantityDelta$ } from "./quantity"

export const deltaCost$ = state((positionId: bigint) =>
  combineLatest({
    quantityDelta: quantityDelta$(positionId),
    leverageValue: leverageValue$(positionId),
  }).pipe(
    withLatestFrom(getPosition$(positionId)),
    switchMap(([{ quantityDelta, leverageValue }, position]) => {
      return concat(
        of(SUSPENSE),
        justWait(200),
        getModifyCostByLeverage$(positionId, quantityDelta, leverageValue).pipe(
          map((cost) => {
            const spotPrice = absolute(
              divideCurrency(
                { value: cost.spotCost, currency: position.quote },
                { value: quantityDelta, currency: position.base },
              ),
            )
            const entryPrice = absolute(
              divideCurrency(
                { value: cost.cost, currency: position.quote },
                { value: quantityDelta, currency: position.base },
              ),
            )
            return {
              ...cost,
              ...position,
              spotPrice,
              entryPrice,
              quantityDelta,
            }
          }),
        ),
      )
    }),
    onEditFormError(positionId, "deltaCost")(formChangeEvents$(positionId)),
    sinkSuspense(),
  ),
)

export const newOpenCost$ = state((positionId: bigint) =>
  combineLatest([
    quantityDelta$(positionId),
    getPosition$(positionId),
    deltaCost$(positionId),
  ]).pipe(
    sinkSuspense(),
    map(
      ([
        qtyDelta,
        { openCost, quantity: existingQty },
        { cost, financingCost, needsBatchedCall, base, quote },
      ]) => {
        const quantity = existingQty + qtyDelta

        // when decreasing a position, the resulting forward price is calculated differently.
        // The existing openCost that is closed as a result of the decrease is proportional
        // to the relative size of the decrease.
        let newCost = openCost + cost

        if (qtyDelta < 0n) {
          const denominator = divideCurrency(
            { value: existingQty, currency: base },
            { value: quantity, currency: base },
          )
          const existingOpenCostAfterDecrease = divideCurrency(
            { value: openCost, currency: quote },
            { value: denominator, currency: base },
          )
          newCost = existingOpenCostAfterDecrease

          if (needsBatchedCall) newCost -= financingCost
        }

        return absolute(newCost)
      },
    ),
  ),
)
