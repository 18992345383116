import {
  account$,
  Base,
  followTransactionWithoutPermit,
  getPositionProp$,
  network$,
  Quote,
  TransactionStatusType,
} from "@/api"
import { approveSpending } from "@/api/chain"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { exhaustMap, Observable, withLatestFrom } from "rxjs"
import { collateralUsed$ } from "./leverage"

const [userSubmitEditApproval$, onUserSubmitEditApproval] = createSignal()
export { onUserSubmitEditApproval }

const followApproveEditSpending$ = state((id: bigint) =>
  userSubmitEditApproval$.pipe(
    withLatestFrom(
      collateralUsed$(id),
      getPositionProp$(id, "quote") as Observable<Exclude<Base | Quote, "ETH">>,
      account$,
      network$,
    ),
    exhaustMap(
      ([
        ,
        amount,
        quote,
        account,
        {
          addresses: { contango },
        },
      ]) => {
        return followTransactionWithoutPermit(
          approveSpending,
          quote,
          account,
          contango,
          amount,
        )
      },
    ),
  ),
)

export const isSubmittingEditApproval$ = state(
  (id: bigint) =>
    followApproveEditSpending$(id).pipe(
      mapDistinct(
        ({ type }) =>
          type !== TransactionStatusType.Completed &&
          type !== TransactionStatusType.Rejected,
      ),
    ),
  false,
)
