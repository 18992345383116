import { IconType } from "@/components/NotificationIcon"
import { classNames } from "@/utils/classNames"
import { roundToTwo } from "@/utils/rounding"
import { withPrefix } from "@/utils/test-utils"
import { FormMessage } from "./FormMessage"

export const marketImpactAboveLimit = (marketImpact: number) =>
  marketImpact > 0.01

const warningText = (marketImpact: number) =>
  `The market impact for this transaction is high: ${roundToTwo(
    marketImpact * 100,
  )}%`

const fetchErrorText = "Unable to estimate market impact."

export const MarketImpactWarning: React.FC<{
  marketImpact: number
  errorFetching: boolean
  className?: string
  testId: string
}> = ({ marketImpact, errorFetching, className, testId }) => {
  const shouldShow = marketImpactAboveLimit(marketImpact) || errorFetching
  return (
    <FormMessage
      testId={withPrefix("market-impact", testId)}
      iconType={IconType.Warning}
      className={classNames(className)}
      visible={shouldShow}
    >
      <span>{errorFetching ? fetchErrorText : warningText(marketImpact)}</span>
    </FormMessage>
  )
}
