import { maxLeverageAvailableBuffer } from "@/api"
import { DisabledLeverage, LeverageEdit } from "@/components/Leverage"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import { useStateObservable } from "@react-rxjs/core"
import { Fragment, Suspense } from "react"
import { merge } from "rxjs"
import { onLeverageInputChanged } from "./state/inputs"
import { leverage$, leverageBoundariesForQuantity$ } from "./state/leverage"
import { maxLeverageUnavTestId } from "./testIds"

export const Equity$ = merge(leverageBoundariesForQuantity$, leverage$)

const CreateLeverageSlider: React.FC = () => {
  const leverage = useStateObservable(leverage$)

  const leverageBoundaries = useStateObservable(leverageBoundariesForQuantity$)

  const props = {
    min: 1,
    max: leverageBoundaries.maxOpeningLeverage,
    value: leverage,
    lowerBound: leverageBoundaries.lowerBound,
    upperBound: leverageBoundaries.upperBound,
    testIdPrefix: "create-ticket",
  }

  const disabled = leverageBoundaries.upperBound === 1

  return (
    <LeverageEdit
      {...props}
      disabled={disabled}
      onChange={(leverage) => onLeverageInputChanged(leverage)}
    />
  )
}

export const EquityModule = () => {
  return (
    <Fragment key="create-ticket-equity-module">
      <Suspense
        fallback={<DisabledLeverage testIdPrefix="create-ticket-fallback" />}
      >
        <CreateLeverageSlider />
      </Suspense>
      <Suspense fallback={null}>
        <MaxLeverageUnavailable />
      </Suspense>
    </Fragment>
  )
}

const MaxLeverageUnavailable: React.FC = () => {
  const { maxOpeningLeverage, upperBound } = useStateObservable(
    leverageBoundariesForQuantity$,
  )

  const maxLeverageUnavailable =
    upperBound !== 1 &&
    upperBound + maxLeverageAvailableBuffer < maxOpeningLeverage

  return (
    <FormMessage
      testId={maxLeverageUnavTestId}
      iconType={IconType.Warning}
      visible={maxLeverageUnavailable}
    >
      <span>
        The maximum leverage for this instrument is unavailable. This can be due
        to insufficient liquidity or exceeding debt limits.
      </span>
    </FormMessage>
  )
}
