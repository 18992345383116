import { Base, Currency, Instrument, Quote, Side } from "@/api"
import { modifyPrecision } from "./precision"

export const invertPriceIfShort = (
  side: Side,
  price: bigint,
  base: Base,
  quote: Quote,
) => {
  const ccy = side === "Short" ? base : quote
  if (side === "Short") {
    const [from, to] = [
      Currency[quote].precision,
      Currency[base].precision,
    ].map(BigInt)
    const value = Currency[quote].precisionMultiplier ** 2n / price
    price = modifyPrecision(value, from, to)
  }
  return [price, ccy] as const
}

export const invertNumber = (
  number: bigint,
  fromCurrency: Quote,
  toCurrency: Base,
) => {
  if (number === 0n) return number
  const [from, to] = [
    Currency[fromCurrency].precision,
    Currency[toCurrency].precision,
  ].map(BigInt)
  const value = Currency[fromCurrency].precisionMultiplier ** 2n / number
  return modifyPrecision(value, from, to)
}

export const invertIfShort = (
  value: bigint,
  { side, base, quote }: Pick<Instrument, "side" | "base" | "quote">,
) => {
  if (side === "Short") {
    const inverted = invertNumber(value, quote, base)
    return [inverted, base] as const
  }
  return [value, quote] as const
}
