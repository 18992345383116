import { CurrencyId } from "@/api"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { absolute } from "@/utils/maths-utils"

export const Payment: React.FC<{
  amount: bigint
  currency: CurrencyId
  testId: string
}> = ({ amount, testId, currency }) => {
  return (
    <div
      className="flex flex-row justify-center text-base gap-1 pt-3"
      data-testid={testId}
    >
      <span>{amount > 0n ? "You Deposit " : "You Receive "}</span>
      <CurrencyDisplay
        className="text-base"
        value={absolute(amount)}
        currencyId={currency}
      />
    </div>
  )
}
