import { classNames } from "@/utils/classNames"
import { Disclosure } from "@headlessui/react"
import { merge } from "rxjs"
import { NetworkStatus, NetworkStatus$ } from "./NetworkStatus"
import { Balances, WalletMenu } from "./Wallet"
import { WalletMenu$ } from "./Wallet/WalletMenu"

interface ShellProps {
  children: React.ReactNode
}
export const Shell$ = merge(NetworkStatus$, WalletMenu$)
export const Shell: React.FC<ShellProps> = ({ children }) => {
  return (
    <div>
      <div className="fixed top-0 left-0 right-0 z-10">
        <SmallNav className="lg:hidden" />
        <LargeNav className="hidden lg:flex" />
      </div>
      <main>
        <div
          className={
            "pt-14 lg:pt-16 text-fontColor-0 font-primary md:h-screen max-w-[1920px] mx-auto"
          }
        >
          {children}
        </div>
      </main>
      <div className="hidden md:block fixed bottom-6 px-6">
        <NetworkStatus />
      </div>
    </div>
  )
}

const SmallNav: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Disclosure
      as="nav"
      className={classNames(
        `flex w-full z-10 h-14 backdrop-blur bg-backgrounds-0/30 border-b border-b-backgrounds-300`,
        className,
      )}
    >
      <div className={"max-w-7xl min-w-sm mx-auto py-4 px-4 sm:px-6 lg:px-8"}>
        <div className="flex items-center h-8">
          <div className="inset-y-0 my-0 mx-auto right-0 left-0 w-fit flex-shrink-0">
            <img
              alt={"logo"}
              src={import.meta.env.BASE_URL + "logo/logo_small.svg"}
            />
          </div>
          <div className="absolute flex items-center inset-y-0 margin-auto-0 right-0">
            <WalletMenu />
          </div>
        </div>
      </div>
    </Disclosure>
  )
}

const LargeNav: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classNames(
        "w-full z-10 flex flex-row justify-between items-center min-w-sm px-4 sm:px-6 lg:px-8 h-16 mx-0 backdrop-blur border-b border-b-backgrounds-300",
        className,
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <img
          className="w-[110px] pt-2"
          alt={"logo"}
          src={import.meta.env.BASE_URL + "logo/logo_full.svg"}
        />
      </div>
      <div className="flex flex-row items-center text-secondary-01">
        <Balances />
        <div className="h-8">
          <WalletMenu />
        </div>
      </div>
    </div>
  )
}
