import { getAllQuotes } from "@/api/sdk/meanFinance"
import { divideCurrency } from "@/utils/currency-utils"
import { onUncaughtError } from "@/utils/error-utils"
import { calculatePnlPercentage } from "@/utils/financial-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createKeyedSignal } from "@react-rxjs/utils"
import { fromHex } from "@unstoppablejs/utils"
import {
  defer,
  interval,
  map,
  pipe,
  startWith,
  switchMap,
  withLatestFrom,
} from "rxjs"
import { address, bytes, bytes32, Struct, Tuple, uint } from "solidity-codecs"
import { nftSafeTransferFrom } from "./chain/contango"
import { Instrument } from "./generated"
import {
  getDeliveryCostForPosition$,
  getPosition$,
  onPositionError,
} from "./queries"
import { bytes32str, followTransactionWithoutPermit } from "./utils"
import { account$, network$ } from "./wallet"

const [slippageChanged$, onSettlementSlippageChanged] = createKeyedSignal(
  ({ positionId }) => positionId,
  (positionId: bigint, e: React.ChangeEvent<HTMLInputElement>) => ({
    positionId,
    value: Number(e.target.value),
  }),
)

export { onSettlementSlippageChanged }

export const settlementSlippage$ = state(
  pipe(
    slippageChanged$,
    mapDistinct((e) => e.value),
  ),
  0.15,
)

const NFTCallbackStruct = Tuple(
  Struct({
    symbol: bytes32,
    base: address,
    quote: address,
    openQuantity: uint,
    spender: address,
    dex: address,
    swapBytes: bytes,
    to: address,
  }),
)
type CallData = Parameters<typeof NFTCallbackStruct.enc>[0]

export const cashSettle = (
  owner: string,
  cashSettler: string,
  positionId: bigint,
  callData: CallData,
) => {
  return followTransactionWithoutPermit(
    nftSafeTransferFrom,
    owner,
    owner,
    cashSettler,
    positionId,
    NFTCallbackStruct.enc(callData),
  )
}

export const settlementData = state((positionId: bigint) =>
  interval(5000).pipe(
    startWith(0),
    withLatestFrom(
      getPosition$(positionId),
      account$,
      network$,
      settlementSlippage$(positionId),
    ),
    switchMap(
      ([, { base, quote, quantity, id }, account, { addresses }, slippage]) => {
        return defer(() =>
          getAllQuotes(
            addresses[base],
            addresses[quote],
            quantity,
            slippage,
            addresses.cashSettler,
          ),
        ).pipe(
          map(([{ buyToken, sellToken, buyAmount, tx, source }]) => {
            const callData: CallData = [
              {
                symbol: bytes32str.enc(id),
                base: sellToken.address,
                quote: buyToken.address,
                openQuantity: quantity,
                spender: source.allowanceTarget,
                dex: tx.to,
                swapBytes: fromHex(tx.data),
                to: account,
              },
            ]

            return {
              callData,
              account,
              cashSettler: addresses.cashSettler,
              baseValueInQuote: BigInt(buyAmount.amount),
            }
          }),
          onUncaughtError((error) =>
            onPositionError(positionId, "settlementQuery", error),
          )(),
        )
      },
    ),
  ),
)

export const expiredPositionData$ = state((positionId: bigint) =>
  settlementData(positionId).pipe(
    withLatestFrom(
      getPosition$(positionId),
      getDeliveryCostForPosition$(positionId),
    ),
    map(([settlementData, position, { deliveryFee }]) => {
      const collateral = position.collateral - position.fees
      const rawPnL = position.openCost + settlementData.baseValueInQuote
      const pnl = rawPnL - position.fees - deliveryFee
      const equity = position.collateral + position.fees + pnl
      const pnlPercentage = calculatePnlPercentage(position.openCost, pnl)
      const base = Instrument[position.instrumentId].base
      const closingPrice = divideCurrency(
        { value: settlementData.baseValueInQuote, currency: base },
        { value: position.quantity, currency: base },
      )
      return {
        rawPnL,
        pnl,
        collateral,
        equity,
        pnlPercentage,
        closingPrice,
        incurredFees: position.fees,
        deliveryFee,
      }
    }),
  ),
)
