import { CurrencyId } from "@/api"
import { NoChange } from "@/App/Positions/Position/PositionView/Shared"
import { classNames } from "@/utils/classNames"
import { formatKnownCurrency } from "@/utils/currency-utils"
import { formatDelta } from "./CurrencyDisplay"

export const PnL: React.FC<{
  pnl: bigint
  pnlPercentage: number
  currency: CurrencyId
  testId?: string
}> = ({ pnl, currency, pnlPercentage, testId }) => {
  const textColour = getPnlColor(pnl)

  return (
    <span
      data-testid={testId}
      className={classNames("whitespace-nowrap w-fit", textColour)}
    >
      {`${formatKnownCurrency(pnl, currency)} (${Math.abs(pnlPercentage)}%)`}
    </span>
  )
}

export const PnLDelta: React.FC<{
  pnl: bigint
  currency: CurrencyId
  testId?: string
}> = ({ pnl, currency, testId }) => {
  if (pnl === 0n) return <NoChange testId={testId} />
  return (
    <div className={getPnlColor(pnl)}>
      {formatDelta(pnl, currency, testId ?? "absolute-pnl")}
    </div>
  )
}

export const getPnlColor = (pnl: bigint) =>
  pnl >= 0 ? "text-functional-success-300" : "text-functional-error-400"
