import {
  account$,
  ChainId,
  Network,
  network$,
  onRequestAccounts,
  onRequestSwitchNetwork,
  switchingToNetworksId$,
  userRequestedAccounts$,
  WalletError,
  walletError$,
  WalletErrorType,
} from "@/api"
import { Checkbox } from "@/components/Checkbox"
import { DropDown } from "@/components/DropDown"
import { DropDownOption } from "@/components/DropDownOption"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import { PrimaryButton } from "@/components/PrimaryButton"
import { SecondaryButton } from "@/components/SecondaryButton"
import { addTrace, getStringifiedTraces, stopTraces } from "@/utils/traces"
import {
  RemoveSubscribe,
  Subscribe,
  useStateObservable,
} from "@react-rxjs/core"
import { Fragment, useEffect, useRef, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { skip } from "rxjs"

const NoProvider: React.FC<{
  type: WalletErrorType
}> = ({ type }) => (
  <div className="flex flex-col">
    <h4 className="uppercase text-accents-400">Install Ethereum Provider</h4>
    <p className="text-fontColor-0">
      {type === WalletErrorType.noProvider
        ? "No Ethereum Provider was detected, please install a valid provider like Metamask"
        : "There seems to be something overriding your Ethereum Provider..."}
    </p>
    <PrimaryButton
      className="mt-5 sm:w-1/2"
      onClick={() => {
        location.reload()
      }}
    >
      Retry
    </PrimaryButton>
  </div>
)

const NoAccount: React.FC = () => {
  const { requesting: isConnecting } = useStateObservable(
    userRequestedAccounts$,
  )

  const [previouslyAcceptedTerms, setPreviouslyAcceptedTerms] = useState(false)
  const [hasAcceptedTerms, setAcceptTerms] = useState(false)

  const termsRef = useRef<boolean>()
  termsRef.current = hasAcceptedTerms

  useEffect(() => {
    const storedValue = JSON.parse(
      localStorage.getItem("hasAcceptedContangoDisclaimer") ?? "false",
    )
    termsRef.current
    setPreviouslyAcceptedTerms(storedValue)

    return () => {
      const storedValue = JSON.parse(
        localStorage.getItem("hasAcceptedContangoDisclaimer") ?? "false",
      )
      localStorage.setItem(
        "hasAcceptedContangoDisclaimer",
        JSON.stringify(storedValue || termsRef.current),
      )

      JSON.parse(
        localStorage.getItem("hasAcceptedContangoDisclaimer") ?? "false",
      )
    }
  }, [])

  const handleSubmit = () => {
    onRequestAccounts()
  }

  return (
    <div className="flex flex-col">
      <h4 className="text-accents-400 text-lg pb-4">Connect Wallet</h4>
      {previouslyAcceptedTerms ? (
        <span className="text-sm text-fontColor-0">
          Please sign into a wallet and connect account.
        </span>
      ) : (
        <FormMessage iconType={IconType.Warning} visible={true}>
          Trading Derivatives markets on margin carries a high level of risk,
          and may not be suitable for all investors/traders
        </FormMessage>
      )}
      <SupportedLists />
      {!previouslyAcceptedTerms && (
        <TermsCheck
          isConnecting={isConnecting}
          hasAcceptedTerms={hasAcceptedTerms}
          setAcceptTerms={setAcceptTerms}
        />
      )}
      <PrimaryButton
        className="sm:w-1/2"
        onClick={handleSubmit}
        disabled={
          !(previouslyAcceptedTerms || hasAcceptedTerms) || isConnecting
        }
      >
        {isConnecting ? "Connecting" : "Connect a Wallet"}
      </PrimaryButton>
    </div>
  )
}

const TermsCheck: React.FC<{
  isConnecting: boolean
  hasAcceptedTerms: boolean
  setAcceptTerms: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isConnecting, hasAcceptedTerms, setAcceptTerms }) => {
  return (
    <div>
      <div className="flex flex-row mb-4 items-center">
        <Checkbox
          disabled={isConnecting}
          checked={hasAcceptedTerms}
          onChange={() => setAcceptTerms((prev) => !prev)}
        />
        <span className="ml-4 text-fontColor-0 text-sm">
          By checking you are confirming that you have read and agree to the our{" "}
          <Hyperlink link="https://docs.google.com/document/d/1Spd46_JTQtyUehzSHj0PwCo2E1ilwHZdD-h7T3Wwe_I/edit?usp=sharing">
            Terms and Conditions
          </Hyperlink>{" "}
          and our{" "}
          <Hyperlink link="https://docs.google.com/document/d/1N9iS0Ib9H2-tI-1OwQSfG_s5QZyav_r4JXruiVPex88/edit?usp=sharing">
            Privacy Policy
          </Hyperlink>
          .
        </span>
      </div>
    </div>
  )
}

const Hyperlink: React.FC<{ link: string; children: string }> = ({
  link,
  children,
}) => (
  <a className="text-accents-400 underline" href={link}>
    {children}
  </a>
)

const ProviderDisconnected: React.FC = () => (
  <p className="text-base">
    Your Ethereum Provider got disconnected. Trying to reconnect you to a new
    instance...
  </p>
)

const SupportedLists: React.FC = () => (
  <div className="flex flex-col text-sm text-fontColor-400 bg-backgrounds-200 my-4 p-4 rounded-lg divide-y divide-backgrounds-300">
    <div className="pb-2">
      <p>We currently support the following browsers:</p>
      <ul className="grid sm:grid-cols-2 gap-2 my-3">
        <SupportedOption>
          Brave
          <img
            className="h-6"
            alt="brave logo"
            src={import.meta.env.BASE_URL + "externalIcons/brave_logo.svg"}
          />
        </SupportedOption>
        <SupportedOption>
          Chrome
          <img
            className="h-6"
            alt="chrome logo"
            src={import.meta.env.BASE_URL + "externalIcons/chrome_logo.svg"}
          />
        </SupportedOption>
        <SupportedOption>
          Edge
          <img
            className="h-6"
            alt="edge logo"
            src={import.meta.env.BASE_URL + "externalIcons/edge_logo.svg"}
          />
        </SupportedOption>
        <SupportedOption>
          Firefox
          <img
            className="h-6"
            alt="firefox logo"
            src={import.meta.env.BASE_URL + "externalIcons/firefox_logo.svg"}
          />
        </SupportedOption>
      </ul>
    </div>
    <div className="pt-2">
      <p>We currently support the following wallets:</p>
      <ul className="grid sm:grid-cols-2 gap-2 mt-3">
        <SupportedOption>
          Metamask
          <img
            className="h-6"
            alt="metamask logo"
            src={import.meta.env.BASE_URL + "externalIcons/metamask_logo.svg"}
          />
        </SupportedOption>
        <SupportedOption>
          Gnosis Safe
          <img
            className="h-6"
            alt="gnosis safe logo"
            src={
              import.meta.env.BASE_URL + "externalIcons/gnosis-safe_logo.svg"
            }
          />
        </SupportedOption>
      </ul>
    </div>
  </div>
)

const SupportedOption: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <li className=" bg-backgrounds-100 rounded-lg px-3 py-2 flex flex-row items-center justify-between align-center h-10">
      {children}
    </li>
  )
}

const networkMap = new Map(
  Object.values(Network)
    .map((n) => [n.chainData.chainId, n.chainData.chainName!] as const)
    .filter(([key]) => key !== "0x7a69"),
)

const UnsupportedNetwork: React.FC = () => {
  const switchingId = useStateObservable(switchingToNetworksId$)
  const [selectedNetwork, onNetworkChange] = useState<string>()
  const displayValue = (
    <span>
      {(selectedNetwork && networkMap.get(selectedNetwork as ChainId)) ??
        "Select a network"}
    </span>
  )

  return (
    <Fragment key="wallet-provider-fragment">
      <h4 className="text-accents-400 text-lg">Unsupported Network</h4>
      <p className="py-4 text-base">
        The Network that you are currently connected to is not supported, please
        connect to one of the supported networks:
      </p>
      <div className="flex flex-row gap-x-4 items-center">
        <DropDown
          className="bg-backgrounds-200"
          selectedId={selectedNetwork ?? ""}
          displayValue={displayValue}
          onChange={(chainId) => {
            onNetworkChange(chainId)
          }}
        >
          {[...networkMap].map(([key, value]) => (
            <DropDownOption key={key} valueId={key}>
              <span>{value}</span>
            </DropDownOption>
          ))}
        </DropDown>
        <PrimaryButton
          className="sm:w-1/2"
          id="switch-btn"
          onClick={() => {
            if (selectedNetwork !== undefined)
              onRequestSwitchNetwork(selectedNetwork as ChainId)
          }}
          disabled={switchingId !== null || selectedNetwork === undefined}
        >
          {switchingId === null ? "Switch" : "Switching..."}
        </PrimaryButton>
      </div>
    </Fragment>
  )
}

const UnhandledError: React.FC<{
  error: Error
}> = ({ error }) => {
  useEffect(() => {
    addTrace(error)
    setTimeout(stopTraces, 500)
  }, [])

  return (
    <div className="flex flex-col">
      <h4 className="text-accents-400 text-lg">
        Something unexpected happened...
      </h4>
      <p className="py-4 text-base">
        No worries!
        <br />
        Just refresh this page and you should be able to reconnect to the
        interface. If this error persists please reach out to us on Discord and
        send us the logs.
      </p>
      <PrimaryButton
        className="mt-2 sm:w-1/2"
        onClick={() => {
          location.reload()
        }}
      >
        Refresh Page
      </PrimaryButton>
      <SecondaryButton
        className="mt-2 sm:w-1/2"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigator.clipboard.writeText(getStringifiedTraces())
        }}
      >
        Copy Logs
      </SecondaryButton>
    </div>
  )
}

const ErrorFallback: React.FC<{
  error: Error
  resetErrorBoundary: () => void
}> = ({ error, resetErrorBoundary }) => {
  const isWalletError = error instanceof WalletError
  useEffect(() => {
    if (!isWalletError) return

    const sub = walletError$.pipe(skip(1)).subscribe(resetErrorBoundary)
    return () => {
      sub.unsubscribe()
    }
  }, [isWalletError])

  const getErrorJsx = () => {
    if (!isWalletError) return <UnhandledError error={error} />

    if (error.type <= WalletErrorType.overriddenProvider)
      return <NoProvider type={error.type} />

    if (error.type === WalletErrorType.disconnected)
      return <ProviderDisconnected />

    return error.type === WalletErrorType.noAccounts ? (
      <NoAccount />
    ) : (
      <UnsupportedNetwork />
    )
  }

  return (
    <div className="flex flex-col align-center items-center justify-center min-h-screen text-fontColor-0 w-screen absolute">
      <div className="max-w-[400px] sm:w-2/3 sm:max-w-[600px] m-4">
        {getErrorJsx()}
      </div>
    </div>
  )
}

const EnsureNetworkAndAccount: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useStateObservable(account$)
  const { chainId } = useStateObservable(network$).chainData
  return (
    <Fragment key={chainId}>
      <RemoveSubscribe>{children}</RemoveSubscribe>
    </Fragment>
  )
}

export const WalletProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [retryNumber, setRetryNumber] = useState(0)
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => setRetryNumber((x) => x + 1)}
    >
      <Subscribe
        key={retryNumber}
        fallback={
          <div className="flex flex-col items-center justify-center text-base text-accents-500 w-screen h-screen inset-0 absolute">
            <h4>Connecting...</h4>
          </div>
        }
      >
        <EnsureNetworkAndAccount>{children}</EnsureNetworkAndAccount>
      </Subscribe>
    </ErrorBoundary>
  )
}
