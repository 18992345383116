import { HistoryItem as RawHistoryItem } from "@/generated/graphql"
import { divideCurrency } from "@/utils/currency-utils"
import { absolute } from "@/utils/maths-utils"
import {
  exhaustMap,
  filter,
  interval,
  map,
  Observable,
  startWith,
  switchMap,
  takeWhile,
} from "rxjs"
import {
  ChainId,
  Currency,
  Instrument,
  InstrumentId,
  Network,
} from "../generated"

const suffix = new URLSearchParams(window.location.search).has("staging")
  ? "-staging"
  : ""

async function graphQLQuery<T>(
  endpoint: string,
  query: string,
  variables: any = {},
) {
  return fetch(endpoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ query, variables }),
  })
    .then((response) => response.json())
    .then((data) => data.data as T)
}

/**
 * Emits when the graph has caught up to the head block number
 */
function update$(
  endpoint: string,
  headBlockNumber$: Observable<{ block: bigint; source: string }>,
): Observable<any> {
  return headBlockNumber$.pipe(
    switchMap((headBlockNumber) =>
      interval(1e3).pipe(
        startWith(0),
        exhaustMap(() =>
          graphQLQuery<{
            meta: { block: { number: string } }
          }>(
            endpoint,
            `
              query IndexerMeta {
                meta: _meta {
                  block {
                    number
                  }
                }
              }
            `,
          ),
        ),
        map(
          ({ meta: { block } }) =>
            BigInt(block.number) >= headBlockNumber.block,
        ),
        takeWhile((isUpdated) => !isUpdated, true),
      ),
    ),
    filter(Boolean),
  )
}

export const getHistoryItems = (
  filters: Partial<{ trader: string; positionId: string }>,
  network: ChainId,
  headBlockNumber$: Observable<{ block: bigint; source: string }>,
) => {
  const fields: (keyof RawHistoryItem)[] = [
    "id",
    "type",
    "fillSize",
    "fillCost",
    "fillPrice",
    "cashflowCcy",
    "cashflowBase",
    "cashflowQuote",
    "equityBase",
    "equityQuote",
    "openQuantity",
    "openCost",
    "closedCost",
    "cashflowBaseAcc",
    "cashflowQuoteAcc",
    "equityBaseAcc",
    "equityQuoteAcc",
    "feeCcy",
    "feeBase",
    "feeQuote",
    "feeBaseAcc",
    "feeQuoteAcc",
    "realisedPnLBase",
    "realisedPnLQuote",
    "spotPrice",
    "owner",
    "blockNumber",
    "blockTimestamp",
    "transactionHash",
    "prevTransactionHash",
    "dateTime",
  ]

  const endpoint = Network[network].chainData.graphUrl + suffix

  const byOwner = filters.trader ? { owner: filters.trader.toLowerCase() } : {}
  const byId = filters.positionId
    ? { position_: { number: filters.positionId } }
    : {}
  const by = { ...byOwner, ...byId }

  const query = `query GetHistoryItems {
    historyItems(where: ${JSON.stringify(by).replace(
      /"(\w+)"\s*:/g,
      "$1:",
    )} orderBy: blockNumber, orderDirection: desc) {
      ${fields.join(" ")}
      position {
        number
        openCost
        instrument {
          symbol
        }
      }
    }
  }`

  return update$(endpoint, headBlockNumber$).pipe(
    startWith(null),
    switchMap(() =>
      graphQLQuery<{ historyItems: RawHistoryItem[] }>(
        endpoint,
        query,
        filters,
      ),
    ),
    map(({ historyItems }) =>
      historyItems.map((trade) => mapHistoryItem(trade)),
    ),
  )
}

const toBigInt = (value: string, unitName: number): bigint => {
  const a = value.split(".")

  if (a.length == 1) {
    return BigInt(a[0] + "0".repeat(unitName))
  } else if (a.length == 2) {
    return BigInt(
      a[0] +
        a[1].trimStart().substring(0, unitName) +
        "0".repeat(Math.max(0, unitName - a[1].length)),
    )
  } else {
    throw new Error("Invalid value")
  }
}

const transformHistoryItem = (item: RawHistoryItem) => {
  const instrument = Instrument[item.position.instrument.symbol as InstrumentId]
  const quotePrecision = Currency[instrument.quote].precision
  const basePrecision = Currency[instrument.base].precision
  return {
    ...item,
    fillSize: toBigInt(item.fillSize, basePrecision),
    fillCost: toBigInt(item.fillCost, quotePrecision),
    fillPrice: toBigInt(item.fillPrice, quotePrecision),
    cashflowBase: toBigInt(item.cashflowBase, basePrecision),
    cashflowBaseAcc: toBigInt(item.cashflowBaseAcc, basePrecision),
    cashflowQuote: toBigInt(item.cashflowQuote, quotePrecision),
    cashflowQuoteAcc: toBigInt(item.cashflowQuoteAcc, quotePrecision),
    equityBase: toBigInt(item.equityBase, basePrecision),
    equityQuote: toBigInt(item.equityQuote, quotePrecision),
    openQuantity: toBigInt(item.openQuantity, basePrecision),
    openCost: toBigInt(item.openCost, quotePrecision),
    closedCost: toBigInt(item.closedCost, quotePrecision),
    feeBase: toBigInt(item.feeBase, basePrecision),
    feeBaseAcc: toBigInt(item.feeBaseAcc, basePrecision),
    feeQuote: toBigInt(item.feeQuote, quotePrecision),
    feeQuoteAcc: toBigInt(item.feeQuoteAcc, quotePrecision),
    realisedPnLBase: toBigInt(item.realisedPnLBase, basePrecision),
    realisedPnLQuote: toBigInt(item.realisedPnLQuote, quotePrecision),
    spotPrice: item.spotPrice ? toBigInt(item.spotPrice, quotePrecision) : null,
    blockNumber: Number(item.blockNumber),
    blockTimestamp: Number(item.blockTimestamp), // unix timestamp
    positionId: BigInt(item.position.number),
    instrument,
  }
}

const mapHistoryItem = (item: RawHistoryItem) => {
  const t = transformHistoryItem(item)
  const previousQuantity = t.openQuantity - t.fillSize
  const previousOpenCost = t.closedCost
    ? t.openCost + t.closedCost
    : t.openCost - t.fillCost
  const previousEntryPrice = absolute(
    divideCurrency(
      { value: previousOpenCost, currency: t.instrument.quote },
      { value: previousQuantity, currency: t.instrument.base },
    ),
  )
  const entryPrice = absolute(
    divideCurrency(
      { value: t.openCost, currency: t.instrument.quote },
      { value: t.openQuantity, currency: t.instrument.base },
    ),
  )

  return {
    ...t,
    previousEntryPrice,
    entryPrice,
    previousQuantity,
    fillPrice: absolute(t.fillPrice),
    openCost: absolute(t.openCost),
    previousOpenCost: absolute(previousOpenCost),
    previousCollateral: t.cashflowQuoteAcc - t.cashflowQuote,
    previousFees: t.feeQuoteAcc - t.feeQuote,
  }
}

export type HistoryItem = ReturnType<typeof mapHistoryItem>
