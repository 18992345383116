import { Maturity } from "@/api"
import { latestBlockTimestamp$ } from "@/api/chain/common"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { Margin } from "@/components/Margin"
import { OnHoverSwitch } from "@/components/OnHoverSwitch"
import { SlippageInput } from "@/components/Slippage"
import { Tooltip } from "@/components/Tooltip"
import { classNames } from "@/utils/classNames"
import {
  calculateLiquidationPrice,
  calculateMarkPrice,
} from "@/utils/financial-utils"
import { getTimeRemaining } from "@/utils/getTimeRemaining"
import { invertIfShort } from "@/utils/inverted-pairs-utis"
import { absolute } from "@/utils/maths-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { withBlurAndHide } from "@/utils/withLoading"
import { state, useStateObservable } from "@react-rxjs/core"
import React from "react"
import { map, merge } from "rxjs"
import { Collapsable } from "../../components/Collapsable"
import { TooltipKey, tooltipMapper } from "../../components/tooltipContent"
import { posDivider } from "../Positions/Position/PositionView/Shared"
import { defaultSpan } from "./common"
import {
  onSlippageChanged,
  quantity$,
  slippage$,
  validQuantity$,
} from "./state"
import { instrument$ } from "./state/instrument"
import { costData$ } from "./state/price"
import {
  expiryDateTestId,
  feeTestId,
  liqPriceTestId,
  markPriceTestId,
  positionValueTestId,
} from "./testIds"

export const underlineStyles =
  "underline underline-offset-2 decoration-1 decoration-dotted decoration-secondary-01/50"

const positionValueJsx$ = state(
  costData$.pipe(
    mapDistinct(({ cost, quote, decimals }) => (
      <CurrencyDisplay
        value={absolute(cost)}
        currencyId={quote}
        testId={positionValueTestId}
        formatOptions={{ nDecimals: decimals }}
      />
    )),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(positionValueTestId),
)

const feeJsx$ = state(
  costData$.pipe(
    mapDistinct(({ fee, quote }) => (
      <CurrencyDisplay value={fee} currencyId={quote} testId={feeTestId} />
    )),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(feeTestId),
)

const marginJsx$ = state(
  costData$.pipe(
    mapDistinct((props) => <Margin testIdPrefix="create-ticket" {...props} />),
    withBlurAndHide(quantity$),
  ),
  defaultSpan("create-ticket--margin"),
)

const expiryTimestamp$ = instrument$.pipeState(
  mapDistinct(({ maturity }) => Maturity[maturity].timestamp),
)

const expiryDate$ = expiryTimestamp$.pipeState(
  mapDistinct((timestamp) => new Date(timestamp * 1000)),
)

const ExpiryTimeRemaining: React.FC<{ expiryDate: Date }> = ({
  expiryDate,
}) => {
  const timestamp = useStateObservable(latestBlockTimestamp$)
  const { days, hours, minutes, seconds } = getTimeRemaining(
    new Date(timestamp * 1000),
    expiryDate,
  )

  return (
    <div>{days + "d " + hours + "h " + minutes + "m " + seconds + "s"}</div>
  )
}

const expiryTimeAndDate$ = state(
  expiryDate$.pipeState(
    map((expiryDate) => {
      return (
        <OnHoverSwitch
          from={
            <div data-testid={expiryDateTestId} className={underlineStyles}>
              {expiryDate.toUTCString()}
            </div>
          }
          to={<ExpiryTimeRemaining expiryDate={expiryDate} />}
        />
      )
    }),
  ),
)

const liqPriceJsx$ = state(
  costData$.pipe(
    map(({ instrument, quantity, decimals, ...quote }) => {
      const [value, ccy] = invertIfShort(
        calculateLiquidationPrice(quote, instrument, quantity),
        instrument,
      )
      return (
        <CurrencyDisplay
          value={value}
          currencyId={ccy}
          testId={liqPriceTestId}
          formatOptions={{ nDecimals: decimals }}
        />
      )
    }),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(liqPriceTestId),
)

const markPriceJsx$ = state(
  costData$.pipe(
    map(({ underlyingCollateral, quantity, instrument, decimals }) => {
      const [value, ccy] = invertIfShort(
        calculateMarkPrice(underlyingCollateral, instrument, quantity),
        instrument,
      )
      return (
        <CurrencyDisplay
          value={value}
          currencyId={ccy}
          testId={markPriceTestId}
          formatOptions={{ nDecimals: decimals }}
        />
      )
    }),
    withBlurAndHide(quantity$),
  ),
  defaultSpan(markPriceTestId),
)

export const AdvancedDetails$ = merge(
  validQuantity$,
  marginJsx$,
  markPriceJsx$,
  liqPriceJsx$,
  expiryTimeAndDate$,
  positionValueJsx$,
  feeJsx$,
  slippage$,
)

export const AdvancedDetails: React.FC = () => {
  const isValid = useStateObservable(validQuantity$)
  const content = (
    <div className="flex flex-col px-4 py-2 rounded-lg bg-backgrounds-100">
      <TableRow>
        <TableLabel tooltipKey="margin">Margin</TableLabel>
        {marginJsx$}
      </TableRow>
      <TableRow>
        <TableLabel tooltipKey="markPrice">Mark Price</TableLabel>
        {markPriceJsx$}
      </TableRow>
      <TableRow>
        <TableLabel tooltipKey="liquidationPrice">Liquidation Price</TableLabel>
        {liqPriceJsx$}
      </TableRow>
      {posDivider}
      <TableRow>
        <TableLabel tooltipKey="expiryDate">Expiry Date</TableLabel>
        <span className="flex-auto text-right">{expiryTimeAndDate$}</span>
      </TableRow>
      {posDivider}
      <TableRow>
        <TableLabel tooltipKey="positionValue">Position Value</TableLabel>
        <div data-testid="create-ticket--cost">{positionValueJsx$}</div>
      </TableRow>
      <TableRow>
        <TableLabel tooltipKey="fee">Trading Fees</TableLabel>
        {feeJsx$}
      </TableRow>
      {posDivider}
      <TableRow className="py-5">
        <TableLabel tooltipKey="slippage">Slippage Tolerance</TableLabel>
        <div className="w-1/3">
          <SlippageInput
            slippage$={slippage$}
            onChange={onSlippageChanged}
            testIdPrefix="create-ticket"
          />
        </div>
      </TableRow>
    </div>
  )

  return isValid ? (
    <Collapsable testId="create-ticket" label="Advanced details">
      {content}
    </Collapsable>
  ) : null
}

export const TableRow: React.FC<
  { children: React.ReactNode } & React.HTMLAttributes<HTMLTableRowElement>
> = ({ children, ...trProps }) => (
  <div
    {...trProps}
    className={
      "flex items-center justify-between w-full text-sm h-6 border-primary-03 font-primary " +
      trProps.className
    }
  >
    {children}
  </div>
)

export const TableLabel: React.FC<{
  children: string
  tooltipKey: TooltipKey
  className?: string
}> = ({ children, className, tooltipKey }) => {
  return (
    <Tooltip message={tooltipMapper(tooltipKey)} className="text-fontColor-500">
      <span className={classNames("block", className)}>{children}</span>
    </Tooltip>
  )
}
