import { TabButton } from "@/components/Tabs"
import { useStateObservable } from "@react-rxjs/core"
import { usePositionContext } from "../Position.context"
import { PositionHeader } from "../PositionEdit/PositionHeader"
import { PositionDelivery } from "./PositionDelivery"
import { PositionSettlement } from "./PositionSettlement"
import {
  DeliveryType,
  onDeliverOrCancel,
  positionDeliveryFlow$,
} from "./state/base"

export const ExpiredPositionSettlement = () => {
  const { id, instrument } = usePositionContext()
  const flowType = useStateObservable(positionDeliveryFlow$(id))

  return (
    <div className="bg-backgrounds-200 rounded-lg p-4">
      <PositionHeader
        instrument={instrument}
        testIdPrefix="position-settlement"
      />
      <div className="flex gap-4 pb-3 px-4 mt-4">
        <TabButton
          testId="expired-position--deliver"
          isActive={flowType === DeliveryType.deliver}
          className="text-sm pb-1 w-auto"
          onClick={() => {
            onDeliverOrCancel(id, DeliveryType.deliver)
          }}
        >
          Deliver
        </TabButton>
        <TabButton
          testId="expired-position--cash-settle"
          isActive={flowType === DeliveryType.settlement}
          className="text-sm pb-1 w-auto"
          onClick={() => {
            onDeliverOrCancel(id, DeliveryType.settlement)
          }}
        >
          Cash Settle
        </TabButton>
      </div>
      <div className="flex flex-col gap-3 text-base">
        {flowType === DeliveryType.settlement ? <PositionSettlement /> : null}
        {flowType === DeliveryType.deliver ? <PositionDelivery /> : null}
      </div>
    </div>
  )
}
