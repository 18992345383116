import { Currency, Side } from "@/api"
import { latestBlockTimestamp$ } from "@/api/chain/common"
import {
  PositionCell,
  PositionRow,
  TableHeader,
} from "@/App/Positions/Position/PositionList"
import {
  NoChange,
  PositionSide,
} from "@/App/Positions/Position/PositionView/Shared"
import { Countdown } from "@/components/Countdown"
import {
  CurrencyDisplay,
  formatDelta,
  renderNumberWithInversion,
} from "@/components/CurrencyDisplay"
import { Label } from "@/components/Label"
import { PnLDelta } from "@/components/PnL"
import { Tooltip } from "@/components/Tooltip"
import { HistoryItemType } from "@/generated/graphql"
import { withPrefix } from "@/utils/test-utils"
import { useStateObservable } from "@react-rxjs/core"
import React from "react"
import { transactionHistory$ } from "../../state"
import { TransactionMenu } from "../action-menu/TransactionMenu"

const testIdPrefix = "transaction-history-item"

function typeToLabel(type: HistoryItemType) {
  switch (type) {
    case HistoryItemType.Open:
      return "Opened"
    case HistoryItemType.Close:
      return "Closed"
    case HistoryItemType.Liquidation:
      return "Liquidated"
    case HistoryItemType.Modification:
      return "Modified"
    case HistoryItemType.Delivery:
      return "Delivered"
  }
}

export const TransactionHistoryHeaders: React.FC<{}> = () => {
  return (
    <>
      <TableHeader className="md:sticky left-[-1px] text-left">ID</TableHeader>
      <TableHeader className="text-left">Instrument</TableHeader>
      <TableHeader className="text-left">Date</TableHeader>
      <TableHeader className="text-center">Type</TableHeader>
      <TableHeader>Quantity</TableHeader>
      <TableHeader>Collateral</TableHeader>
      <TableHeader>Realised PnL</TableHeader>
      <TableHeader>Execution price</TableHeader>
      <TableHeader>Trading Fee</TableHeader>
      <TableHeader className="sticky right-[-1px] text-center">
        <span className="sr-only 2xl:not-sr-only">Actions</span>
      </TableHeader>
    </>
  )
}

export const TransactionHistoryTable: React.FC = () => {
  const historyItems = useStateObservable(transactionHistory$)

  if (!historyItems) {
    return <LoadErrorMessage />
  }
  return (
    <>
      {historyItems.length === 0 ? (
        <NoHistoryItemsMessage />
      ) : (
        historyItems.map((item) => {
          const { base, quote, side, maturity } = item.instrument
          return (
            <PositionRow key={item.id.toString()} testId={item.id.toString()}>
              <PositionCell className="md:sticky left-[-1px] text-left">
                <span data-testid={withPrefix("id", testIdPrefix)}>
                  {"#" + item.positionId}
                </span>
              </PositionCell>
              <PositionCell
                className="text-left"
                testId={withPrefix("instrument", testIdPrefix)}
              >
                <div>
                  {side === Side.Long
                    ? `${base}/${quote} ${maturity}`
                    : `${quote}/${base} ${maturity}`}
                </div>
                <PositionSide side={side} />
              </PositionCell>
              <PositionCell
                className="text-left"
                testId={withPrefix("time", testIdPrefix)}
              >
                <TransactionTime
                  transactionTimestamp={Number(item.blockTimestamp)}
                />
              </PositionCell>
              <PositionCell className="text-center">
                <Label
                  testId={withPrefix("action", testIdPrefix)}
                  label={typeToLabel(item.type)}
                />
              </PositionCell>
              <PositionCell>
                {formatDelta(
                  item.fillSize,
                  base,
                  withPrefix("quantity", testIdPrefix),
                  {
                    nDecimals: Currency[item.instrument.base].displayDecimals,
                    padToDecimals: false,
                  },
                  "-",
                )}
              </PositionCell>
              <PositionCell>
                {formatDelta(
                  item.type === HistoryItemType.Delivery
                    ? 0n
                    : item.cashflowQuote,
                  quote,
                  withPrefix("collateral", testIdPrefix),
                  {},
                  "-",
                )}
              </PositionCell>
              <PositionCell>
                <PnLDelta
                  pnl={item.realisedPnLQuote}
                  currency={quote}
                  testId={withPrefix("pnl", testIdPrefix)}
                />
              </PositionCell>
              <PositionCell testId={withPrefix("executionPrice", testIdPrefix)}>
                {item.fillCost === 0n ||
                item.type === HistoryItemType.Delivery ? (
                  <NoChange />
                ) : (
                  renderNumberWithInversion(item.fillPrice, item.instrument)
                )}
              </PositionCell>
              <PositionCell testId={withPrefix("fee", testIdPrefix)}>
                <CurrencyDisplay value={item.feeQuote} currencyId={quote} />
              </PositionCell>
              <PositionCell
                className="sticky right-[-1px] text-right"
                testId={withPrefix("menu", testIdPrefix)}
              >
                <TransactionMenu item={item} />
              </PositionCell>
            </PositionRow>
          )
        })
      )}
    </>
  )
}

const TransactionTime: React.FC<{ transactionTimestamp: number }> = ({
  transactionTimestamp,
}) => {
  const now = useStateObservable(latestBlockTimestamp$)

  return (
    <Tooltip message={new Date(transactionTimestamp * 1000).toLocaleString()}>
      <Countdown startTimestamp={transactionTimestamp} endTimestamp={now} />
    </Tooltip>
  )
}

const NoHistoryItemsMessage: React.FC = () => {
  return (
    <PositionRow>
      <td
        data-testid="no-trades-message"
        className="absolute whitespace-nowrap pl-4 py-3 font-primary text-left"
      >
        No transaction history available
      </td>
    </PositionRow>
  )
}

const LoadErrorMessage: React.FC = () => {
  return (
    <PositionRow>
      <td
        data-testid="trade-history-load-error"
        className="absolute whitespace-nowrap pl-4 py-3 font-primary text-left"
      >
        Failed to load transaction history. Please try again later.
      </td>
    </PositionRow>
  )
}
