import { HistoryItem } from "@/api/graphql/positions"
import {
  ResultingPositionRow,
  SummaryRow,
  SummaryTable,
} from "@/App/Positions/Position/PositionEdit/Summary/SummaryTable"
import { CurrencyDisplay, formatDelta } from "@/components/CurrencyDisplay"
import { PnLDelta } from "@/components/PnL"
import { TabButton, Tabs } from "@/components/Tabs"
import { divideCurrency } from "@/utils/currency-utils"
import { invertIfShort } from "@/utils/inverted-pairs-utis"
import { formatDate } from "@/utils/mappers"
import { absolute } from "@/utils/maths-utils"
import { useState } from "react"

export const LiquidatedPositionSummary: React.FC<HistoryItem> = (item) => {
  const { openQuantity, fillSize, instrument } = item
  const wasFullyLiquidated = openQuantity === 0n

  const [selectedTab, setSelectedTab] = useState<
    "Resulting" | "TransactionDetails"
  >("Resulting")

  if (wasFullyLiquidated)
    return (
      <>
        <LiquidationTransactionSummary {...item} />
        <div>Your position was fully liquidated</div>
      </>
    )

  return (
    <>
      <Tabs>
        <TabButton
          isActive={selectedTab === "Resulting"}
          onClick={() => setSelectedTab("Resulting")}
        >
          Resulting Position
        </TabButton>
        <TabButton
          isActive={selectedTab === "TransactionDetails"}
          onClick={() => setSelectedTab("TransactionDetails")}
        >
          Transaction Details
        </TabButton>
      </Tabs>
      {selectedTab === "TransactionDetails" && (
        <LiquidationTransactionSummary {...item} />
      )}
      {selectedTab === "Resulting" && <LiquidationResultingSummary {...item} />}
      <div className="text-ml flex flex-row gap-2 justify-center">
        You were liquidated
        <CurrencyDisplay value={fillSize} currencyId={instrument.base} />
      </div>
    </>
  )
}

const LiquidationTransactionSummary: React.FC<HistoryItem> = ({
  fillSize,
  fillCost,
  realisedPnLQuote,
  blockTimestamp,
  instrument: { base, quote, side },
}) => {
  const [exitPrice, exitCurrency] = invertIfShort(
    divideCurrency(
      { value: fillCost, currency: quote },
      { value: fillSize, currency: base },
    ),
    { base, quote, side },
  )
  return (
    <SummaryTable>
      <SummaryRow title="Date and time">
        {formatDate(blockTimestamp)}
      </SummaryRow>
      <SummaryRow title="Quantity decrease">
        {formatDelta(
          fillSize,
          base,
          "transaction-details-liquidation--quantity",
        )}
      </SummaryRow>
      <SummaryRow title="Exit Price">
        <CurrencyDisplay
          value={absolute(exitPrice)}
          currencyId={exitCurrency}
        />
      </SummaryRow>
      <SummaryRow title="Realised PnL">
        <PnLDelta
          pnl={realisedPnLQuote}
          currency={quote}
          testId="transaction-details-liquidation--realised-pnl"
        />
      </SummaryRow>
    </SummaryTable>
  )
}

const LiquidationResultingSummary: React.FC<HistoryItem> = ({
  openQuantity,
  openCost,
  previousEntryPrice,
  previousQuantity,
  previousOpenCost,
  instrument: { base, quote },
}) => {
  return (
    <SummaryTable>
      <ResultingPositionRow
        title="Quantity"
        from={<CurrencyDisplay value={previousQuantity} currencyId={base} />}
        to={<CurrencyDisplay value={openQuantity} currencyId={base} />}
        resultingTestId="liquidation-resulting-summary--quantity"
      />
      <ResultingPositionRow
        title="Entry Price"
        from={<CurrencyDisplay value={previousEntryPrice} currencyId={quote} />}
        to={
          <CurrencyDisplay
            value={divideCurrency(
              { value: openCost, currency: quote },
              { value: openQuantity, currency: base },
            )}
            currencyId={quote}
          />
        }
        resultingTestId="liquidation-resulting-summary--price"
      />
      <ResultingPositionRow
        title="Position Value"
        from={<CurrencyDisplay value={previousOpenCost} currencyId={quote} />}
        to={<CurrencyDisplay value={openCost} currencyId={quote} />}
        resultingTestId="liquidation-resulting-summary--value"
      />
    </SummaryTable>
  )
}
