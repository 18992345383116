import { mapResponses } from "@/utils/mappers"
import { sinkSuspense, state } from "@react-rxjs/core"
import { catchError, combineLatest, map, of } from "rxjs"
import { getOpeningCostForPositionWithLeverage$ } from "../chain"
import { getValidFees } from "../fees"
import { InstrumentId } from "../types"
import { collateralSlippage } from "../utils"

export const getOpeningCostByLeverage$ = state(
  (instrumentId: InstrumentId, quantity: bigint, leverage: bigint) => {
    const uniswapFees = getValidFees()
    return combineLatest(
      uniswapFees.map((uniswapFee) => {
        return getOpeningCostForPositionWithLeverage$(
          {
            quantity: quantity,
            symbol: instrumentId,
            collateralSlippage,
            uniswapFee,
          },
          leverage,
        ).pipe(
          map((res) => {
            if (res.ok) return { ...res.result, uniswapFee }
            return new OpeningCostError(
              OpeningCostErrorType.InsufficientLiquidity,
            )
          }),
          catchError(() =>
            of(new OpeningCostError(OpeningCostErrorType.Unknown)),
          ),
          sinkSuspense(),
        )
      }),
    ).pipe(mapResponses("getOpeningCostForPositionWithLeverage$"))
  },
)

export enum OpeningCostErrorType {
  InsufficientLiquidity = "InsufficientLiquidity",
  Unknown = "Unknown",
}

export class OpeningCostError extends Error {
  type: OpeningCostErrorType
  constructor(type: OpeningCostErrorType) {
    super()
    this.type = type
  }
}
