import { Queue } from "./Queue"

export const maxConcurrent = <A extends Array<any>, T>(
  fn: (...args: A) => Promise<T>,
  nMax: number,
): ((...args: A) => Promise<T>) => {
  let nRunning = 0
  const waitingQueue = new Queue<() => void>()

  const doneRunning = () => {
    nRunning--
    waitingQueue.pop()?.()
  }

  return async (...args: A) => {
    if (nRunning < nMax) {
      nRunning++
      return fn(...args).finally(doneRunning)
    }

    return new Promise<T>((res, rej) => {
      waitingQueue.push(() => {
        nRunning++
        fn(...args)
          .then(res, rej)
          .finally(doneRunning)
      })
    })
  }
}
