import { getModifyCostByLeverage$, getPosition$ } from "@/api"
import { calculateMinQuantity } from "@/utils/financial-utils"
import { baseToMinQty } from "@/utils/mappers"
import { combineLatest$, mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { combineLatest, map, switchMap, take } from "rxjs"
import { formChangeEvents$, onEditFormError } from "./errors"
import { hasLeverageDelta$ } from "./leverage"
import { quantityDelta$, quantityState$, QuantityValidity } from "./quantity"

export const benchmarkMinQuantityCost$ = state((positionId: bigint) =>
  getPosition$(positionId).pipe(
    switchMap(({ quote, base }) => {
      const minQuantity = baseToMinQty(base)
      return getModifyCostByLeverage$(positionId, minQuantity, 11)
        .pipe(
          take(1),
          mapDistinct((cost) => cost),
        )
        .pipe(
          map((cost) => ({
            ...cost,
            quantity: minQuantity,
            base: base,
            quote: quote,
          })),
        )
    }),
    onEditFormError(
      positionId,
      "benchmarkMinQtyCost",
    )(formChangeEvents$(positionId)),
  ),
)

export const minQuantity$ = state(
  (positionId: bigint) =>
    benchmarkMinQuantityCost$(positionId).pipe(
      map(({ minDebt, cost, quantity, base, quote }) =>
        calculateMinQuantity(minDebt, cost, quantity, base, quote),
      ),
    ),
  0n,
)

export const isMinQuantityValidationMet$ = state(
  (id: bigint) =>
    combineLatest([minQuantity$(id), quantityState$(id)]).pipe(
      map(([minQuantity, state]) => {
        if (state.state === QuantityValidity.ZERO) return true
        if (state.state === QuantityValidity.NIL) return false
        return state.value >= minQuantity
      }),
    ),
  true,
)

export const areInputsValidAndHasDeltas$ = state((positionId: bigint) => {
  const isQuantityValid$ = quantityState$(positionId).pipe(
    mapDistinct((q) => q.state === QuantityValidity.VALID),
  )

  const qDelta$ = quantityDelta$(positionId)
  const hasLDelta$ = hasLeverageDelta$(positionId)

  const hasDeltas$ = combineLatest$({
    qDelta$,
    hasLDelta$,
  }).pipe(mapDistinct(({ qDelta, hasLDelta }) => qDelta !== 0n || hasLDelta))

  return isQuantityValid$.pipe(
    switchMap((isValid) => (isValid ? hasDeltas$ : [false])),
  )
}, false)
