import { Currency, CurrencyId } from "@/api"
import { HistoryItem } from "@/api/graphql/positions"
import {
  ResultingPositionRow,
  SummaryRow,
  SummaryTable,
} from "@/App/Positions/Position/PositionEdit/Summary/SummaryTable"
import {
  CurrencyDisplay,
  formatDelta,
  renderNumber,
  renderNumberWithInversion,
} from "@/components/CurrencyDisplay"
import { TabButton, Tabs } from "@/components/Tabs"
import { formatKnownCurrency } from "@/utils/currency-utils"
import { formatDate } from "@/utils/mappers"
import { absolute } from "@/utils/maths-utils"
import { useState } from "react"

enum TabType {
  RESULTING_POSITION = "Resulting Position",
  TRANSACTION_DETAILS = "Transaction Details",
}

export const EditTransactionSummary: React.FC<HistoryItem> = (item) => {
  const [currentTab, setCurrentTab] = useState<TabType>(
    TabType.RESULTING_POSITION,
  )

  return (
    <div>
      <Tabs>
        <TabButton
          isActive={currentTab === TabType.RESULTING_POSITION}
          onClick={() => setCurrentTab(TabType.RESULTING_POSITION)}
          testId="edit-transaction-view--resulting-position-button"
        >
          Resulting Position
        </TabButton>
        <TabButton
          isActive={currentTab === TabType.TRANSACTION_DETAILS}
          onClick={() => setCurrentTab(TabType.TRANSACTION_DETAILS)}
          testId="edit-transaction-view--transaction-details-button"
        >
          Transaction Details
        </TabButton>
      </Tabs>
      {currentTab === TabType.RESULTING_POSITION ? (
        <ResultingPosition {...item} />
      ) : (
        <TransactionSummary {...item} />
      )}
    </div>
  )
}

const TransactionSummary: React.FC<HistoryItem> = ({
  fillSize,
  cashflowQuote,
  blockTimestamp,
  instrument: { base, quote, decimals },
}) => {
  return (
    <SummaryTable>
      <SummaryRow title="Date and time">
        {formatDate(blockTimestamp)}
      </SummaryRow>
      <SummaryRow title="Quantity">
        {formatDelta(
          fillSize,
          base,
          "edit-transaction-summary--quantity",
          { nDecimals: decimals, padToDecimals: false },
          "No change",
        )}
      </SummaryRow>
      <SummaryRow title="Collateral">
        {formatDelta(
          cashflowQuote,
          quote,
          "edit-transaction-summary--collateral",
          {},
          "No change",
        )}
      </SummaryRow>
    </SummaryTable>
  )
}

const ResultingPosition: React.FC<HistoryItem> = ({
  openCost,
  openQuantity,
  previousOpenCost,
  fillSize,
  cashflowQuoteAcc,
  cashflowQuote,
  previousEntryPrice,
  entryPrice,
  previousQuantity,
  previousCollateral,
  instrument,
}) => {
  const { base, quote, decimals } = instrument
  const formatOptions = {
    nDecimals: Currency[base].displayDecimals,
    padToDecimals: false,
  }
  return (
    <>
      <SummaryTable>
        <ResultingPositionRow
          title="Quantity"
          from={
            <CurrencyDisplay
              formatOptions={formatOptions}
              value={previousQuantity}
              currencyId={base}
            />
          }
          to={
            <CurrencyDisplay
              formatOptions={formatOptions}
              value={openQuantity}
              currencyId={base}
            />
          }
          resultingTestId="quantity"
        />
        <ResultingPositionRow
          title="Collateral"
          from={formatKnownCurrency(previousCollateral, quote, {
            nDecimals: decimals,
          })}
          to={<CurrencyDisplay value={cashflowQuoteAcc} currencyId={quote} />}
          resultingTestId="collateral"
        />
        <ResultingPositionRow
          title="Entry price"
          from={renderNumber(previousEntryPrice, instrument)}
          to={renderNumberWithInversion(entryPrice, instrument)}
          resultingTestId="entryPrice"
        />
        <ResultingPositionRow
          title="Value"
          from={formatKnownCurrency(previousOpenCost, quote, {
            nDecimals: decimals,
          })}
          to={<CurrencyDisplay value={openCost} currencyId={quote} />}
          resultingTestId="openCost"
        />
      </SummaryTable>
      <div className="mt-2 flex flex-col gap-1">
        <QuantityDelta quantity={fillSize} base={base} />
        <CollateralDelta collateral={cashflowQuote} quote={quote} />
      </div>
    </>
  )
}

const QuantityDelta: React.FC<{
  quantity: bigint
  base: CurrencyId
}> = ({ quantity, base }) => {
  if (!quantity) return null

  return (
    <div className="text-ml flex flex-row gap-2 justify-center">
      {quantity > 0 ? " You increased quantity" : "You decreased quantity"}
      <CurrencyDisplay
        value={absolute(quantity)}
        currencyId={base}
        testId="transaction-summary--quantity-delta"
      />
    </div>
  )
}

const CollateralDelta: React.FC<{
  collateral: bigint
  quote: CurrencyId
}> = ({ collateral, quote }) => {
  if (!collateral) return null

  return (
    <div className="text-ml flex flex-row gap-2 justify-center">
      {collateral > 0 ? "You deposited" : "You withdrew"}
      <CurrencyDisplay
        value={absolute(collateral)}
        currencyId={quote}
        testId="transaction-summary--collateral-delta"
      />
    </div>
  )
}
