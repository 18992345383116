import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createKeyedSignal } from "@react-rxjs/utils"
import { pipe } from "rxjs"

export const DeliveryType = {
  none: "n",
  deliver: "d",
  settlement: "s",
}
export type DeliveryType = typeof DeliveryType[keyof typeof DeliveryType]

const [deliverOrCancel$, onDeliverOrCancel] = createKeyedSignal(
  ({ positionId }) => positionId,
  (positionId: bigint, value: DeliveryType) => ({
    positionId,
    value,
  }),
)

export { onDeliverOrCancel }
export const positionDeliveryFlow$ = state(
  pipe(
    deliverOrCancel$,
    mapDistinct((x) => x.value),
  ),
  DeliveryType.none,
)
