import { noActiveInstruments$ } from "@/api"
import { BlurryOverlay } from "@/components/BlurryOverlay"
import { loading } from "@/components/Loading"
import { noActiveInstruments } from "@/components/Shared"
import { classNames } from "@/utils/classNames"
import { unsuspended } from "@/utils/observable-utils"
import { useStateObservable } from "@react-rxjs/core"
import { PropsWithChildren, Suspense } from "react"
import { merge } from "rxjs"
import { AdvancedDetails, AdvancedDetails$ } from "./AdvancedDetails"
import { DefineInstrument, DefineInstrument$ } from "./DefineInstrument"
import { Equity$, EquityModule } from "./Equity"
import { PriceQuote, PriceQuote$ } from "./PriceQuote"
import { Quantity, Quantity$ } from "./Quantity"
import { isSubmitting$ } from "./state/submitting"
import { Submit, Submit$ } from "./Submit"
import { submittingOverlayTestId } from "./testIds"
import { YouPay, YouPay$ } from "./YouPay"

export const CreateTicket$ = unsuspended(
  merge(
    DefineInstrument$,
    Quantity$,
    Equity$,
    AdvancedDetails$,
    PriceQuote$,
    YouPay$,
    Submit$,
    noActiveInstruments$,
  ),
)

const IsSubmittingOverlay: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const isSubmitting = useStateObservable(isSubmitting$)
  return (
    <BlurryOverlay testId={submittingOverlayTestId} isVisible={isSubmitting}>
      <form>
        <fieldset disabled={isSubmitting}>{children}</fieldset>
      </form>
    </BlurryOverlay>
  )
}

const NoActiveInstruments: React.FC<PropsWithChildren> = ({ children }) => {
  const noActive = useStateObservable(noActiveInstruments$)
  if (noActive) return noActiveInstruments()
  return <>{children}</>
}

export function CreateTicket() {
  return (
    <div
      className={classNames(
        "xl:flex text-secondary-01 sm:min-w-[22rem] flex-col mx-auto gap-4 w-full max-w-sm md:pb-12 sm:pb-0",
      )}
    >
      <NoActiveInstruments>
        <Suspense fallback={loading}>
          <IsSubmittingOverlay>
            <div className="flex flex-col gap-3 bg-backgrounds-200 font-primary rounded-lg p-4">
              <DefineInstrument />
              <Quantity />
              <EquityModule />
              <PriceQuote />
              <AdvancedDetails />
              <YouPay />
              <Submit />
            </div>
          </IsSubmittingOverlay>
        </Suspense>
      </NoActiveInstruments>
    </div>
  )
}
