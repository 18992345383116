import {
  Currency,
  expiredPositionData$,
  getDeliveryCostForPosition$,
  getEquityPnLData$,
  getPosition$,
  getPositionClosingPrice$,
  getPositionProp$,
  getPositionStatus$,
  Instrument,
  Maturity,
  positionErrors$,
  PositionExpiryStatus,
  PositionsErrorOrigin,
  Side,
} from "@/api"
import { latestBlockTimestamp$ } from "@/api/chain/common"
import { CurrencyDisplay, renderNumber } from "@/components/CurrencyDisplay"
import { Leverage } from "@/components/Leverage"
import { IconType, NotificationIcon } from "@/components/NotificationIcon"
import { PnL } from "@/components/PnL"
import { Tooltip } from "@/components/Tooltip"
import { classNames } from "@/utils/classNames"
import { divideCurrency } from "@/utils/currency-utils"
import {
  calculateLiquidationPrice,
  calculateMarkPrice,
} from "@/utils/financial-utils"
import { formatDate } from "@/utils/mappers"
import { absolute } from "@/utils/maths-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { state, useStateObservable } from "@react-rxjs/core"
import React from "react"
import { filter, map, merge, Observable, withLatestFrom } from "rxjs"

export const posDivider = (
  <div className="w-full px-3 h-[2px] my-2 bg-backgrounds-300" />
)

export const shortRow = (element: JSX.Element, hidden: boolean) => (
  <span
    className={classNames(hidden ? "hidden" : "", "text-xs flex justify-end")}
  >
    {element}
  </span>
)

const handlePositionErrors =
  (id: bigint, origin?: PositionsErrorOrigin, fallback?: React.ReactNode) =>
  <T extends any>(source$: Observable<T>) =>
    merge(
      source$,
      positionErrors$(id).pipe(
        filter((x) => {
          return (
            x.positionId == id &&
            x.error &&
            (x.origin === "getPosition" || x.origin === origin)
          )
        }),
        map(
          ({ error }) =>
            fallback || (
              <div className="flex justify-end">
                <Tooltip
                  message={`unable to fetch relevant data due to unexpected error: ${error.name}`}
                >
                  <NotificationIcon iconType={IconType.Error} />
                </Tooltip>
              </div>
            ),
        ),
      ),
    )

// ---------- Active Positions Only --------- //

export const equityAndPnl$ = state((id: bigint) =>
  getEquityPnLData$(id).pipe(
    withLatestFrom(getPosition$(id)),
    map(([props, { quote }]) => (
      <div className="flex flex-col items-end">
        <CurrencyDisplay
          testId="position--equity"
          value={props.equity}
          currencyId={quote}
        />
        <PnL testId="position--pnl" {...props} currency={quote} />
      </div>
    )),
    handlePositionErrors(id, "getModifyCost"),
  ),
)

export const closingPriceJsx$ = state((id: bigint) =>
  getPositionClosingPrice$(id).pipeState(
    map(({ closingPrice, ...instrument }) => (
      <span data-testid="position--exitPrice">
        {renderNumber(closingPrice, instrument)}
      </span>
    )),
  ),
)

// ---------- Expired Positions Only --------- //

export const deliveryCost$ = state((positionId: bigint) =>
  getDeliveryCostForPosition$(positionId).pipe(
    withLatestFrom(getPositionProp$(positionId, "quote")),
    map(([{ deliveryCost }, quote]) => (
      <CurrencyDisplay
        testId="position-delivery--cost"
        value={deliveryCost}
        currencyId={quote}
      />
    )),
    handlePositionErrors(positionId, "getDeliveryCost"),
  ),
)

export const expiredEquityAndPnl$ = state((id: bigint) =>
  expiredPositionData$(id).pipe(
    withLatestFrom(getPosition$(id)),
    map(([props, { quote }]) => (
      <div className="flex flex-col items-end">
        <CurrencyDisplay
          testId="position--equity"
          value={props.equity}
          currencyId={quote}
        />
        <PnL testId="position--pnl" {...props} currency={quote} />
      </div>
    )),
    handlePositionErrors(id, "settlementQuery"),
  ),
)

export const expiredClosingPrice$ = state((id: bigint) =>
  expiredPositionData$(id).pipe(
    withLatestFrom(getPosition$(id)),
    mapDistinct(([{ closingPrice }, instrument]) =>
      renderNumber(closingPrice, instrument),
    ),
    handlePositionErrors(id, "settlementQuery"),
  ),
)

// ---------- Common --------- //

export const quantityJsx$ = state((positionId: bigint) =>
  getPosition$(positionId).pipe(
    withLatestFrom(getPosition$(positionId)),
    map(([{ quantity }, { base }]) => (
      <CurrencyDisplay
        value={quantity}
        currencyId={base}
        testId="position--quantity"
        formatOptions={{
          nDecimals: Currency[base].displayDecimals,
          padToDecimals: false,
        }}
      />
    )),
    handlePositionErrors(positionId),
  ),
)

export const entryPrice$ = state((id: bigint) =>
  getPosition$(id).pipe(
    mapDistinct(({ openCost, quantity, base, quote, side, decimals }) => {
      const price =
        quantity &&
        divideCurrency(
          { value: absolute(openCost), currency: quote },
          { value: quantity, currency: base },
        )
      return { price, base, quote, side, decimals }
    }),
  ),
)

export const entryPriceJsx$ = state((id: bigint) =>
  entryPrice$(id).pipe(
    map(({ price, ...props }) => renderNumber(price, props)),
    handlePositionErrors(id),
  ),
)

export const leverageJsx$ = state((id: bigint) =>
  getPositionStatus$(id).pipe(
    map((props) => (
      <Leverage {...props} showSuffix={false} testIdPrefix={"position"} />
    )),
    handlePositionErrors(id, "positionStatus"),
  ),
)

export const marginJsx$ = state((id: bigint) =>
  getPositionStatus$(id).pipe(
    map(({ margin, minMargin }) => (
      <div className="">
        <div data-testid="position--margin">{`${margin}%`}</div>
        <div
          data-testid="position--minMargin"
          className="text-sm whitespace-nowrap text-fontColor-500"
        >
          {`(liq: ${minMargin}%)`}
        </div>
      </div>
    )),
    handlePositionErrors(id, "positionStatus"),
  ),
)

export const markPrice$ = state((id: bigint) =>
  getPositionStatus$(id).pipe(
    withLatestFrom(getPosition$(id)),
    map(([{ underlyingCollateral }, { quantity, ...instrument }]) => {
      const markPrice = calculateMarkPrice(
        underlyingCollateral,
        instrument,
        quantity,
      )
      return renderNumber(markPrice, instrument)
    }),
    handlePositionErrors(id, "positionStatus"),
  ),
)

export const liquidationPrice$ = state((id: bigint) =>
  getPositionStatus$(id).pipe(
    withLatestFrom(getPosition$(id)),
    map(([positionStatus, { quantity, ...instrument }]) => {
      const liqPrice = calculateLiquidationPrice(
        positionStatus,
        instrument,
        quantity,
      )
      return renderNumber(liqPrice, instrument)
    }),
    handlePositionErrors(id, "positionStatus"),
  ),
)

export const fees$ = state((id: bigint) =>
  getPosition$(id).pipe(
    map(({ fees, instrumentId }) => (
      <CurrencyDisplay
        value={fees}
        currencyId={Instrument[instrumentId].quote}
        testId="position--fees"
      />
    )),
    handlePositionErrors(id),
  ),
)

export const CurrencyPair: React.FC<{ id: bigint }> = ({ id }) => {
  const { base, quote, side } = useStateObservable(getPosition$(id))
  const [lhs, rhs] = side === "Long" ? [base, quote] : [quote, base]

  return (
    <div>
      <span
        data-testid="position--title"
        className="mr-1"
      >{`${lhs}/${rhs}`}</span>
      <Expiry id={id} />
    </div>
  )
}

export const Expiry: React.FC<{ id: bigint }> = ({ id }) => {
  const { maturity } = useStateObservable(getPosition$(id))
  const now = useStateObservable(latestBlockTimestamp$)
  const expiryDate = Maturity[maturity].timestamp
  const [content, fontColor] =
    now < expiryDate
      ? [maturity, "text-inherit"]
      : ["Expired", "text-highlight-01"]

  return (
    <Tooltip
      testId="position--expiry"
      className={fontColor}
      message={formatDate(expiryDate)}
    >
      {content}
    </Tooltip>
  )
}

export const PositionSide: React.FC<{
  side: Side
  testId?: string
}> = ({ side, testId }) => {
  return (
    <div
      data-testid={testId}
      className={classNames(
        side === "Long"
          ? "text-functional-buy-500"
          : "text-functional-sell-500",
      )}
    >
      {side}
    </div>
  )
}

export const Equity: React.FC<{ id: bigint; status: PositionExpiryStatus }> = ({
  id,
  status,
}) => {
  if (status === PositionExpiryStatus.Expired) return expiredEquityAndPnl$(id)
  else return equityAndPnl$(id)
}

export const NoChange: React.FC<{ testId?: string }> = ({ testId }) => {
  return (
    <div className="text-fontColor-600" data-testid={testId}>
      -
    </div>
  )
}
