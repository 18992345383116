import { bytes32, enhanceCodec } from "solidity-codecs"

const textEncoder = new TextEncoder()
const textDecoder = new TextDecoder()
export const bytes32strMapper = (input: Uint8Array) => {
  let len = -1
  while (input[++len] > 0);
  return textDecoder.decode(input.slice(0, len))
}
export const bytes32str = enhanceCodec<Uint8Array, string>(
  bytes32,
  textEncoder.encode.bind(textEncoder),
  bytes32strMapper,
)
