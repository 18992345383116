import { MAX_256 } from "@/utils/constants"
import { state } from "@react-rxjs/core"
import { of } from "rxjs"
import { getAllowance, getBalance } from "./chain"
import { client } from "./chain/common"
import { Quote } from "./generated"
import { withTicks } from "./utils"
import { ensureAccountAndNetwork } from "./wallet"

const erc20Balance$ = state(withTicks(getBalance))
const erc20Allowance$ = state(withTicks(getAllowance))

const getEthBalance = (
  account: string,
  block: number | "latest" | "earliest" | "pending" = "latest",
) =>
  client
    .request<string>("eth_getBalance", [
      account,
      typeof block === "number" ? "0x" + block.toString(16) : block,
    ])
    .then(BigInt)
const ethBalance$ = withTicks(getEthBalance)

const currentBalance$ = (contract: Quote, account: string) =>
  contract === "ETH" ? ethBalance$(account) : erc20Balance$(contract, account)

export const balance$ = state((quote: Quote) =>
  ensureAccountAndNetwork((account) => currentBalance$(quote, account)),
)

export const allowance$ = state((quote: Quote) =>
  ensureAccountAndNetwork((account, network) =>
    quote === "ETH"
      ? of(MAX_256)
      : erc20Allowance$(quote, account, network.addresses.contango),
  ),
)
