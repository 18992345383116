import { absolute } from "@/utils/maths-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createKeyedSignal } from "@react-rxjs/utils"
import { pipe } from "rxjs"

// ---------------- Slippage ---------------- //

const [slippageChanged$, onSlippageChanged] = createKeyedSignal(
  ({ positionId }) => positionId,
  (positionId: bigint, e: React.ChangeEvent<HTMLInputElement>) => ({
    positionId,
    value: Number(e.target.value),
  }),
)

const slippage$ = state(
  pipe(
    slippageChanged$,
    mapDistinct((e) => e.value),
  ),
  0.15,
)

export { slippage$, onSlippageChanged }

// ---------------- Equity ---------------- //

export const [userInputEquity$, onUserInputEquity] = createKeyedSignal(
  ({ positionId }) => positionId,
  (positionId: bigint, value: bigint) => ({
    positionId,
    value,
  }),
)

// ---------------- Leverage ---------------- //

export const [leverageInputChanged$, onLeverageInputChanged] =
  createKeyedSignal(
    ({ positionId }) => positionId,
    (positionId: bigint, value: number) => ({
      positionId,
      value,
    }),
  )

export const leverageInput$ = state((id: bigint) =>
  leverageInputChanged$(id).pipe(mapDistinct(({ value }) => value)),
)

// ---------------- Quantity ---------------- //

export const [quantityChanged$, onQuantityChanged] = createKeyedSignal(
  ({ positionId }) => positionId,
  (positionId: bigint, value: bigint | null) => ({
    positionId,
    value: value && absolute(value),
  }),
)
