import { solidityError } from "@unstoppablejs/solidity-bindings";
import { address,uint,int,uint24,Struct,bytes32,bytes6,uint32 } from "solidity-codecs";

const a = {};
const b = {token: address};
const c = {sender: address};
const d = {openCost: uint, minCost: uint};
const e = {n: int};
const f = {n: uint};
const g = {limitCost: uint, actualCost: uint};
const h = {hedgeSize: uint, swapAmount: uint};
const i = {amount0Delta: int, amount1Delta: int};
const j = {caller: address};
const k = Struct({token0:address,token1:address,fee:uint24});
const l = {poolKey: k};
const m = {symbol: bytes32};
const n = {symbol: bytes32, baseId: bytes6};
const o = {symbol: bytes32, quoteId: bytes6};
const p = {symbol: bytes32, baseId: bytes6, baseMaturity: uint, quoteId: bytes6, quoteMaturity: uint};
const q = {symbol: bytes32, maturity: uint32, timestamp: uint};
const r = {positionId: uint, payer: address};
const s = {positionId: uint};
const t = {positionId: uint, decreaseQuantity: int, currentQuantity: uint};
const u = {quantity: int};
const v = {positionId: uint, msgSender: address, actualOwner: address};
const w = {positionId: uint, maturity: uint32, timestamp: uint};





export const TransactionRevertedSilently = solidityError("TransactionRevertedSilently", a);
export const UnknownToken = solidityError("UnknownToken", b);
export const NothingToWrap = solidityError("NothingToWrap", a);
export const OnlyFromWETH = solidityError("OnlyFromWETH", c);
export const PositionIsTooSmall = solidityError("PositionIsTooSmall", d);
export const InvalidInt128 = solidityError("InvalidInt128", e);
export const InvalidUInt128 = solidityError("InvalidUInt128", f);
export const ZeroDestination = solidityError("ZeroDestination", a);
export const ZeroPayer = solidityError("ZeroPayer", a);
export const CostAboveTolerance = solidityError("CostAboveTolerance", g);
export const CostBelowTolerance = solidityError("CostBelowTolerance", g);
export const InsufficientHedgeAmount = solidityError("InsufficientHedgeAmount", h);
export const InvalidAmountDeltas = solidityError("InvalidAmountDeltas", i);
export const InvalidCallbackCaller = solidityError("InvalidCallbackCaller", j);
export const InvalidPoolKey = solidityError("InvalidPoolKey", l);
export const ClosingOnly = solidityError("ClosingOnly", a);
export const InstrumentAlreadyExists = solidityError("InstrumentAlreadyExists", m);
export const InvalidBaseId = solidityError("InvalidBaseId", n);
export const InvalidInstrument = solidityError("InvalidInstrument", m);
export const InvalidQuoteId = solidityError("InvalidQuoteId", o);
export const MismatchedMaturity = solidityError("MismatchedMaturity", p);
export const InstrumentClosingOnly = solidityError("InstrumentClosingOnly", m);
export const InstrumentExpired = solidityError("InstrumentExpired", q);
export const InvalidPayer = solidityError("InvalidPayer", r);
export const InvalidPosition = solidityError("InvalidPosition", s);
export const InvalidPositionDecrease = solidityError("InvalidPositionDecrease", t);
export const InvalidQuantity = solidityError("InvalidQuantity", u);
export const NotPositionOwner = solidityError("NotPositionOwner", v);
export const PositionActive = solidityError("PositionActive", w);
export const PositionExpired = solidityError("PositionExpired", w);
