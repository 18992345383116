import { HistoryItem } from "@/api/graphql/positions"
import {
  SummaryRow,
  SummaryTable,
} from "@/App/Positions/Position/PositionEdit/Summary/SummaryTable"
import { posDivider } from "@/App/Positions/Position/PositionView/Shared"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { getPnlColor } from "@/components/PnL"
import { invertIfShort } from "@/utils/inverted-pairs-utis"
import { formatDate } from "@/utils/mappers"
import { absolute } from "@/utils/maths-utils"

const testId = (field: string) => "close-transaction-summary--" + field

export const CloseTransactionSummary: React.FC<HistoryItem> = ({
  instrument,
  fillPrice,
  previousFees,
  feeQuote,
  realisedPnLQuote,
  cashflowQuote,
  fillSize,
  blockTimestamp,
  feeQuoteAcc,
}) => {
  const { base, quote } = instrument

  const [exitPrice, exitPriceCurrency] = invertIfShort(fillPrice, instrument)

  const pnlAfterFees = realisedPnLQuote - feeQuoteAcc

  return (
    <div className="flex flex-col">
      <SummaryTable>
        <SummaryRow testId={testId("date")} title="Date and Time">
          {formatDate(blockTimestamp)}
        </SummaryRow>
        {posDivider}
        <SummaryRow testId={testId("size")} title="Position Size">
          <CurrencyDisplay currencyId={base} value={fillSize} />
        </SummaryRow>
        <SummaryRow testId={testId("exitPrice")} title="Exit Price">
          <CurrencyDisplay
            value={absolute(exitPrice)}
            currencyId={exitPriceCurrency}
          />
        </SummaryRow>
        {posDivider}
        <SummaryRow title={realisedPnLQuote >= 0 ? "Profit" : "Loss"}>
          <CurrencyDisplay
            testId={testId("grossPnL")}
            currencyId={quote}
            value={absolute(realisedPnLQuote)}
            className={getPnlColor(realisedPnLQuote)}
          />
        </SummaryRow>
        <SummaryRow testId={testId("openingFees")} title="Open/Modify Fees">
          <CurrencyDisplay currencyId={quote} value={previousFees} />
        </SummaryRow>
        <SummaryRow testId={testId("closingFee")} title="Closing Fee">
          <CurrencyDisplay currencyId={quote} value={feeQuote} />
        </SummaryRow>
        {posDivider}
        <SummaryRow title={"Net " + (pnlAfterFees >= 0 ? "Profit" : "Loss")}>
          <CurrencyDisplay
            testId={testId("netPnL")}
            currencyId={quote}
            value={absolute(pnlAfterFees)}
            className={getPnlColor(pnlAfterFees)}
          />
        </SummaryRow>
      </SummaryTable>

      <div className="flex gap-1 mx-auto py-3">
        You Received
        <CurrencyDisplay currencyId={quote} value={absolute(cashflowQuote)} />
      </div>
    </div>
  )
}
