import { classNames } from "@/utils/classNames"

export enum IconType {
  Warning = "warning",
  Error = "error",
}

export const NotificationIcon: React.FC<{
  iconType: IconType
  className?: string
}> = ({ iconType, className }) => {
  return (
    <img
      className={classNames(className)}
      alt={iconType}
      src={import.meta.env.BASE_URL + `notificationIcons/${iconType}.svg`}
    />
  )
}
