import { useState } from "react"

export const OnHoverSwitch: React.FC<{
  from: React.ReactNode
  to: React.ReactNode
}> = ({ from, to }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <span
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? to : from}
    </span>
  )
}
