import { solidityFn, solidityEvent } from "@unstoppablejs/solidity-bindings";
import { address,Tuple,uint,bool,str } from "solidity-codecs";

const a = [address, address] as [owner: typeof address, spender: typeof address];
const b = Tuple(uint);
const c = [address, uint] as [spender: typeof address, amount: typeof uint];
const d = Tuple(bool);
const e = [address] as [account: typeof address];
const f = [] as [];
const g = Tuple(str);
const h = [address, uint] as [to: typeof address, amount: typeof uint];
const i = {owner: address, spender: address};
const j = {from: address, to: address};

export const allowance = solidityFn("allowance", a, b, 1);
export const approve = solidityFn("approve", c, d, 2);
export const balanceOf = solidityFn("balanceOf", e, b, 1);
export const name = solidityFn("name", f, g, 1);
export const transfer = solidityFn("transfer", h, d, 2);

export const Approval = solidityEvent(i, b, "Approval");
export const Transfer = solidityEvent(j, b, "Transfer");




export interface ERC20 {
  functions: typeof allowance | typeof approve | typeof balanceOf | typeof name | typeof transfer,
  events: typeof Approval | typeof Transfer,
  errors: never
}