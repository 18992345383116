import { getPosition$, getPositionProp$, getPositionStatus$ } from "@/api"
import { CurrencyDisplay } from "@/components/CurrencyDisplay"
import { LeverageValue } from "@/components/Leverage"
import { Margin } from "@/components/Margin"
import { divideCurrency, formatKnownCurrency } from "@/utils/currency-utils"
import { calculateLiquidationPrice } from "@/utils/financial-utils"
import { invertIfShort } from "@/utils/inverted-pairs-utis"
import { absolute } from "@/utils/maths-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { withPrefix } from "@/utils/test-utils"
import { withBlurAndHide } from "@/utils/withLoading"
import { state } from "@react-rxjs/core"
import { map, merge, withLatestFrom } from "rxjs"
import { usePositionContext } from "../../Position.context"
import {
  entryPriceJsx$,
  liquidationPrice$,
  posDivider,
} from "../../PositionView/Shared"
import { deltaCost$, newOpenCost$ } from "../state/cost"
import { newMarginLeverage$ } from "../state/leverage"
import {
  quantityEdit$,
  quantityState$,
  QuantityValidity,
} from "../state/quantity"
import { ResultingPositionRow, SummaryTable } from "./SummaryTable"

const newOpenCostFormatted$ = state((positionId: bigint) =>
  newOpenCost$(positionId).pipe(
    withLatestFrom(getPositionProp$(positionId, "quote")),
    map(([cost, quote]) => <CurrencyDisplay value={cost} currencyId={quote} />),
    withBlurAndHide(quantityEdit$(positionId)),
  ),
)

export const newLiquidationPrice$ = state((id: bigint) =>
  deltaCost$(id).pipe(
    withLatestFrom(quantityState$(id)),
    map(([cost, qtyState]) => {
      if (qtyState.state !== QuantityValidity.VALID) return <span>-</span>
      const [liqPrice, ccy] = invertIfShort(
        calculateLiquidationPrice(cost, cost, qtyState.value),
        cost,
      )
      return (
        <CurrencyDisplay
          formatOptions={{ nDecimals: cost.decimals }}
          value={liqPrice}
          currencyId={ccy}
        />
      )
    }),
    withBlurAndHide(quantityEdit$(id)),
  ),
)

export const newEntryPrice$ = state((id: bigint) =>
  newOpenCost$(id).pipe(
    withLatestFrom(quantityState$(id), getPosition$(id)),
    mapDistinct(([openCost, quantityState, instrument]) => {
      if (quantityState.state !== QuantityValidity.VALID) return <span>-</span>
      const newPrice = divideCurrency(
        { value: openCost, currency: instrument.quote },
        { value: quantityState.value, currency: instrument.base },
      )
      const [value, ccy] = invertIfShort(newPrice, instrument)
      return (
        <CurrencyDisplay
          formatOptions={{ nDecimals: instrument.decimals }}
          value={value}
          currencyId={ccy}
        />
      )
    }),
    withBlurAndHide(quantityEdit$(id)),
  ),
)

const newLeverage$ = state((id: bigint) =>
  newMarginLeverage$(id).pipe(
    map(({ leverage }) => (
      <LeverageValue testIdPrefix="edit-to" leverage={leverage} />
    )),
    withBlurAndHide(quantityEdit$(id)),
  ),
)

const newMargin$ = state((id: bigint) =>
  newMarginLeverage$(id).pipe(
    map(({ margin }) => (
      <span
        data-testid={withPrefix("margin", "edit-to")}
        className="flex justify-end "
      >{`${margin}%`}</span>
    )),
    withBlurAndHide(quantityEdit$(id)),
  ),
)

const openCost$ = state((id: bigint) =>
  getPosition$(id).pipe(
    map(({ openCost, quote }) => (
      <span data-testid="position--open-cost">
        {formatKnownCurrency(absolute(openCost), quote)}
      </span>
    )),
  ),
)

export const ResultingPositionSummary$ = state((id: bigint) =>
  merge(
    newMarginLeverage$(id),
    newLeverage$(id),
    newMargin$(id),
    liquidationPrice$(id),
    newLiquidationPrice$(id),
    entryPriceJsx$(id),
    newEntryPrice$(id),
    openCost$(id),
    newOpenCostFormatted$(id),
  ),
)

export const ResultingPositionSummary: React.FC = () => {
  const { id } = usePositionContext()

  return (
    <SummaryTable>
      <ResultingPositionRow
        title="Leverage:"
        from={getPositionStatus$(id).pipeState(
          map(({ leverage }) => (
            <LeverageValue testIdPrefix="edit-from" leverage={leverage} />
          )),
        )}
        to={newLeverage$(id)}
        tooltipKey="leverage"
        resultingTestId="leverage"
      />
      {posDivider}
      <ResultingPositionRow
        title="Margin:"
        from={getPositionStatus$(id).pipeState(
          map(({ margin, minMargin }) => (
            <Margin margin={margin} minMargin={minMargin} showSuffix={false} />
          )),
        )}
        to={newMargin$(id)}
        tooltipKey="margin"
        resultingTestId="margin"
      />
      <ResultingPositionRow
        title="Liquidation Price:"
        from={liquidationPrice$(id)}
        to={newLiquidationPrice$(id)}
        tooltipKey="liquidationPrice"
        resultingTestId="liquidationPrice"
      />
      <ResultingPositionRow
        title="Entry Price:"
        from={entryPriceJsx$(id)}
        to={newEntryPrice$(id)}
        tooltipKey="entryPrice"
        resultingTestId="entryPrice"
      />
      {posDivider}
      <ResultingPositionRow
        title="Position Value:"
        tooltipKey="positionValue"
        from={openCost$(id)}
        to={newOpenCostFormatted$(id)}
        resultingTestId="openCost"
      />
    </SummaryTable>
  )
}
