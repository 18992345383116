import React, { useState } from "react"
import { usePositionContext } from "./Position.context"
import { EditType, onEditOrCancel } from "./PositionEdit/state/base"
import {
  DeliveryType,
  onDeliverOrCancel,
} from "./PositionSettlement/state/base"

import { ActionButton, ActionsPanel } from "@/components/PositionMenu"

export const ExpiredPositionActions = () => {
  const { id } = usePositionContext()
  let [isOpen, setIsOpen] = useState(false)
  return (
    <ActionsPanel isOpen={isOpen} setIsOpen={setIsOpen} testIdPrefix="position">
      <ActionButton
        data-testid="position--deliver-settle-button"
        onClick={() => {
          setIsOpen(false)
          //timeout is important here to prevent multiple dialogs interference resulting in the body scroll to become blocked
          setTimeout(() => {
            onDeliverOrCancel(id, DeliveryType.settlement)
          }, 100)
        }}
      >
        Close
      </ActionButton>
    </ActionsPanel>
  )
}

export const PositionActions: React.FC = () => {
  const { id } = usePositionContext()
  let [isOpen, setIsOpen] = useState(false)
  return (
    <ActionsPanel isOpen={isOpen} setIsOpen={setIsOpen} testIdPrefix="position">
      <ActionButton
        data-testid="position--close-button"
        onClick={() => {
          setIsOpen(false)
          //timeout is important here to prevent multiple dialogs interference resulting in the body scroll to become blocked
          setTimeout(() => {
            onEditOrCancel(id, EditType.close)
          }, 100)
        }}
      >
        Close
      </ActionButton>
      <ActionButton
        data-testid="position--edit-button"
        onClick={() => {
          setIsOpen(false)
          //timeout is important here to prevent multiple dialogs interference resulting in the body scroll to become blocked
          setTimeout(() => {
            onEditOrCancel(id, EditType.edit)
          }, 100)
        }}
      >
        Modify
      </ActionButton>
    </ActionsPanel>
  )
}
