import { ApprovalType, approvalType$ } from "@/App/state/approvals"
import { withBlurryOverlay } from "@/components/BlurryOverlay"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import { PrimaryButton } from "@/components/PrimaryButton"
import { SecondaryButton } from "@/components/SecondaryButton"
import { RemoveSubscribe, useStateObservable } from "@react-rxjs/core"
import { Suspense } from "react"
import { merge } from "rxjs"
import { usePositionContext } from "../Position.context"
import { SummaryRow, SummaryTable } from "../PositionEdit/Summary/SummaryTable"
import { deliveryCost$, quantityJsx$ } from "../PositionView/Shared"
import {
  approvalNeeded$,
  isSubmittingApproval$,
  onSubmittingDeliveryApproval,
} from "./state/approvals"
import { DeliveryType, onDeliverOrCancel } from "./state/base"
import {
  hasSufficientBalance$,
  isSubmittingDelivery$,
  onUserSubmitDeliver,
} from "./state/submitting"

const DeliverPositionSummary: React.FC = () => {
  const { id } = usePositionContext()
  return (
    <SummaryTable>
      <SummaryRow tooltipKey="deliveryQuantity" title="You Receive">
        {quantityJsx$(id)}
      </SummaryRow>
      <SummaryRow tooltipKey="deliveryCost" title="You Pay">
        {deliveryCost$(id)}
      </SummaryRow>
    </SummaryTable>
  )
}

const SubmittingOverlay = withBlurryOverlay(() => {
  const { id } = usePositionContext()
  return useStateObservable(isSubmittingDelivery$(id))
})

export const PositionDelivery$ = (positionId: bigint) =>
  merge(
    hasSufficientBalance$(positionId),
    quantityJsx$(positionId),
    deliveryCost$(positionId),
    isSubmittingApproval$(positionId),
  )

export const ApproveSpending: React.FC<{ id: bigint }> = ({ id }) => {
  const isSubmittingApproval = useStateObservable(isSubmittingApproval$(id))

  return (
    <PrimaryButton
      testId="position-deliver--approve-spending"
      className="w-full"
      disabled={isSubmittingApproval}
      onClick={() => onSubmittingDeliveryApproval(id)}
    >
      {isSubmittingApproval ? "Awaiting Approval" : "Approve Spending"}
    </PrimaryButton>
  )
}

export const PositionDelivery: React.FC = () => {
  const { id } = usePositionContext()
  const hasSufficientBalance = useStateObservable(hasSufficientBalance$(id))

  const approvalType = useStateObservable(approvalType$)
  const needsApproval =
    useStateObservable(approvalNeeded$(id)) &&
    approvalType !== ApprovalType.Permit

  const primaryButton = needsApproval ? (
    <ApproveSpending id={id} />
  ) : (
    <PrimaryButton
      className="w-full"
      testId="position-deliver--submit"
      disabled={!hasSufficientBalance}
      onClick={onUserSubmitDeliver}
    >
      Deliver
    </PrimaryButton>
  )

  return (
    <RemoveSubscribe>
      <Suspense fallback={null}>
        <div className="flex flex-col">
          <SubmittingOverlay>
            <DeliverPositionSummary />
            <InsufficientBalanceMessage />
            <div className="flex flex-col gap-4 mt-4">
              {primaryButton}
              <SecondaryButton
                className="w-full"
                onClick={() => {
                  onDeliverOrCancel(id, DeliveryType.none)
                }}
              >
                Cancel
              </SecondaryButton>
            </div>
          </SubmittingOverlay>
        </div>
      </Suspense>
    </RemoveSubscribe>
  )
}

export const InsufficientBalanceMessage = () => {
  const { id, quote } = usePositionContext()
  const hasSufficientBalance = useStateObservable(hasSufficientBalance$(id))
  return (
    <FormMessage
      iconType={IconType.Error}
      visible={!hasSufficientBalance}
      testId="position-delivery-insufficient-balance"
    >{`Insufficient ${quote} balance for physical delivery.`}</FormMessage>
  )
}
