import { ensureAccountAndNetwork } from "@/api"
import { addTrace } from "@/utils/traces"
import { merge } from "rxjs"
import { CreateTicket, CreateTicket$ } from "./CreateTicket/CreateTicket"
import { Positions, Positions$ } from "./Positions/Positions"
import { Shell, Shell$ } from "./Shell"
import { WalletProvider } from "./Wallet"

const App$ = merge(Shell$, Positions$, CreateTicket$)

ensureAccountAndNetwork(() => App$).subscribe({
  error(e) {
    console.error("TOP LEVEL SUBSCRIPTION DIED!")
    console.error(e)
    addTrace(e)
  },
  complete() {
    console.error("TOP LEVEL SUBSCRIPTION COMPLETED... WTF!?")
  },
})

export function App() {
  return (
    <WalletProvider>
      <Shell>
        <div className="p-6 lg:px-8 flex flex-col lg:flex-row gap-6 h-full">
          <div className="w-full lg:w-[22rem]">
            <CreateTicket />
          </div>
          <div className="lg:h-full flex-1 flex flex-col lg:w-96">
            <div className="h-80 hidden lg:block lg:h-2/3 w-full max-h-[600px] pb-3">
              {/* <TradingViewChart /> */}
            </div>
            <div className="lg:h-1/3 w-full pt-3 pb-14 md:pb-0">
              <Positions />
            </div>
          </div>
        </div>
      </Shell>
    </WalletProvider>
  )
}
