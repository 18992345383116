import type { ERC2612PermitDomain, RSV, SignedERC2612Permit } from "@/api"
import {
  SolidityContractClient,
  withContract,
} from "@unstoppablejs/solidity-bindings"
import { fromHex } from "@unstoppablejs/utils"
import { Base, ChainId, Network, Quote } from "../generated"
import { account$, network$ } from "../wallet"
import { client } from "./common"
import {
  allowance,
  Approval,
  approve,
  balanceOf,
  ERC20,
  name,
  Transfer,
} from "./contracts/ERC20"
import { nonces } from "./contracts/IERC2612"
import { getCurrentNetwork } from "./latestChain"

const { ETH: _, ...BaseWithoutETH } = Base
const { ETH: __, ...QuoteWithoutETH } = Quote

export const ERC20s = { ...BaseWithoutETH, ...QuoteWithoutETH }
export type ERC20s = keyof typeof ERC20s
const contracts: Record<ERC20s, SolidityContractClient> = {} as any

Object.values(ERC20s).forEach((baseOrQuote) => {
  contracts[baseOrQuote] = withContract<ERC20>(
    () => getCurrentNetwork().addresses[baseOrQuote],
    client,
  )
})

const withERC20Contract = <I extends Array<any>, O>(
  cb: (context: SolidityContractClient) => (...args: I) => O,
): ((token: Exclude<Base | Quote, "ETH">, ...args: I) => O) => {
  return (token, ...args) => cb(contracts[token])(...args)
}
export const getBalance = withERC20Contract((c) => c.call(balanceOf))
export const getTransferEvent = withERC20Contract((c) => c.event(Transfer))
export const getTokenName = withERC20Contract((c) => c.call(name))
export const getAllowance = withERC20Contract((c) => c.call(allowance))

export const approveSpending = withERC20Contract((c) => c.tx(approve))
export const getApprovalEvent = withERC20Contract((c) => c.event(Approval))

const getNonce = withERC20Contract((c) => c.call(nonces))

const signData = async (fromAddress: string, data: string): Promise<RSV> => {
  const result = await client
    .request("eth_signTypedData_v4", [fromAddress, data])
    .catch((error: any) => {
      if (error.message === "Method eth_signTypedData_v4 not supported.") {
        return client.request("eth_signTypedData", [fromAddress, data])
      } else {
        throw error
      }
    })

  const res = {
    r: fromHex(result.slice(0, 66)),
    s: fromHex("0x" + result.slice(66, 130)),
    v: BigInt("0x" + result.slice(130, 132)),
  }
  if (res.v < 27n) {
    res.v += 27n
  }
  return res
}

export const signERC2612Permit = async (
  token: ERC20s,
  value: bigint,
): Promise<SignedERC2612Permit> => {
  const owner = account$.getValue() as string
  const spender = (network$.getValue() as Network).addresses.contango
  const [nonce, tokenName, chainId] = await Promise.all([
    getNonce(token, owner),
    getTokenName(token),
    client.request("eth_chainId", []),
  ] as const)

  // This is a bit hacky, but will have to do for now. This check will be moved to the generated.ts structure in near future.
  const version =
    (chainId === ChainId["Arbitrum"] || chainId === ChainId["Localhost8546"]) &&
    token === Quote["DAI"]
      ? "2"
      : "1"

  const domain: ERC2612PermitDomain = {
    name: tokenName,
    version,
    chainId: Number(chainId),
    verifyingContract: (network$.getValue() as Network).addresses[token],
  }

  const message = {
    owner,
    spender,
    value,
    nonce,
    deadline: BigInt(Number.MAX_SAFE_INTEGER),
  }

  const typedData = {
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    },
    primaryType: "Permit",
    domain,
    message: {
      ...message,
      value: value.toString(),
      nonce: nonce.toString(),
      deadline: message.deadline.toString(),
    },
  }

  const signed = await signData(owner, JSON.stringify(typedData))

  return { ...signed, ...message, ...domain }
}
