import { BalanceTooLowErrorMessage } from "@/components/Shared"
import { useStateObservable } from "@react-rxjs/core"
import React from "react"
import { usePositionContext } from "../../Position.context"
import { hasEnoughBalance$ } from "../state/submitting"

export const EditBalanceTooLowErrorMessage: React.FC = () => {
  const { id } = usePositionContext()
  const hasEnoughBalance = useStateObservable(hasEnoughBalance$(id))
  return (
    <BalanceTooLowErrorMessage
      hasEnoughBalance={hasEnoughBalance}
      testId="edit-position--insufficient-balance"
    />
  )
}
