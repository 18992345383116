import { withPrefix } from "@/utils/test-utils"
import { Transition } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import { Fragment, PropsWithChildren, useState } from "react"

export const Collapsable: React.FC<
  PropsWithChildren & { label: string; testId: string }
> = ({ children, label, testId }) => {
  const [showContent, setShowContent] = useState(false)

  return (
    <div className="flex flex-col gap-3">
      <Transition
        show={showContent}
        as={Fragment}
        key={`collapsable-${testId}`}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 translate-y-[-20px]"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 translate-y-[-20px]"
      >
        <div className="flex flex-col">{children}</div>
      </Transition>
      <button
        type="button"
        className="flex justify-center items-center"
        data-testid={withPrefix("advanced-details-button", testId)}
        onClick={() => setShowContent((prev) => !prev)}
      >
        <span className="text-sm font-primary text-functional-buy-500">
          {label}
        </span>
        {showContent ? (
          <ChevronUpIcon className="h-4 text-functional-buy-500" />
        ) : (
          <ChevronDownIcon className="h-4 text-functional-buy-500" />
        )}
      </button>
    </div>
  )
}
