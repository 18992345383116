import { getOpeningCostByLeverage$ } from "@/api"
import {
  calculateLeverage,
  calculateMaxOpeningLeverage,
} from "@/utils/financial-utils"
import {
  justWait,
  nullWhen,
  suspenseToNull,
  unsuspended,
} from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { combineLatest, concat, map, of, switchMap } from "rxjs"
import { onFormError } from "./error/formErrors"
import { isQtyNullAndBlurred$, leverageInput$ } from "./inputs"
import { formChangeEvents$ } from "./inputsChange"
import { instrument$ } from "./instrument"
import { costData$ } from "./price"
import { benchmarkMinQuantityCost$, validQuantity$ } from "./validations"

export const leverageBoundariesForQuantity$ = state(
  combineLatest([
    validQuantity$,
    instrument$,
    unsuspended(benchmarkMinQuantityCost$),
  ]).pipe(
    switchMap(([quantity, { id }, { liquidationRatio, ltvRatio }]) => {
      const maxOpeningLeverage = calculateMaxOpeningLeverage(
        liquidationRatio,
        ltvRatio,
      )
      if (!quantity)
        return of({
          lowerBound: 1.01,
          upperBound: 1,
          maxOpeningLeverage,
        })

      return concat(
        justWait(200),
        getOpeningCostByLeverage$(
          id,
          quantity,
          BigInt(calculateLeverage(1e6, liquidationRatio) * 1e18),
        ).pipe(
          map(({ underlyingDebt, underlyingCollateral, minDebt }) => ({
            lowerBound: calculateLeverage(minDebt, underlyingCollateral),
            upperBound: Math.min(
              calculateLeverage(underlyingDebt, underlyingCollateral),
              maxOpeningLeverage,
            ),
            maxOpeningLeverage,
          })),
        ),
      )
    }),
    onFormError("leverageBoundariesForQuantity")(formChangeEvents$),
  ),
)

const computedLeverage$ = state(
  costData$.pipe(
    map(({ leverage }) => leverage),
    suspenseToNull(),
  ),
  null,
)

export const leverage$ = state(
  combineLatest([leverageInput$, computedLeverage$]).pipe(
    map(([inputValue, computedLeverage]) =>
      inputValue === null ? computedLeverage : inputValue,
    ),
    nullWhen(isQtyNullAndBlurred$),
  ),
)
