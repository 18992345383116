interface Node<T> {
  value: T
  next?: Node<T>
}

export class Queue<T> {
  #nElements: number
  #maxElements: number
  #first: Node<T>
  #current: Node<T>

  constructor(maxElements: number) {
    this.#nElements = 0
    this.#maxElements = maxElements
    this.#current = this.#first = {} as Node<T>
  }

  add(value: T) {
    this.#current = this.#current.next = { value }
    if (this.#nElements === this.#maxElements || !this.#nElements++) {
      const oldOne = this.#first
      this.#first = oldOne.next!
      delete oldOne.next
      delete (oldOne as any).value
    }
  }

  iterate(cb: (v: T) => void) {
    let current: Node<T> | undefined = this.#first
    while (current) {
      cb(current.value)
      current = current.next
    }
  }
}

let running = true
const queue = new Queue<any>(100)

export const addTrace = (trace: any) => running && queue.add(trace)

export const stopTraces = () => {
  running = false
}

export const getStringifiedTraces = () => {
  const msgs: Array<string> = []
  queue.iterate((item) => {
    if (item instanceof Error) {
      msgs.push(item.message)
      item.stack && msgs.push(item.stack)
      return
    }

    try {
      msgs.push(
        JSON.stringify(
          item,
          (_, v) => {
            if (typeof v !== "bigint") return v
            return v.toString() + "n"
          },
          2,
        ),
      )
    } catch (e) {
      msgs.push(`Error stringifing trace. Error: ${(e as Error).message}.`)
    }
  })

  return msgs.join("\n\n")
}
