import { Currency, CurrencyId } from "@/api/types"

export const decimalSeparatorDisplay = "."
export const decimalSeparatorsInput = [".", ","]
export const decimalSeparatorRegex = ",|."

const parsingRegex = new RegExp(`^(-)?(\\d*)(${decimalSeparatorRegex}(\\d+))?$`)

export const getDecimalSeparator = (inputString: string) => {
  const parts = new RegExp(
    `^(-)?(\\d*)(${decimalSeparatorRegex})?((\\d+))?$`,
  ).exec(inputString)

  return parts ? parts[3] : null
}

export type FormatCurrencyOptions = {
  nDecimals: number
  padToDecimals: boolean
  decimalSeparator: string
}

const defaultOptions: FormatCurrencyOptions = {
  nDecimals: Infinity,
  padToDecimals: true,
  decimalSeparator: decimalSeparatorDisplay,
}

export const formatCurrency = (
  value: bigint | null,
  precision: number,
  options: Partial<FormatCurrencyOptions> = {},
): string => {
  const { nDecimals, padToDecimals, decimalSeparator } = {
    ...defaultOptions,
    ...options,
  }
  if (value === null) return ""
  const precisionMultiplier = 10n ** BigInt(precision)
  if (nDecimals < precision) {
    value = value / 10n ** BigInt(precision - (nDecimals + 1))
    const rounded = Math.abs(Number(value % 10n)) > 4
    const rounding = rounded ? (value < 0 ? -1n : 1n) : 0n
    value = value / 10n + rounding
    value *= 10n ** BigInt(precision - nDecimals)
  }
  const isNegative = value < 0n
  const absValue = isNegative ? value * -1n : value
  let intPartStr = (absValue / precisionMultiplier).toString()
  if (isNegative) intPartStr = "-" + intPartStr
  const decimalPart = absValue % precisionMultiplier

  if (
    nDecimals === 0 ||
    (nDecimals === Infinity && decimalPart === 0n) ||
    (padToDecimals === false && decimalPart === 0n)
  )
    return intPartStr

  let newDecimalPart = decimalPart
    .toString()
    .padStart(precision, "0")
    .replace(/00*$/, "")

  if (nDecimals !== Infinity && padToDecimals === true) {
    newDecimalPart = newDecimalPart.padEnd(nDecimals, "0")
  }

  return intPartStr + decimalSeparator + newDecimalPart
}

const STABLES: CurrencyId[] = [CurrencyId.USDC, CurrencyId.DAI, CurrencyId.USDT]

const shouldPadByDefault = (currencyId: CurrencyId) => {
  return STABLES.includes(currencyId)
}

export const formatMoney = (
  value: Money | null,
  options: Partial<FormatCurrencyOptions> = {},
): string => {
  const currencyId = value?.currency ?? CurrencyId.USDC
  const { precision, displayDecimals } = Currency[currencyId]

  return formatCurrency(value?.value ?? null, precision, {
    padToDecimals: shouldPadByDefault(currencyId),
    ...options,
    nDecimals: options.nDecimals || displayDecimals,
  })
}

export const formatKnownCurrency = (
  value: bigint | null,
  currencyId: CurrencyId,
  options: Partial<FormatCurrencyOptions> = {},
): string => {
  const { precision, displayDecimals } = Currency[currencyId]

  return formatCurrency(value, precision, {
    padToDecimals: shouldPadByDefault(currencyId),
    ...options,
    nDecimals: options.nDecimals || displayDecimals,
  })
}

export const parseCurrency = (
  value: string,
  currency: CurrencyId,
): bigint | null => {
  if (!value) return null
  const parts = parsingRegex.exec(value)
  if (!parts)
    throw new Error(`Error parsing unnexpected currency value: ${value}`)

  const [, minus, intPartStr, , decimalPartStr] = parts

  const { precision, precisionMultiplier } = Currency[currency]
  const intPart = BigInt(intPartStr) * precisionMultiplier
  const decPart = decimalPartStr
    ? BigInt(
        (decimalPartStr.length > precision
          ? decimalPartStr.substring(0, precision)
          : decimalPartStr
        ).padEnd(precision, "0"),
      )
    : 0n

  const result = intPart + decPart

  return minus ? 0n - result : result
}

export type Money = {
  value: bigint
  currency: CurrencyId
}

export const divideCurrency = (numerator: Money, denominator: Money) => {
  if (numerator.value === 0n || denominator.value === 0n) return 0n
  const numeratorPrecision = BigInt(Currency[numerator.currency].precision)
  const denominatorPrecision = BigInt(Currency[denominator.currency].precision)

  return divideBigInt(
    { value: numerator.value, precision: numeratorPrecision },
    { value: denominator.value, precision: denominatorPrecision },
  )
}

export const divideMoney = (numerator: Money, denominator: Money): Money => {
  if (numerator.value === 0n || denominator.value === 0n)
    return {
      value: 0n,
      currency: numerator.currency,
    }
  const numeratorPrecision = BigInt(Currency[numerator.currency].precision)
  const denominatorPrecision = BigInt(Currency[denominator.currency].precision)

  return {
    value: divideBigInt(
      { value: numerator.value, precision: numeratorPrecision },
      { value: denominator.value, precision: denominatorPrecision },
    ),
    currency: numerator.currency,
  }
}

type BigNumber = {
  value: bigint
  precision: bigint
}

export const divideBigInt = (
  numerator: BigNumber,
  denominator: BigNumber,
  targetPrecision?: bigint,
) => {
  const precision = targetPrecision ?? numerator.precision
  return (
    (10n ** precision * (numerator.value * 10n ** denominator.precision)) /
    denominator.value /
    10n ** numerator.precision
  )
}
