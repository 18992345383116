import { classNames } from "@/utils/classNames"
import { Dialog } from "@headlessui/react"
import React, {
  createRef,
  PropsWithChildren,
  useLayoutEffect,
  useState,
} from "react"

export const Tooltip: React.FC<
  PropsWithChildren<{
    message: React.ReactNode | string
    className?: string
    innerClassName?: string
    testId?: string
  }>
> = ({ message, children, className, testId, innerClassName }) => {
  const tooltipRef = createRef<HTMLDivElement>()
  const dialogRef = createRef<HTMLDivElement>()
  const [isShowing, setIsShowing] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [posX, setPosX] = useState(0)
  const [posY, setPosY] = useState(0)
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>()
  const margin = 10

  useLayoutEffect(() => {
    if (isCreated && dialogRef.current) {
      setWidth(dialogRef.current.getBoundingClientRect().width)
      setHeight(dialogRef.current.getBoundingClientRect().height)
    }
    if (isShowing && tooltipRef.current) {
      const offsetX =
        window.innerWidth - tooltipRef.current.getBoundingClientRect().left <
        width
          ? window.innerWidth -
            (tooltipRef.current.getBoundingClientRect().left + width + margin)
          : 0
      const offsetY =
        tooltipRef.current.getBoundingClientRect().top - (height + 5) < margin
          ? tooltipRef.current.getBoundingClientRect().bottom + 5
          : tooltipRef.current.getBoundingClientRect().top - (height + 5)
      setPosX(tooltipRef.current.getBoundingClientRect().left + offsetX)
      setPosY(offsetY)
    }
  })

  function showTooltip() {
    setIsCreated(true)
    setTimer(
      setTimeout(() => {
        setIsShowing(true)
      }, 400),
    )
  }

  function hideTooltip() {
    timer && clearTimeout(timer)
    setIsShowing(false)
    setIsCreated(false)
  }

  return (
    <span>
      <div
        ref={tooltipRef}
        className={classNames(
          "inline-block underline underline-offset-2 decoration-1 decoration-dotted hover:decoration-solid",
          className,
        )}
        onMouseEnter={() => {
          showTooltip()
        }}
        onMouseLeave={() => {
          hideTooltip()
        }}
        data-testid={testId}
      >
        {children}
      </div>
      <Dialog
        as="div"
        onClose={() => {}}
        open={isCreated}
        style={{
          top: `${posY}px`,
          left: `${posX}px`,
        }}
        className={classNames(
          "fixed z-20 pointer-events-none",
          innerClassName,
          isShowing ? "opacity-100" : "opacity-0",
        )}
      >
        <div
          ref={dialogRef}
          className="relative px-3 py-2 text-sm text-backgrounds-0 font-primary bg-fontColor-0 drop-shadow-tooltip rounded-lg w-max max-w-[350px] whitespace-normal"
        >
          {message}
        </div>
      </Dialog>
    </span>
  )
}
