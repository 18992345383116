import { onUncaughtError } from "@/utils/error-utils"
import { state } from "@react-rxjs/core"
import { createKeyedSignal } from "@react-rxjs/utils"

export type FormErrorOrigin =
  | "marketImpact"
  | "cannotRetrieveQuote"
  | "leverageBoundariesForQuantity"
  | "noLiquidity"
  | "insufficientLiquidity"
  | "insufficientBalance"

const [errors$, setFormError] = createKeyedSignal(
  ({ origin }) => origin,
  (origin: FormErrorOrigin, error: any) => ({ origin, error }),
)

// TODO: Reset once currency pair changes
const formErrors$ = state(errors$, null)

export { formErrors$, setFormError }

export const onFormError = (origin: FormErrorOrigin) =>
  onUncaughtError((error) => {
    if (error)
      console.error(
        `Error calling ${origin}`,
        error,
        typeof error,
        Object.keys(error),
      )
    return setFormError(origin, error)
  })
