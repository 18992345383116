import { sinkSuspense, state, withDefault } from "@react-rxjs/core"
import { combineLatest, distinctUntilChanged, filter, map } from "rxjs"
import { latestBlockTimestamp$ } from "./chain/common"
import { Instrument, Maturity, NetworkInstruments } from "./generated"
import { network$ } from "./wallet"

// excluding network changes, this observable will only emit once UNLESS:
// some instruments expire during the lifecylce of the subscription.
// If after filtering out expired/expiring instruments, there are none left,
// return suspend, which we'll catch at the top level (CreateTicket.tsx) and show a placeholder
const instrumentsWithError$ = state(
  combineLatest([network$, latestBlockTimestamp$]).pipe(
    map(([network, latestBlockTimestamp]) => {
      const instruments = NetworkInstruments[network.chainData.chainId]
      const result = instruments.filter((instrument) => {
        const maturity = Maturity[instrument.maturity].timestamp
        const timeToExpiry = maturity - latestBlockTimestamp
        return timeToExpiry > network.expiryBuffer
      })
      if (result.length === 0)
        return new Error("No Active Instruments To Display")
      return result
    }),
    sinkSuspense(),
  ),
)

export const noActiveInstruments$ = instrumentsWithError$.pipeState(
  map((x) => x instanceof Error),
  withDefault(null),
)

export const instruments$ = instrumentsWithError$.pipeState(
  filter((x): x is Instrument[] => !(x instanceof Error)),
  distinctUntilChanged((prev, curr) => prev.length === curr.length),
)
