import { getPositionStatus$ } from "@/api"
import { calculateMarginAndLeverage } from "@/utils/financial-utils"
import { filterOutSuspenseAndNull } from "@/utils/observable-utils"
import { liftSuspense, state } from "@react-rxjs/core"
import { concat, map, pluck, take, withLatestFrom } from "rxjs"
import { deltaCost$ } from "./cost"
import { leverageInput$ } from "./inputs"

export const collateralUsed$ = state((positionId: bigint) =>
  deltaCost$(positionId).pipe(
    map(({ collateralUsed }) => collateralUsed),
    liftSuspense(),
    filterOutSuspenseAndNull(),
  ),
)

export const newMarginLeverage$ = state((id: bigint) =>
  deltaCost$(id).pipe(map(calculateMarginAndLeverage)),
)

export const leverageValue$ = state((id: bigint) => {
  const init$ = getPositionStatus$(id).pipe(pluck("leverage"), take(1))

  return concat(init$, leverageInput$(id))
})

export const hasLeverageDelta$ = (id: bigint) =>
  leverageValue$(id).pipe(
    withLatestFrom(getPositionStatus$(id)),
    map(([lev, { leverage: init }]) => lev !== init),
  )
