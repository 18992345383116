export const withRetries = <A extends Array<any>, T>(
  fn: (...args: A) => Promise<T>,
  onError: (error: any, nTries: number, args: A) => number,
  shouldWaitUntilRecovery: boolean,
): ((...args: A) => Promise<T>) => {
  let waitForRecovery: null | {
    p: Promise<void>
    res: () => void
  } = null

  return async (...args: A) => {
    const retriable = async (nTries: number, ...args: A): Promise<T> => {
      try {
        const result = await fn(...args)
        waitForRecovery?.res()
        return result
      } catch (e) {
        if (shouldWaitUntilRecovery && !waitForRecovery) {
          let res: () => void = () => {}
          const p = new Promise<void>((_res, _rej) => {
            res = _res
          }).finally(() => {
            waitForRecovery = null
          })
          waitForRecovery = { p, res }
        }

        let result: number = 0
        try {
          result = onError(e, nTries, args)
        } catch (error) {
          waitForRecovery?.res()
          throw error
        }

        await new Promise((res) => setTimeout(res, result))
        return retriable(nTries + 1, ...args)
      }
    }

    if (waitForRecovery) await waitForRecovery.p
    return retriable(1, ...args)
  }
}
