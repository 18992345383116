import { solidityFn, solidityError } from "@unstoppablejs/solidity-bindings";
import { Vector,bytes,Tuple,address,uint,uint24,bool,uint8,bytes32,int,uint32,Struct,bytes6 } from "solidity-codecs";
import { bytes32str } from "@/api/utils";

const a = Vector(bytes);
const b = [a] as [calls: typeof a];
const c = Tuple(a);
const d = [bytes32str, address, uint, uint, uint, address, uint, uint24] as [symbol: typeof bytes32str, trader: typeof address, quantity: typeof uint, limitCost: typeof uint, collateral: typeof uint, payer: typeof address, lendingLiquidity: typeof uint, uniswapFee: typeof uint24];
const e = Tuple(uint);
const f = [uint, address, address] as [positionId: typeof uint, payer: typeof address, to: typeof address];
const g = Tuple();
const h = [address, address, uint, uint, bool, uint8, bytes32, bytes32] as [token: typeof address, spender: typeof address, nonce: typeof uint, deadline: typeof uint, allowed: typeof bool, v: typeof uint8, r: typeof bytes32, s: typeof bytes32];
const i = [address, address, uint, uint, uint8, bytes32, bytes32] as [token: typeof address, spender: typeof address, amount: typeof uint, deadline: typeof uint, v: typeof uint8, r: typeof bytes32, s: typeof bytes32];
const j = [uint, int, uint, address, uint] as [positionId: typeof uint, collateral: typeof int, slippageTolerance: typeof uint, payerOrReceiver: typeof address, lendingLiquidity: typeof uint];
const k = [uint, int, uint, int, address, uint, uint24] as [positionId: typeof uint, quantity: typeof int, limitCost: typeof uint, collateral: typeof int, payerOrReceiver: typeof address, lendingLiquidity: typeof uint, uniswapFee: typeof uint24];
const l = [uint] as [positionId: typeof uint];
const m = Struct({symbol:bytes32str,openQuantity:uint,openCost:uint,collateral:int,protocolFees:uint,maturity:uint32,feeModel:address});
const n = Tuple(m);
const o = [address] as [to: typeof address];
const p = [] as [];
const q = {};
const r = {symbol: bytes32str};
const s = {symbol: bytes32str, baseId: bytes6};
const t = {symbol: bytes32str, quoteId: bytes6};
const u = {symbol: bytes32str, baseId: bytes6, baseMaturity: uint, quoteId: bytes6, quoteMaturity: uint};
const v = {sender: address};
const w = {openCost: uint, minCost: uint};
const x = {token: address};

export const batch = solidityFn("batch", b, c, 3);
export const createPosition = solidityFn("createPosition", d, e, 3);
export const deliver = solidityFn("deliver", f, g, 3);
export const forwardDaiPermit = solidityFn("forwardDaiPermit", h, g, 3);
export const forwardPermit = solidityFn("forwardPermit", i, g, 3);
export const modifyCollateral = solidityFn("modifyCollateral", j, g, 3);
export const modifyPosition = solidityFn("modifyPosition", k, g, 3);
export const position = solidityFn("position", l, n, 1);
export const unwrapWETH = solidityFn("unwrapWETH", o, e, 3);
export const wrapETH = solidityFn("wrapETH", p, e, 3);



export const ClosingOnly = solidityError("ClosingOnly", q);
export const InstrumentAlreadyExists = solidityError("InstrumentAlreadyExists", r);
export const InvalidBaseId = solidityError("InvalidBaseId", s);
export const InvalidInstrument = solidityError("InvalidInstrument", r);
export const InvalidQuoteId = solidityError("InvalidQuoteId", t);
export const MismatchedMaturity = solidityError("MismatchedMaturity", u);
export const NothingToWrap = solidityError("NothingToWrap", q);
export const OnlyFromWETH = solidityError("OnlyFromWETH", v);
export const PositionIsTooSmall = solidityError("PositionIsTooSmall", w);
export const TransactionRevertedSilently = solidityError("TransactionRevertedSilently", q);
export const UnknownToken = solidityError("UnknownToken", x);
export const ZeroDestination = solidityError("ZeroDestination", q);
export const ZeroPayer = solidityError("ZeroPayer", q);


export interface ContangoYield {
  functions: typeof batch | typeof createPosition | typeof deliver | typeof forwardDaiPermit | typeof forwardPermit | typeof modifyCollateral | typeof modifyPosition | typeof position | typeof unwrapWETH | typeof wrapETH,
  events: never,
  errors: typeof ClosingOnly | typeof InstrumentAlreadyExists | typeof InvalidBaseId | typeof InvalidInstrument | typeof InvalidQuoteId | typeof MismatchedMaturity | typeof NothingToWrap | typeof OnlyFromWETH | typeof PositionIsTooSmall | typeof TransactionRevertedSilently | typeof UnknownToken | typeof ZeroDestination | typeof ZeroPayer
}