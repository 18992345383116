import { CurrencyId } from "@/api"
import { useCurrencyInput } from "@/hooks/useCurrencyInput"
import { NumberInput, NumberInputProps } from "./NumberInput"

type NumberInputWithIconProps = Omit<
  NumberInputProps,
  "suffix" | "onChange" | "value"
> & {
  value: bigint | null
  currency: CurrencyId
  onChange?: (value: bigint | null) => void
}

export const CurrencyInput: React.FC<NumberInputWithIconProps> = ({
  value: valueBigint,
  onChange: onChangeBigint,
  currency,
  ...props
}) => {
  const [value, onChange] = useCurrencyInput(
    valueBigint,
    currency,
    onChangeBigint,
  )

  return (
    <NumberInput
      className="text-base"
      value={value}
      onChange={onChange}
      suffix={currency}
      {...props}
    />
  )
}
