import {
  cashSettle,
  expiredPositionData$,
  getPosition$,
  onSettlementSlippageChanged,
  settlementData,
  settlementSlippage$,
} from "@/api"
import { withBlurryOverlay } from "@/components/BlurryOverlay"
import {
  renderNumberWithInversion,
  renderWithCcy,
} from "@/components/CurrencyDisplay"
import { getPnlColor } from "@/components/PnL"
import { PrimaryButton } from "@/components/PrimaryButton"
import { SecondaryButton } from "@/components/SecondaryButton"
import { SlippageInput } from "@/components/Slippage"
import { isTxPending } from "@/utils/isTxPending"
import { mapDistinct } from "@/utils/observable-utils"
import { state, useStateObservable } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { Fragment, Suspense } from "react"
import { exhaustMap, withLatestFrom } from "rxjs"
import { usePositionContext } from "../Position.context"
import { Payment } from "../PositionEdit/Summary/Payment"
import { SummaryRow, SummaryTable } from "../PositionEdit/Summary/SummaryTable"
import { entryPrice$, posDivider, shortRow } from "../PositionView/Shared"
import { DeliveryType, onDeliverOrCancel } from "./state/base"

const testIdPrefix = "position-settlement"
const withTestId = (field: string) => `${testIdPrefix}--${field}`
const [userSubmitSettle$, onUserSubmitSettle] = createSignal()

const submit$ = state((positionId: bigint) =>
  userSubmitSettle$.pipe(
    withLatestFrom(settlementData(positionId)),
    exhaustMap(([, { account, cashSettler, callData }]) =>
      cashSettle(account, cashSettler, positionId, callData),
    ),
  ),
)

const isSubmittingSettlement$ = state(
  (positionId: bigint) => submit$(positionId).pipe(mapDistinct(isTxPending)),
  false,
)

const SettlePositionSummary: React.FC = () => {
  const { id, base, quote, instrument } = usePositionContext()
  const { quantity, decimals } = useStateObservable(getPosition$(id))
  const { price, ...priceProps } = useStateObservable(entryPrice$(id))
  const { closingPrice, rawPnL, incurredFees, pnl, deliveryFee, equity } =
    useStateObservable(expiredPositionData$(id))

  const isLong = instrument.side === "Long"

  return (
    <Fragment key={`settlement-summary-${id.toString()}`}>
      <SummaryTable>
        <SummaryRow title="Position Size">
          {renderWithCcy(quantity, base, decimals)}
        </SummaryRow>
        {posDivider}
        <div>
          <SummaryRow tooltipKey="entryPrice" title="Entry Price:">
            {renderNumberWithInversion(
              price,
              priceProps,
              withTestId("entryPrice"),
            )}
          </SummaryRow>
          {shortRow(renderWithCcy(price, quote, decimals), isLong)}
        </div>
        <div>
          <SummaryRow tooltipKey="exitPrice" title="Exit Price:">
            {renderNumberWithInversion(
              closingPrice,
              instrument,
              withTestId("exitPrice"),
            )}
          </SummaryRow>
          {shortRow(renderWithCcy(closingPrice, quote, decimals), isLong)}
        </div>
        {posDivider}
        <SummaryRow
          tooltipKey="grossPnL"
          title={`Gross ${rawPnL < 0 ? "Loss" : "Profit"}`}
          testId={withTestId("grossPnl")}
        >
          {renderWithCcy(rawPnL, quote, decimals)}
        </SummaryRow>
        <SummaryRow
          tooltipKey="totalFees"
          title="Fees + Closing Fee"
          testId={withTestId("fees")}
        >
          -{renderWithCcy(incurredFees + deliveryFee, quote, decimals)}
        </SummaryRow>
        {posDivider}
        <SummaryRow
          tooltipKey="pnl"
          className={getPnlColor(pnl)}
          title={`Net ${pnl < 0 ? "Loss" : "Profit"}`}
          testId={withTestId("netPnL")}
        >
          {renderWithCcy(pnl, quote, decimals)}
        </SummaryRow>
        {posDivider}
        <SummaryRow
          tooltipKey="slippage"
          title="Slippage Tolerance"
          className="py-5"
        >
          <div className="w-[7rem]">
            <SlippageInput
              slippage$={settlementSlippage$(id)}
              onChange={(e) => onSettlementSlippageChanged(id, e)}
              testIdPrefix={testIdPrefix}
            />
          </div>
        </SummaryRow>
      </SummaryTable>
      <Payment
        amount={-equity}
        currency={quote}
        testId={withTestId("amountReceived")}
      />
    </Fragment>
  )
}

const SubmittingOverlay = withBlurryOverlay(() => {
  const { id } = usePositionContext()
  return useStateObservable(isSubmittingSettlement$(id))
})

export const PositionSettlement: React.FC = () => {
  const { id } = usePositionContext()

  return (
    <Suspense fallback={null}>
      <div className="flex flex-col">
        <SubmittingOverlay>
          <SettlePositionSummary />
          <div className="flex flex-col gap-2 mt-4">
            <PrimaryButton
              className="w-full"
              testId={withTestId("submit")}
              onClick={onUserSubmitSettle}
            >
              Settle
            </PrimaryButton>
            <SecondaryButton
              className="w-full"
              onClick={() => {
                onDeliverOrCancel(id, DeliveryType.none)
              }}
            >
              Cancel
            </SecondaryButton>
          </div>
        </SubmittingOverlay>
      </div>
    </Suspense>
  )
}
