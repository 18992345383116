import { justWait } from "@/utils/observable-utils"
import { state, SUSPENSE } from "@react-rxjs/core"
import { concat, defer, EMPTY, filter, map, Observable, switchMap } from "rxjs"
import { client, eventsProcessed$, newHeads$ } from "../chain/common"

const latestTickingBlock$ = state(
  client.chainId$.pipe(
    switchMap((chainId) =>
      chainId === null
        ? [SUSPENSE as SUSPENSE]
        : concat(
            ["latest" as "latest"],
            eventsProcessed$.pipe(
              filter(({ type }) => type === "post"),
              map((x) => x.blockNumber),
            ),
          ),
    ),
  ),
)
latestTickingBlock$.subscribe()

export const withTicks =
  <A extends Array<any>, T>(
    input: (
      ...args: [...A] | [...A, number | "latest" | "earliest" | "pending"]
    ) => Promise<T>,
  ): ((...args: A) => Observable<T>) =>
  (...args: A) =>
    latestTickingBlock$.pipe(
      switchMap((blockNumber) =>
        blockNumber === SUSPENSE
          ? EMPTY
          : concat(
              justWait(0),
              defer(() => input(...args, blockNumber)),
            ),
      ),
    )

export const withLatest =
  <A extends Array<any>, T>(
    input: (...args: A) => Promise<T>,
  ): ((...args: A) => Observable<T>) =>
  (...args: A) =>
    concat(
      defer(() => input(...args)),
      newHeads$.pipe(switchMap(() => input(...args))),
    )

const getGasPrice = async () =>
  BigInt(await client.request<string>("eth_gasPrice", []))
export const gasPrice$ = state(withLatest(getGasPrice)())
