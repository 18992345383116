import {
  account$,
  Base,
  followTransactionWithoutPermit,
  network$,
  Quote,
  TransactionStatusType,
} from "@/api"
import { approveSpending } from "@/api/chain"
import { ApprovalType, approvalType$ } from "@/App/state/approvals"
import { MAX_256 } from "@/utils/constants"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { exhaustMap, Observable, pluck, withLatestFrom } from "rxjs"
import { instrument$ } from "./instrument"
import { costData$ } from "./price"

const [userSubmitApprovalTx$, onUserSubmitApprovalTx] = createSignal()
export { onUserSubmitApprovalTx }

const followApproveSpending$ = state(
  userSubmitApprovalTx$.pipe(
    withLatestFrom(
      costData$,
      instrument$.pipe(pluck("quote")) as Observable<
        Exclude<Base | Quote, "ETH">
      >,
      account$,
      network$,
      approvalType$,
    ),
    exhaustMap(
      ([
        ,
        { collateralUsed },
        quote,
        account,
        {
          addresses: { contango },
        },
        approvalType,
      ]) => {
        const amountToApprove =
          approvalType === ApprovalType.TransactionExact
            ? collateralUsed
            : MAX_256
        return followTransactionWithoutPermit(
          approveSpending,
          quote,
          account,
          contango,
          amountToApprove,
        )
      },
    ),
  ),
)

export const isSubmittingApproval$ = state(
  followApproveSpending$.pipe(
    mapDistinct(
      ({ type }) =>
        type !== TransactionStatusType.Completed &&
        type !== TransactionStatusType.Rejected,
    ),
  ),
  false,
)
