import {
  accountAndNetwork$,
  liquidations$,
  removedPositions$,
  sent$,
  upsertedPositions$,
} from "@/api"
import { getHistoryItems } from "@/api/graphql/positions"
import { createSignalState } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import {
  catchError,
  combineLatest,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
} from "rxjs"

export const latestTransactionBlock$ = merge(
  liquidations$.pipe(
    map(({ message }) => ({
      source: "liquidations$",
      block: BigInt(message?.blockNumber) ?? 0,
    })),
  ),
  removedPositions$.pipe(
    map(({ message }) => ({
      source: "removedPositions$",
      block: BigInt(message?.blockNumber) ?? 0,
    })),
  ),
  upsertedPositions$.pipe(
    map(({ message }) => ({
      source: "upsertedPositions$",
      block: BigInt(message?.blockNumber) ?? 0,
    })),
  ),
  sent$.pipe(
    map(({ message }) => ({
      source: "sent$",
      block: BigInt(message?.blockNumber) ?? 0,
    })),
  ),
).pipe(shareReplay(1))

export const [filters$, setFilters] = createSignalState({ positionId: "" })

export const filterCount$ = state(
  filters$.pipe(
    map((filters) => Object.values(filters).filter(Boolean).length),
  ),
)

export const transactionHistory$ = state(
  combineLatest([filters$, accountAndNetwork$]).pipe(
    switchMap(([filters, [trader, { chainData }]]) =>
      getHistoryItems(
        { trader, ...filters },
        chainData.chainId,
        latestTransactionBlock$,
      ).pipe(
        catchError((err) => {
          console.error("Failed to load trades", err)
          return of(null)
        }),
      ),
    ),
    shareReplay(1),
  ),
)

export const TransactionHistory$ = merge(transactionHistory$, filterCount$)
