import {
  getEquityPnLData$,
  getPosition$,
  getPositionExpiryStatus$,
  PositionExpiryStatus,
  settlementData,
} from "@/api"
import { useStateObservable } from "@react-rxjs/core"
import { memo } from "react"
import { merge, switchMap } from "rxjs"
import { ActivePositionRow } from "./ActivePosition"
import { PositionContextProvider } from "./Position.context"
import { PositionDialog } from "./PositionDialog"
import {
  PositionClose,
  PositionEdit,
  PositionEdit$,
} from "./PositionEdit/PositionEdit"
import {
  EditType,
  onEditOrCancel,
  positionEditFlow$,
} from "./PositionEdit/state/base"
import { ExpiredPositionSettlement } from "./PositionSettlement/ExpiredPositionSettlement"
import { PositionDelivery$ } from "./PositionSettlement/PositionDelivery"
import {
  DeliveryType,
  onDeliverOrCancel,
  positionDeliveryFlow$,
} from "./PositionSettlement/state/base"
import {
  closingPriceJsx$,
  deliveryCost$,
  entryPriceJsx$,
  equityAndPnl$,
  expiredEquityAndPnl$,
  fees$,
  leverageJsx$,
  liquidationPrice$,
  markPrice$,
  quantityJsx$,
} from "./PositionView/Shared"

export const position$ = (id: bigint) => {
  return getPositionExpiryStatus$(id).pipe(
    switchMap((status) => {
      const common = merge(
        getPosition$(id),
        getPositionExpiryStatus$(id),
        quantityJsx$(id),
        entryPriceJsx$(id),
        leverageJsx$(id),
        markPrice$(id),
        liquidationPrice$(id),
        fees$(id),
      )
      switch (status) {
        case PositionExpiryStatus.Active:
          return merge(
            common,
            equityAndPnl$(id),
            getEquityPnLData$(id),
            closingPriceJsx$(id),
            PositionEdit$(id),
          )
        case PositionExpiryStatus.Expired:
          return merge(
            common,
            deliveryCost$(id),
            expiredEquityAndPnl$(id),
            PositionDelivery$(id),
            settlementData(id),
          )
        default:
          return common
      }
    }),
  )
}

interface ActivePositionProps {
  id: bigint
}

export const ActivePosition: React.FC<ActivePositionProps> = memo(({ id }) => {
  const instrument = useStateObservable(getPosition$(id))
  const status = useStateObservable(getPositionExpiryStatus$(id))
  const isActive = status === PositionExpiryStatus.Active

  return (
    <PositionContextProvider
      value={{
        base: instrument.base,
        quote: instrument.quote,
        id,
        instrument,
      }}
    >
      <ActivePositionRow
        data-position-id={id}
        data-position-instrument={instrument.id}
        status={status}
      >
        {isActive ? <ActiveContent id={id} /> : <ExpiredContent id={id} />}
      </ActivePositionRow>
    </PositionContextProvider>
  )
})

const ActiveContent: React.FC<{ id: bigint }> = ({ id }) => {
  const flowType = useStateObservable(positionEditFlow$(id))

  return (
    <PositionDialog
      onClose={() => onEditOrCancel(id, EditType.none)}
      isOpen={flowType !== EditType.none}
    >
      {flowType === EditType.close ? (
        <PositionClose />
      ) : flowType === EditType.edit ? (
        <PositionEdit />
      ) : null}
    </PositionDialog>
  )
}

const ExpiredContent: React.FC<{ id: bigint }> = ({ id }) => {
  const flowType = useStateObservable(positionDeliveryFlow$(id))

  return (
    <PositionDialog
      onClose={() => onDeliverOrCancel(id, DeliveryType.none)}
      isOpen={flowType !== DeliveryType.none}
    >
      <ExpiredPositionSettlement />
    </PositionDialog>
  )
}
