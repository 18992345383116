import { account$, isGnosisSafe } from "@/api"
import { loading } from "@/components/Loading"
import { Popover, Switch, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { useStateObservable } from "@react-rxjs/core"
import { Fragment } from "react"
import { merge } from "rxjs"
import {
  ApprovalType,
  approvalType$,
  setApprovalType,
  useApprovalType,
} from "../state/approvals"
import { Balances, UserWallet } from "../Wallet"
import { Allowances, Balances$ } from "./Balances"
import { UserIcon } from "./UserWallet"

export const WalletMenu$ = merge(account$, approvalType$, Balances$)
export const WalletMenu: React.FC = () => {
  const account = useStateObservable(account$)
  return (
    <Popover>
      {({ open }) => (
        <Fragment key="wallet-menu-fragment">
          <Popover.Button>
            <div
              data-testid="wallet-menu-button"
              className={`flex text-fontColor-0 items-center bg-backgrounds-200 hover:bg-backgrounds-300 rounded-full h-8 my-2 mr-6 lg:my-0 lg:ml-3 lg:mr-0 p-1 ${
                open === true ? "bg-backgrounds-100" : ""
              }`}
            >
              <div className="h-6 flex font-secondary">
                <UserIcon address={account} diameter={24} />
                <span className="hidden lg:inline-flex text-sm items-center gap-1 px-2">
                  {`${account.slice(0, 5)}...${account.slice(-4)}`}
                </span>
              </div>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            key="user-wallet-menu"
            enter="transition ease-out duration-300"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition ease-in duration-200"
            leaveFrom="transform translate-x-0"
            leaveTo="transform translate-x-full"
          >
            <Popover.Panel className="overflow-y-auto bg-backgrounds-200 origin-top-right fixed z-10 right-0 top-0 w-screen sm:max-w-sm h-screen text-secondary-01 drop-shadow-overlay focus:outline-none">
              <Popover.Button
                data-testid="wallet-menu-close-button"
                className="absolute top-0 right-0"
              >
                <XIcon className="h-5 w-5 m-6 my-[22px]" />
              </Popover.Button>
              <div className="bg-backgrounds-0 p-4 h-16">
                <UserWallet />
              </div>
              <div className="p-6 flex flex-col gap-4 divide-y divide-backgrounds-400">
                <div>
                  <div className="pb-3 text-base">Balances</div>
                  <div className="flex flex-col content-start items-start justify-start justify-items-start align-start space-y-2">
                    <Balances fallback={loading} />
                  </div>
                </div>
                <div className="pt-4">
                  <div className="pb-3 text-base">Allowances</div>
                  <div className="flex flex-col content-start items-start justify-start justify-items-start align-start space-y-2">
                    <Allowances />
                  </div>
                </div>
                <div className="pt-4">
                  <ApprovalSettings />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Fragment>
      )}
    </Popover>
  )
}

const ApprovalSettings: React.FC = () => {
  const approvalType = useApprovalType()
  const transactionsEnabled = approvalType !== ApprovalType.Permit
  const maxEnabled = approvalType === ApprovalType.TransactionInfinite

  const className1 = (bool: boolean) =>
    `${
      bool ? "bg-accents-500" : "bg-backgrounds-400"
    } relative inline-flex h-6 w-11 items-center rounded-full`

  const className2 = (bool: boolean) =>
    `${
      bool ? "translate-x-6" : "translate-x-1"
    } inline-block h-4 w-4 transform rounded-full bg-white transition`

  return (
    <div className="flex flex-col gap-3">
      <div className="text-base">Preferences</div>
      {isGnosisSafe() ? null : (
        <div className="flex justify-between">
          <span className="text-sm">Use Approval by Transactions</span>
          <Switch
            data-testid="approval-switch"
            checked={transactionsEnabled}
            onChange={(isChecked: boolean) =>
              setApprovalType(
                isChecked ? ApprovalType.TransactionExact : ApprovalType.Permit,
              )
            }
            className={className1(transactionsEnabled)}
          >
            <span className={className2(transactionsEnabled)} />
          </Switch>
        </div>
      )}
      {transactionsEnabled ? (
        <div className="flex justify-between">
          <span className="text-sm">Approve Max</span>
          <Switch
            data-testid="approve-max-switch"
            checked={maxEnabled}
            onChange={(isChecked: boolean) =>
              setApprovalType(
                isChecked
                  ? ApprovalType.TransactionInfinite
                  : ApprovalType.TransactionExact,
              )
            }
            className={className1(maxEnabled)}
          >
            <span className={className2(maxEnabled)} />
          </Switch>
        </div>
      ) : null}
    </div>
  )
}
