import { mapResponses } from "@/utils/mappers"
import { sinkSuspense, state } from "@react-rxjs/core"
import { combineLatest, map } from "rxjs"
import { getModifyPositionCostByLeverage$ } from "../chain"
import { getValidFees } from "../fees"
import { collateralSlippage } from "../utils"

export const getModifyCostByLeverage$ = state(
  (positionId: bigint, quantity: bigint, leverage: number) => {
    const uniswapFees = getValidFees()
    return combineLatest(
      uniswapFees.map((uniswapFee) =>
        getModifyPositionCostByLeverage$(
          {
            positionId,
            quantity,
            collateralSlippage,
            uniswapFee,
          },
          BigInt(leverage * 1e18),
        ).pipe(
          map((res) => {
            if (res.ok) return { ...res.result, uniswapFee }
            throw res.error
          }),
          sinkSuspense(),
        ),
      ),
    ).pipe(mapResponses("getModifyPositionCostByLeverage$"))
  },
)
