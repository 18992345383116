import { maxConcurrent } from "@/utils/maxConcurrent"
import { mapDistinct, throwErrors } from "@/utils/observable-utils"
import { addTrace } from "@/utils/traces"
import { withRetries } from "@/utils/withRetries"
import { SafeAppProvider } from "@gnosis.pm/safe-apps-provider"
import SafeAppsSDK from "@gnosis.pm/safe-apps-sdk"
import type { JsonRpcProvider } from "@json-rpc-tools/provider"
import Provider from "@json-rpc-tools/provider"
import detectEthereumProvider from "@metamask/detect-provider"
import { sinkSuspense, state, SUSPENSE } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import { createPullClient } from "@unstoppablejs/solidity-bindings"
import ethProvider from "eth-provider"
import {
  catchError,
  combineLatest,
  concat,
  concatMap,
  exhaustMap,
  filter,
  map,
  merge,
  Observable,
  of,
  switchMap,
  take,
  takeWhile,
  withLatestFrom,
} from "rxjs"
import * as AllErrors from "../chain/contracts/all-errors"
import { ChainId, Network } from "../types"
import { WalletError, WalletErrorType } from "./WalletError"

const fromEvent = (provider: JsonRpcProvider, event: string) =>
  new Observable((observer) => {
    const next = observer.next.bind(observer)
    provider.on(event, next)

    return () => {
      provider.removeListener(event, next)
    }
  })

const walletError = (type: WalletErrorType) => new WalletError(type)

export const isGnosisSafe = () => window.parent !== window

// For the following methods we may use an external service
const externalMethods = new Set<string>([
  "eth_blockNumber",
  "eth_getBlockByNumber",
  "eth_getLogs",
  "eth_call",
  "eth_getBalance",
  "eth_gasPrice",
])

const withRpcUri = (
  walletProvider: JsonRpcProvider,
  targets: string | string[],
) => {
  const rpc = ethProvider(targets)

  const originalRequest = rpc.request.bind(rpc)
  const withRetriesRequest = maxConcurrent(
    withRetries(
      originalRequest,
      (error, nTries) => {
        if (nTries === 6) throw error
        return nTries * 250
      },
      true,
    ),
    4,
  )

  const originalWalletRequest = walletProvider.request.bind(walletProvider)

  const enhancedRequest: typeof originalRequest = (input) => {
    return externalMethods.has(input.method)
      ? withRetriesRequest(input)
      : originalWalletRequest(input)
  }

  ;(walletProvider as any).request = enhancedRequest

  return walletProvider
}

const isCi = window.location.href.includes("localhost:4173")
export const client = createPullClient(
  async () => {
    if ((window as any).Cypress) {
      const provider = ethProvider([
        "ws://localhost:8546",
        "http://localhost:8546",
      ]) as any

      const originalRequest = provider.request.bind(provider)

      provider.request = async ({
        method,
        params,
      }: {
        method: string
        params: any
      }) => {
        if (method === "eth_accounts") {
          return await fetch("http://myfakedomain.com/accounts")
            .then((res) => res.json())
            .catch(() => ["0x00000000000000000000000000000188e855c635"])
        }
        if (method === "eth_signTypedData_v4") {
          return await (window as any).signTypedDataV4(params[1])
        }
        return await originalRequest({ method, params })
      }

      return provider
    }
    let result: Provider
    if (isGnosisSafe()) {
      const sdk = new SafeAppsSDK()
      result = new SafeAppProvider(await sdk.safe.getInfo(), sdk) as any
    } else {
      result = (await detectEthereumProvider()) as any
    }

    return window.location.href.includes("contango.xyz")
      ? withRpcUri(result, [
          "https://arbitrum-one.chainnodes.org/d27f689b-6e0f-4cec-b766-acf2a2edca1b",
        ])
      : result
  },
  Object.values(AllErrors),
  (msg) => {
    console.debug(msg)
    addTrace(msg)
  },
  isCi ? 500 : 5000,
  isCi ? 250 : 2500,
)
client.chainId$.subscribe()

const providerOrError$ = state(
  concat(client.providerPromise).pipe(
    catchError(() => of(walletError(WalletErrorType.noProvider))),
    map((provider) => {
      if (provider instanceof Error) return provider
      return provider
    }),
  ),
)

export const networkOrError$ = providerOrError$.pipeState(
  take(1),
  concatMap((p) => (p instanceof Error ? of(p) : client.chainId$)),
  map((chainId) => {
    if (chainId instanceof Error) return chainId

    if (chainId === null) return walletError(WalletErrorType.disconnected)
    return (
      Network[chainId as ChainId] ??
      walletError(WalletErrorType.unsupportedNetwork)
    )
  }),
)

networkOrError$.subscribe()

export const network$ = state(networkOrError$.pipe(throwErrors()))

const [requestAccounts$, onRequestAccounts] = createSignal()
const userRequestedAccounts$ = state<{
  requesting: boolean
  accounts?: string[]
}>(
  requestAccounts$.pipe(
    withLatestFrom(client.providerPromise),
    exhaustMap(([, provider]) => {
      return concat(
        [{ requesting: true }],
        provider
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            return { requesting: false, accounts }
          })
          .catch(() => ({ requesting: false })),
      )
    }),
  ),
  { requesting: false },
)
export { onRequestAccounts, userRequestedAccounts$ }

const userProvidedAccounts = userRequestedAccounts$.pipe(
  filter(({ accounts }) => !!accounts?.length),
  map(({ accounts }) => accounts!),
)

export const accountOrError$ = providerOrError$.pipeState(
  takeWhile((p) => p instanceof Error, true),
  concatMap((p) => {
    if (p instanceof Error) return of(p)
    return concat(
      p.request({ method: "eth_accounts" }),
      merge(userProvidedAccounts, fromEvent(p, "accountsChanged")),
    ).pipe(
      mapDistinct((accounts: Array<string>) =>
        accounts.length > 0
          ? accounts[0]
          : walletError(WalletErrorType.noAccounts),
      ),
    )
  }),
)

accountOrError$.subscribe()

export const account$ = state(accountOrError$.pipe(throwErrors()))

export const ensureAccountAndNetwork = <T>(
  project: (account: string, network: Network) => Observable<T>,
): Observable<T> =>
  combineLatest([accountOrError$, networkOrError$]).pipe(
    switchMap(([account, network]) =>
      account instanceof Error || network instanceof Error
        ? []
        : combineLatest([account$, network$]).pipe(
            switchMap(([account, network]) => project(account, network)),
          ),
    ),
  )

export const walletError$ = combineLatest([
  accountOrError$,
  networkOrError$,
]).pipe(
  mapDistinct(([account, network]) => {
    const accountType = account instanceof Error ? account.type ?? 0 : Infinity
    const networkType = network instanceof Error ? network.type ?? 0 : Infinity
    if (accountType === Infinity && networkType === Infinity) return null
    return (accountType <= networkType ? account : network) as WalletError
  }),
)

export const accountAndNetwork$ = state(
  combineLatest([account$, network$]).pipe(
    catchError(() => of(SUSPENSE)),
    sinkSuspense(),
  ),
)
