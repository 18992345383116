import { classNames } from "@/utils/classNames"
import React from "react"

export const PositionList: React.FC<{
  tableHeader: React.ReactNode
  children: React.ReactNode
  className?: string
}> = ({ tableHeader, children, className }) => {
  return (
    <div
      className={classNames(
        "max-h-[500px] md:max-h-full mx-auto md:mx-0 overflow-x-auto w-full bg-backgrounds-200 rounded-lg",
        className,
      )}
    >
      <div className="inline-block w-full">
        <table className="min-w-full text-right text-sm 2xl:text-base">
          <thead className="font-primary font-normal text-fontColor-500">
            <tr className="py-2 bg-backgrounds-200 sticky top-0 z-[1] shadow-[0_2px_0px_0px_rgb(58,62,85)]">
              {tableHeader}
            </tr>
          </thead>
          <tbody className="font-secondary font-normal text-white divide-y divide-backgrounds-300">
            {children}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const TableHeader: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <th
      scope="col"
      className={classNames(
        "whitespace-nowrap 2xl:px-6 px-4 py-2 text-sm font-normal bg-inherit border-0 first:pl-4 last:pr-4",
        className,
      )}
    >
      {children}
    </th>
  )
}

export const PositionRow: React.FC<{
  children: React.ReactNode
  testId?: string
}> = ({ children, testId }) => {
  return (
    <tr
      className="bg-backgrounds-200 font-primary text-sm font-normal hover:bg-backgrounds-100 text-right h-10 relative"
      data-testid={testId ?? "position"}
    >
      {children}
    </tr>
  )
}

export const PositionCell: React.FC<{
  className?: string
  children: React.ReactNode
  testId?: string
}> = ({ children, className, testId }) => {
  return (
    <td
      data-testid={testId}
      className={classNames(
        "2xl:whitespace-nowrap py-2 pr-2 2xl:px-6 px-4 bg-inherit first:pl-4 last:pr-4",
        className,
      )}
    >
      {children}
    </td>
  )
}
