import {
  getEquityPnLData$,
  getPositionClosingPrice$,
  getPositionProp$,
} from "@/api"
import {
  CurrencyDisplay,
  renderNumberWithInversion,
  renderWithCcy,
} from "@/components/CurrencyDisplay"
import { getPnlColor } from "@/components/PnL"
import { SlippageInput } from "@/components/Slippage"
import { absolute } from "@/utils/maths-utils"
import { state, Subscribe, useStateObservable } from "@react-rxjs/core"
import { map, merge, withLatestFrom } from "rxjs"
import { usePositionContext } from "../../Position.context"
import {
  entryPrice$,
  entryPriceJsx$,
  posDivider,
  shortRow,
} from "../../PositionView/Shared"
import { onSlippageChanged, slippage$ } from "../state/inputs"
import { Payment } from "./Payment"
import { SummaryRow, SummaryTable } from "./SummaryTable"

const fee$ = state((id: bigint) =>
  getEquityPnLData$(id).pipe(
    withLatestFrom(getPositionProp$(id, "quote")),
    map(([{ estimatedFeeOfClosing }, quote]) => (
      <CurrencyDisplay value={estimatedFeeOfClosing} currencyId={quote} />
    )),
  ),
)

export const ClosingPositionSummary$ = state((id: bigint) =>
  merge(
    entryPriceJsx$(id),
    getEquityPnLData$(id),
    slippage$(id),
    fee$(id),
    getPositionProp$(id, "quantity"),
    getPositionClosingPrice$(id),
    entryPrice$(id),
  ),
)

const testId = (str: string) => `closing-summary--${str}`

export const ClosingPositionSummary: React.FC = () => {
  const {
    id,
    quote,
    base,
    instrument: { decimals, side },
  } = usePositionContext()
  const { equity, rawPnL, pnl, incurredFees, estimatedFeeOfClosing } =
    useStateObservable(getEquityPnLData$(id))
  const quantity = useStateObservable(getPositionProp$(id, "quantity"))
  const { closingPrice, ...closingPriceProps } = useStateObservable(
    getPositionClosingPrice$(id),
  )
  const { price, ...priceProps } = useStateObservable(entryPrice$(id))

  const isLong = side === "Long"

  return (
    <Subscribe>
      <SummaryTable>
        <SummaryRow testId={testId("size")} title="Position Size">
          <CurrencyDisplay
            formatOptions={{ padToDecimals: false }}
            value={quantity}
            currencyId={base}
          />
        </SummaryRow>
        {posDivider}
        <div>
          <SummaryRow
            testId={testId("entry-price")}
            tooltipKey="entryPrice"
            title="Entry Price"
          >
            {renderNumberWithInversion(price, priceProps)}
          </SummaryRow>
          {shortRow(renderWithCcy(price, quote, decimals), isLong)}
        </div>
        <div>
          <SummaryRow
            testId={testId("exit-price")}
            tooltipKey="exitPrice"
            title="Exit Price"
          >
            {renderNumberWithInversion(closingPrice, closingPriceProps)}
          </SummaryRow>
          {shortRow(renderWithCcy(closingPrice, quote, decimals), isLong)}
        </div>
        {posDivider}
        <SummaryRow
          testId={testId("gross-pnl")}
          tooltipKey="grossPnL"
          title={rawPnL < 0 ? "Loss" : "Profit"}
        >
          {renderWithCcy(absolute(rawPnL), quote)}
        </SummaryRow>
        <SummaryRow
          testId={testId("fees")}
          tooltipKey="fees"
          title="Fees + Closing Fee"
        >
          {renderWithCcy(incurredFees + estimatedFeeOfClosing, quote)}
        </SummaryRow>
        {posDivider}
        <SummaryRow
          testId={testId("pnl")}
          tooltipKey="pnl"
          className={getPnlColor(pnl)}
          title={`Net ${pnl < 0 ? "Loss" : "Profit"}`}
        >
          {renderWithCcy(absolute(pnl), quote)}
        </SummaryRow>
        {posDivider}
        <SummaryRow
          tooltipKey="slippage"
          title="Slippage Tolerance"
          className="py-5"
        >
          <div className="w-[7rem]">
            <SlippageInput
              slippage$={slippage$(id)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSlippageChanged(id, event)
              }
              testIdPrefix="close-position-slippage"
            />
          </div>
        </SummaryRow>
      </SummaryTable>
      <Payment
        amount={-equity}
        currency={quote}
        testId="position-close--amount-to-receive"
      />
    </Subscribe>
  )
}
