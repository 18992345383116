import { account$ } from "@/api"
import { classNames } from "@/utils/classNames"
import { ClipboardIcon } from "@heroicons/react/outline"
import { useStateObservable } from "@react-rxjs/core"
import Jazzicon, { jsNumberForAddress } from "react-jazzicon"

export const UserWallet: React.FC = () => {
  const account = useStateObservable(account$)

  return (
    <span className="h-full flex flex-row items-center px-3 py-0.5 text-sm font-medium font-secondary text-secondary-01">
      <UserIcon address={account} diameter={26} />
      <span
        onClick={() => {
          navigator.clipboard.writeText(account)
        }}
        className="inline-flex items-center text-base gap-1 hover:text-accents-500 ml-2 cursor-pointer"
      >
        {`${account.slice(0, 5)}...${account.slice(-4)}`}
        <ClipboardIcon className="h-4 w-4 hover:text-accents-500" />
      </span>
    </span>
  )
}

export const UserIcon: React.FC<{ address?: string; diameter?: number }> = ({
  address,
  diameter,
}) => {
  return address ? (
    <Jazzicon diameter={diameter} seed={jsNumberForAddress(address)} />
  ) : (
    <span
      className={classNames(
        "rounded-full border overflow-hidden",
        diameter ? "h-[" + diameter + "px]" : "h-full",
      )}
    >
      <svg className="h-full w-auto" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  )
}
