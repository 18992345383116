import { getPosition$ } from "@/api"
import { CurrencyInput } from "@/components/CurrencyInput"
import { FormMessage } from "@/components/messaging/FormMessage"
import { IconType } from "@/components/NotificationIcon"
import { formatKnownCurrency } from "@/utils/currency-utils"
import { useStateObservable } from "@react-rxjs/core"
import { Fragment } from "react"
import { merge } from "rxjs"
import { usePositionContext } from "../Position.context"
import { onQuantityChanged } from "./state/inputs"
import { quantityDelta$, quantityEdit$ } from "./state/quantity"
import { isMinQuantityValidationMet$, minQuantity$ } from "./state/validations"

export const Quantity$ = (id: bigint) =>
  merge(
    quantityEdit$(id),
    quantityDelta$(id),
    minQuantity$(id),
    getPosition$(id),
    isMinQuantityValidationMet$(id),
  )

export const Quantity: React.FC = () => {
  const { base, id } = usePositionContext()
  const quantityEdit = useStateObservable(quantityEdit$(id))

  return (
    <Fragment key={`quantity-fragment-${id.toString()}`}>
      <CurrencyInput
        htmlFor="quantity"
        label="New Position Size"
        value={quantityEdit}
        onFocus={(e) => {
          e.target.select()
        }}
        onChange={(e) => onQuantityChanged(id, e)}
        currency={base}
        testIdPrefix="position-edit-quantity"
      />
      <MinQuantityWarning id={id} />
    </Fragment>
  )
}

const MinQuantityWarning: React.FC<{ id: bigint }> = ({ id }) => {
  const { base } = usePositionContext()

  const minQuantity = useStateObservable(minQuantity$(id))
  const isValid = useStateObservable(isMinQuantityValidationMet$(id))

  return (
    <FormMessage
      iconType={IconType.Error}
      className="max-w-md mt-3"
      testId="edit-position--min-quantity-warning"
      visible={!isValid}
    >
      <div>{`Below min quantity: ${formatKnownCurrency(
        minQuantity,
        base,
      )}. Please increase quantity`}</div>
    </FormMessage>
  )
}
