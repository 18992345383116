import { divideCurrency } from "@/utils/currency-utils"
import { onUncaughtError } from "@/utils/error-utils"
import {
  calculateMarginAndLeverage,
  calculatePnlPercentage,
} from "@/utils/financial-utils"
import { justWait, mapDistinct } from "@/utils/observable-utils"
import { ObservableType } from "@/utils/types"
import { state } from "@react-rxjs/core"
import { createKeyedSignal, partitionByKey } from "@react-rxjs/utils"
import {
  catchError,
  combineLatest,
  concat,
  defer,
  filter,
  map,
  merge,
  pipe,
  race,
  scan,
  share,
  startWith,
  switchMap,
  timer,
} from "rxjs"
import {
  getDeliveryCostForPosition,
  getPosition,
  getPositionStatus,
  positionLiquidatedEvent,
  positionUpsertedEvent,
  RawPosition,
} from "../chain"
import { latestBlockTimestamp$ } from "../chain/common"
import { getValidFees } from "../fees"
import { Instrument, Maturity } from "../generated"
import { InstrumentId, PositionExpiryStatus } from "../types"
import { bytes32strMapper, withTicks } from "../utils"
import { account$, network$ } from "../wallet"
import { getModifyCostByLeverage$ } from "./editPosition"

export type PositionsErrorOrigin =
  | "getPosition"
  | "positionStatus"
  | "getModifyCost"
  | "settlementQuery"
  | "getDeliveryCost"

export const [errors$, onPositionError] = createKeyedSignal(
  ({ positionId }) => positionId,
  (positionId: bigint, origin: PositionsErrorOrigin, error?: any) => {
    if (error)
      console.error(
        "Uncaught error for positionId: ",
        positionId,
        origin,
        error,
      )
    return { positionId, origin, error }
  },
)

export const positionErrors$ = state((id: bigint) => errors$(id))

export const upsertedPositions$ = account$.pipe(
  switchMap((trader) => positionUpsertedEvent({ trader })),
  share(),
)

const [getPositionUpsertions$] = partitionByKey(
  upsertedPositions$,
  (e) => e.filters.positionId,
  pipe(
    map(({ data: { totalFees, ...payload }, filters }) => ({
      ...payload,
      symbol: bytes32strMapper(filters.symbol),
      fees: totalFees,
    })),
  ),
)

export const liquidations$ = account$.pipe(
  switchMap((trader) => positionLiquidatedEvent({ trader })),
  share(),
)

export const getPosition$ = state((positionId: bigint) => {
  const onError = onUncaughtError((error) =>
    onPositionError(positionId, "getPosition", error),
  )
  const positionLiquidations$ = liquidations$.pipe(
    filter(({ filters: { positionId: id } }) => id === positionId),
    map(({ data }) => data),
  )

  const pullInitialPosition$ = concat(
    justWait(0),
    defer(() =>
      getPosition(positionId).then(({ protocolFees, ...payload }) => ({
        ...payload,
        fees: protocolFees,
      })),
    ),
  ).pipe(
    catchError((_, caught$) => {
      console.log(
        "getPosition() returned an empty struct. Retrying in 3 seconds...",
      )
      return timer(3000).pipe(switchMap(() => caught$))
    }),
  )
  // if getPosition() is called with an id that doesn't contain a storage mapping yet,
  // it returns an empty struct (default solidity behavior). That's why we need the filter here.

  const upserts$ = getPositionUpsertions$(positionId)

  return race(pullInitialPosition$, upserts$).pipe(
    switchMap((initialPosition) =>
      merge(upserts$, positionLiquidations$).pipe(startWith(initialPosition)),
    ),
    scan(
      (acc, payload) => ({ ...acc, ...payload }),
      {} as Omit<RawPosition, "protocolFees"> & { fees: bigint },
    ),
    map((position) => {
      const instrumentId = position.symbol as InstrumentId
      return {
        positionId,
        instrumentId,
        quantity: position.openQuantity,
        collateral: position.collateral,
        openCost: position.openCost * -1n,
        fees: position.fees,
        ...Instrument[instrumentId],
      }
    }),
    onError(),
  )
})

export const getDeliveryCostForPosition$ = state((positionId: bigint) => {
  const onError = onUncaughtError((error) =>
    onPositionError(positionId, "getDeliveryCost", error),
  )
  return withTicks(getDeliveryCostForPosition)(positionId).pipe(onError())
})

export const getPositionProp$ = state(
  <K extends keyof ObservableType<ReturnType<typeof getPosition$>>>(
    positionId: bigint,
    key: K,
  ) => getPosition$(positionId).pipe(mapDistinct((position) => position[key])),
)

const tickingPositionStatus$ = withTicks(getPositionStatus)

export const getPositionStatus$ = state((positionId: bigint) => {
  const onError = onUncaughtError((error) =>
    onPositionError(positionId, "positionStatus", error),
  )
  return tickingPositionStatus$(positionId, getValidFees()[0])
    .pipe(
      mapDistinct((status) => ({
        ...status,
        ...calculateMarginAndLeverage(status),
      })),
    )
    .pipe(onError())
})

export const getPositionExpiryStatus$ = state((id: bigint) =>
  combineLatest([
    latestBlockTimestamp$,
    getPositionProp$(id, "maturity").pipe(
      map((maturityId) => Maturity[maturityId].timestamp),
    ),
    network$,
  ]).pipe(
    mapDistinct(([timestampOfLatestBlock, maturity, { expiryBuffer }]) => {
      const timeToMaturity = maturity - timestampOfLatestBlock
      let status: PositionExpiryStatus = PositionExpiryStatus.Active
      if (timeToMaturity <= expiryBuffer) {
        status = PositionExpiryStatus.Expiring
      }
      if (timeToMaturity <= 0) {
        status = PositionExpiryStatus.Expired
      }
      return status
    }),
  ),
)

export const getPositionClosingCostData$ = state((positionId: bigint) => {
  const onError = onUncaughtError((error) =>
    onPositionError(positionId, "getModifyCost", error),
  )
  return getPositionProp$(positionId, "quantity").pipe(
    switchMap((quantity) => {
      return getModifyCostByLeverage$(positionId, -quantity, 0).pipe(onError())
    }),
  )
})

export const getPositionClosingPrice$ = state((positionId: bigint) => {
  const closingCost$ = getPositionClosingCostData$(positionId)
  const position$ = getPosition$(positionId)

  return combineLatest([closingCost$, position$]).pipe(
    map(([{ cost }, instrument]) => {
      const { quantity, base, quote } = instrument
      const closingPrice = divideCurrency(
        { value: cost, currency: quote },
        { value: quantity, currency: base },
      )
      return { closingPrice, ...instrument }
    }),
  )
})

export const getEquityPnLData$ = state((positionId: bigint) =>
  combineLatest([
    getPosition$(positionId),
    getPositionClosingCostData$(positionId),
  ]).pipe(
    map(([{ openCost, fees, collateral }, { cost, fee }]) => {
      const rawPnL = openCost + cost
      const pnl = rawPnL - (fees + fee)
      const equity = collateral + rawPnL - fee
      const pnlPercentage = calculatePnlPercentage(openCost, pnl)
      return {
        rawPnL,
        pnl,
        equity,
        pnlPercentage,
        incurredFees: fees,
        estimatedFeeOfClosing: fee,
      }
    }),
  ),
)
