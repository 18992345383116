import { solidityEvent } from "@unstoppablejs/solidity-bindings";
import { bytes32,address,uint,Struct,int } from "solidity-codecs";

const a = {symbol: bytes32, trader: address, positionId: uint};
const b = Struct({closedQuantity: uint,closedCost: uint,collateral: int,totalFees: uint,txFees: uint,realisedPnL: int});
const c = Struct({to: address,deliveredQuantity: uint,deliveryCost: uint,totalFees: uint});
const d = Struct({openQuantity: uint,openCost: uint,collateral: int,realisedPnL: int});
const e = Struct({openQuantity: uint,openCost: uint,collateral: int,totalFees: uint,txFees: uint,realisedPnL: int});



export const PositionClosed = solidityEvent(a, b, "PositionClosed");
export const PositionDelivered = solidityEvent(a, c, "PositionDelivered");
export const PositionLiquidated = solidityEvent(a, d, "PositionLiquidated");
export const PositionUpserted = solidityEvent(a, e, "PositionUpserted");




export interface ExecutionProcessorLib {
  functions: never,
  events: typeof PositionClosed | typeof PositionDelivered | typeof PositionLiquidated | typeof PositionUpserted,
  errors: never
}