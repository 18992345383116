type IdentityRecord<K extends keyof any> = {
  [P in K]: P
}

export const CurrencyId = {
  ETH: "ETH",
  DAI: "DAI",
  USDC: "USDC",
  USDT: "USDT",
} as const
export type CurrencyId = typeof CurrencyId[keyof typeof CurrencyId]

export type UniswapFee = bigint
export const UniswapFees = {
  LOWEST: 100n,
  LOW: 500n,
  MEDIUM: 3000n,
  HIGH: 10000n,
}
export type UniswapFees = typeof UniswapFees[keyof typeof UniswapFees]

export const Currency = {
  ETH: {
    id: CurrencyId.ETH,
    name: "ETH",
    precision: 18,
    precisionMultiplier: 10n ** 18n,
    displayDecimals: 6,
  },
  DAI: {
    id: CurrencyId.DAI,
    name: "Dai Stablecoin",
    precision: 18,
    precisionMultiplier: 10n ** 18n,
    displayDecimals: 2,
  },
  USDC: {
    id: CurrencyId.USDC,
    name: "USD Coin",
    precision: 6,
    precisionMultiplier: 10n ** 6n,
    displayDecimals: 2,
  },
  USDT: {
    id: CurrencyId.USDT,
    name: "Tether USD",
    precision: 6,
    precisionMultiplier: 10n ** 6n,
    displayDecimals: 2,
  },
} as const
export type Currency = typeof Currency[keyof typeof Currency]

export const MaturityId = {
  "301222": "301222",
  "310323": "310323",
  "300623": "300623",
  "290923": "290923",
  "291223": "291223",
} as const
export type MaturityId = typeof MaturityId[keyof typeof MaturityId]

export const Maturity = {
  "301222": {
    id: MaturityId["301222"],
    timestamp: 1672412400,
  },
  "310323": {
    id: MaturityId["310323"],
    timestamp: 1680274800,
  },
  "300623": {
    id: MaturityId["300623"],
    timestamp: 1688137200,
  },
  "290923": {
    id: MaturityId["290923"],
    timestamp: 1695999600,
  },
  "291223": {
    id: MaturityId["291223"],
    timestamp: 1703862000,
  },
} as const
export type Maturity = typeof Maturity[keyof typeof Maturity]

export const LiquidityId = {
  Y: "Y",
} as const
export type LiquidityId = typeof LiquidityId[keyof typeof LiquidityId]

export const Liquidity = {
  Y: {
    id: LiquidityId.Y,
    displayName: "Yield Protocol",
  },
} as const
export type Liquidity = typeof Liquidity[keyof typeof Liquidity]

export const InstrumentId = {
  yETHUSDC2212: "yETHUSDC2212",
  yETHDAI2212: "yETHDAI2212",
  yUSDCETH2212: "yUSDCETH2212",
  yDAIETH2212: "yDAIETH2212",
  yUSDCDAI2212: "yUSDCDAI2212",
  yDAIUSDC2212: "yDAIUSDC2212",
  yETHUSDC2303: "yETHUSDC2303",
  yETHDAI2303: "yETHDAI2303",
  yUSDCETH2303: "yUSDCETH2303",
  yDAIETH2303: "yDAIETH2303",
  yUSDCDAI2303: "yUSDCDAI2303",
  yDAIUSDC2303: "yDAIUSDC2303",
  yETHUSDC2306: "yETHUSDC2306",
  yETHDAI2306: "yETHDAI2306",
  yUSDCETH2306: "yUSDCETH2306",
  yDAIETH2306: "yDAIETH2306",
  yUSDCDAI2306: "yUSDCDAI2306",
  yDAIUSDC2306: "yDAIUSDC2306",
  yETHUSDT2306: "yETHUSDT2306",
  yDAIUSDT2306: "yDAIUSDT2306",
  yUSDCUSDT2306: "yUSDCUSDT2306",

  // September 2023
  yDAIETH2309: "yDAIETH2309",
  yDAIUSDC2309: "yDAIUSDC2309",
  yDAIUSDT2309: "yDAIUSDT2309",
  yETHDAI2309: "yETHDAI2309",
  yETHUSDC2309: "yETHUSDC2309",
  yETHUSDT2309: "yETHUSDT2309",
  yUSDCDAI2309: "yUSDCDAI2309",
  yUSDCETH2309: "yUSDCETH2309",
  yUSDCUSDT2309: "yUSDCUSDT2309",

  // December 2023
  yDAIETH2312: "yDAIETH2312",
  yDAIUSDC2312: "yDAIUSDC2312",
  yDAIUSDT2312: "yDAIUSDT2312",
  yETHDAI2312: "yETHDAI2312",
  yETHUSDC2312: "yETHUSDC2312",
  yETHUSDT2312: "yETHUSDT2312",
  yUSDCDAI2312: "yUSDCDAI2312",
  yUSDCETH2312: "yUSDCETH2312",
  yUSDCUSDT2312: "yUSDCUSDT2312",
} as const
export type InstrumentId = typeof InstrumentId[keyof typeof InstrumentId]

export const Side = {
  Long: "Long",
  Short: "Short",
} as const
export type Side = typeof Side[keyof typeof Side]

const StableConfig = {
  decimals: 4,
  ltvRatio: 97.0,
}

const PairConfig = {
  ETHUSDC: {
    base: CurrencyId.ETH,
    quote: CurrencyId.USDC,
    liquiditySource: LiquidityId.Y,
    decimals: undefined,
    ltvRatio: 82.5,
    side: Side.Long,
  },
  USDCETH: {
    base: CurrencyId.USDC,
    quote: CurrencyId.ETH,
    liquiditySource: LiquidityId.Y,
    decimals: undefined,
    ltvRatio: 81.0,
    side: Side.Short,
  },
  ETHDAI: {
    base: CurrencyId.ETH,
    quote: CurrencyId.DAI,
    liquiditySource: LiquidityId.Y,
    decimals: undefined,
    ltvRatio: 82.5,
    side: Side.Long,
  },
  DAIETH: {
    base: CurrencyId.DAI,
    quote: CurrencyId.ETH,
    liquiditySource: LiquidityId.Y,
    decimals: undefined,
    ltvRatio: 77.0,
    side: Side.Short,
  },
  DAIUSDC: {
    base: CurrencyId.DAI,
    quote: CurrencyId.USDC,
    liquiditySource: LiquidityId.Y,
    side: Side.Long,
    ...StableConfig,
  },
  USDCDAI: {
    base: CurrencyId.USDC,
    quote: CurrencyId.DAI,
    liquiditySource: LiquidityId.Y,
    side: Side.Short,
    ...StableConfig,
  },
  ETHUSDT: {
    base: CurrencyId.ETH,
    quote: CurrencyId.USDT,
    liquiditySource: LiquidityId.Y,
    decimals: undefined,
    ltvRatio: 82.5,
    side: Side.Long,
  },
  USDCUSDT: {
    base: CurrencyId.USDC,
    quote: CurrencyId.USDT,
    liquiditySource: LiquidityId.Y,
    side: Side.Long,
    ...StableConfig,
  },
  DAIUSDT: {
    base: CurrencyId.DAI,
    quote: CurrencyId.USDT,
    liquiditySource: LiquidityId.Y,
    side: Side.Long,
    ...StableConfig,
  },
}

export const Instrument = {
  // ETHUSDC 2212
  yETHUSDC2212: {
    id: InstrumentId.yETHUSDC2212,
    maturity: MaturityId["301222"],
    ...PairConfig.ETHUSDC,
  } as const,
  yUSDCETH2212: {
    id: InstrumentId.yUSDCETH2212,
    maturity: MaturityId["301222"],
    ...PairConfig.USDCETH,
  } as const,
  // ETHDAI 2212
  yETHDAI2212: {
    id: InstrumentId.yETHDAI2212,
    maturity: MaturityId["301222"],
    ...PairConfig.ETHDAI,
  } as const,
  yDAIETH2212: {
    id: InstrumentId.yDAIETH2212,
    maturity: MaturityId["301222"],
    ...PairConfig.DAIETH,
  } as const,
  // DAIUSDC 2212
  yDAIUSDC2212: {
    id: InstrumentId.yDAIUSDC2212,
    maturity: MaturityId["301222"],
    ...PairConfig.DAIUSDC,
  } as const,
  yUSDCDAI2212: {
    id: InstrumentId.yUSDCDAI2212,
    maturity: MaturityId["301222"],
    ...PairConfig.USDCDAI,
  } as const,
  // ETHUSDC 2303
  yETHUSDC2303: {
    id: InstrumentId.yETHUSDC2303,
    maturity: MaturityId["310323"],
    ...PairConfig.ETHUSDC,
  } as const,
  yUSDCETH2303: {
    id: InstrumentId.yUSDCETH2303,
    maturity: MaturityId["310323"],
    ...PairConfig.USDCETH,
  } as const,
  // ETHDAI 2303
  yETHDAI2303: {
    id: InstrumentId.yETHDAI2303,
    maturity: MaturityId["310323"],
    ...PairConfig.ETHDAI,
  } as const,
  yDAIETH2303: {
    id: InstrumentId.yDAIETH2303,
    maturity: MaturityId["310323"],
    ...PairConfig.DAIETH,
  } as const,
  // DAIUSDC 2303
  yDAIUSDC2303: {
    id: InstrumentId.yDAIUSDC2303,
    maturity: MaturityId["310323"],
    ...PairConfig.DAIUSDC,
  } as const,
  yUSDCDAI2303: {
    id: InstrumentId.yUSDCDAI2303,
    maturity: MaturityId["310323"],
    ...PairConfig.USDCDAI,
  } as const,

  // ETHUSDC 2306
  yETHUSDC2306: {
    id: InstrumentId.yETHUSDC2306,
    maturity: MaturityId["300623"],
    ...PairConfig.ETHUSDC,
  } as const,
  yUSDCETH2306: {
    id: InstrumentId.yUSDCETH2306,
    maturity: MaturityId["300623"],
    ...PairConfig.USDCETH,
  } as const,
  // ETHDAI 2306
  yETHDAI2306: {
    id: InstrumentId.yETHDAI2306,
    maturity: MaturityId["300623"],
    ...PairConfig.ETHDAI,
  } as const,
  yDAIETH2306: {
    id: InstrumentId.yDAIETH2306,
    maturity: MaturityId["300623"],
    ...PairConfig.DAIETH,
  } as const,
  // DAIUSDC 2306
  yDAIUSDC2306: {
    id: InstrumentId.yDAIUSDC2306,
    maturity: MaturityId["300623"],
    ...PairConfig.DAIUSDC,
  } as const,
  yUSDCDAI2306: {
    id: InstrumentId.yUSDCDAI2306,
    maturity: MaturityId["300623"],
    ...PairConfig.USDCDAI,
  } as const,
  // ETHUSDT 2306
  yETHUSDT2306: {
    id: InstrumentId.yETHUSDT2306,
    maturity: MaturityId["300623"],
    ...PairConfig.ETHUSDT,
  } as const,
  // USDCUSDT 2306
  yUSDCUSDT2306: {
    id: InstrumentId.yUSDCUSDT2306,
    maturity: MaturityId["300623"],
    ...PairConfig.USDCUSDT,
  } as const,
  // DAIUSDT 2306
  yDAIUSDT2306: {
    id: InstrumentId.yDAIUSDT2306,
    maturity: MaturityId["300623"],
    ...PairConfig.DAIUSDT,
  } as const,

  // SEPTEMBER 2023
  // ETHUSDC 2309
  yETHUSDC2309: {
    id: InstrumentId.yETHUSDC2309,
    maturity: MaturityId["290923"],
    ...PairConfig.ETHUSDC,
  } as const,
  yUSDCETH2309: {
    id: InstrumentId.yUSDCETH2309,
    maturity: MaturityId["290923"],
    ...PairConfig.USDCETH,
  } as const,
  // ETHDAI 2309
  yETHDAI2309: {
    id: InstrumentId.yETHDAI2309,
    maturity: MaturityId["290923"],
    ...PairConfig.ETHDAI,
  } as const,
  yDAIETH2309: {
    id: InstrumentId.yDAIETH2309,
    maturity: MaturityId["290923"],
    ...PairConfig.DAIETH,
  } as const,
  // DAIUSDC 2309
  yDAIUSDC2309: {
    id: InstrumentId.yDAIUSDC2309,
    maturity: MaturityId["290923"],
    ...PairConfig.DAIUSDC,
  } as const,
  yUSDCDAI2309: {
    id: InstrumentId.yUSDCDAI2309,
    maturity: MaturityId["290923"],
    ...PairConfig.USDCDAI,
  } as const,
  // ETHUSDT 2309
  yETHUSDT2309: {
    id: InstrumentId.yETHUSDT2309,
    maturity: MaturityId["290923"],
    ...PairConfig.ETHUSDT,
  } as const,
  // USDCUSDT 2309
  yUSDCUSDT2309: {
    id: InstrumentId.yUSDCUSDT2309,
    maturity: MaturityId["290923"],
    ...PairConfig.USDCUSDT,
  } as const,
  // DAIUSDT 2309
  yDAIUSDT2309: {
    id: InstrumentId.yDAIUSDT2309,
    maturity: MaturityId["290923"],
    ...PairConfig.DAIUSDT,
  } as const,

  // DECEMBER 2023
  yETHUSDC2312: {
    id: InstrumentId.yETHUSDC2312,
    maturity: MaturityId["291223"],
    ...PairConfig.ETHUSDC,
  } as const,
  yUSDCETH2312: {
    id: InstrumentId.yUSDCETH2312,
    maturity: MaturityId["291223"],
    ...PairConfig.USDCETH,
  } as const,
  // ETHDAI 2312
  yETHDAI2312: {
    id: InstrumentId.yETHDAI2312,
    maturity: MaturityId["291223"],
    ...PairConfig.ETHDAI,
  } as const,
  yDAIETH2312: {
    id: InstrumentId.yDAIETH2312,
    maturity: MaturityId["291223"],
    ...PairConfig.DAIETH,
  } as const,
  // DAIUSDC 2312
  yDAIUSDC2312: {
    id: InstrumentId.yDAIUSDC2312,
    maturity: MaturityId["291223"],
    ...PairConfig.DAIUSDC,
  } as const,
  yUSDCDAI2312: {
    id: InstrumentId.yUSDCDAI2312,
    maturity: MaturityId["291223"],
    ...PairConfig.USDCDAI,
  } as const,
  // ETHUSDT 2312
  yETHUSDT2312: {
    id: InstrumentId.yETHUSDT2312,
    maturity: MaturityId["291223"],
    ...PairConfig.ETHUSDT,
  } as const,
  // USDCUSDT 2312
  yUSDCUSDT2312: {
    id: InstrumentId.yUSDCUSDT2312,
    maturity: MaturityId["291223"],
    ...PairConfig.USDCUSDT,
  } as const,
  // DAIUSDT 2312
  yDAIUSDT2312: {
    id: InstrumentId.yDAIUSDT2312,
    maturity: MaturityId["291223"],
    ...PairConfig.DAIUSDT,
  } as const,
}
export type Instrument = typeof Instrument[keyof typeof Instrument]
export type Quote = Instrument["quote"]
export type Base = Instrument["base"]

export const Quote: IdentityRecord<Quote> = {} as any
export const Base: IdentityRecord<Base> = {} as any
Object.values(Instrument).forEach(({ base, quote }) => {
  ;(Quote[quote] as any) = quote
  ;(Base[base] as any) = base
})

export interface AddEthereumChainParameter {
  chainId: ChainId
  blockExplorerUrls?: string[]
  chainName?: string
  iconUrls?: string[]
  nativeCurrency?: {
    name: Currency["name"]
    symbol: CurrencyId
    decimals: Currency["precision"]
  }
  rpcUrls?: string[]
  graphUrl: string
}

export const ChainId = {
  Localhost8546: "0x7a69",
  Arbitrum: "0xa4b1",
} as const
export type ChainId = typeof ChainId[keyof typeof ChainId]

export const Network = {
  [ChainId.Localhost8546]: {
    chainData: {
      chainName: "Localhost8546",
      blockExplorerUrls: [],
      nativeCurrency: {
        name: Currency.ETH.name,
        symbol: Currency.ETH.id,
        decimals: Currency.ETH.precision,
      },
      chainId: ChainId.Localhost8546,
      rpcUrls: ["http://localhost:8546"],
      graphUrl:
        "http://localhost:8000/subgraphs/name/contango-xyz/master-graph-arbitrum",
    } as AddEthereumChainParameter,
    addresses: {
      NFT: "0x497931c260a6f76294465f7BBB5071802e97E109",
      contango: "0x30E7348163016B3b6E1621A3Cb40e8CF33CE97db",
      cashSettler: "0x17104eb54776B63301ba5dBBF809250918175433", // cashSettler contract address
      contangoQuoter: "0x807073F955439fa0eF808a9B50007696b5dCE971",
      multicall2: "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2",
      DAI: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      WBTC: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      USDC: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      ETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    },
    expiryBuffer: 60, // time in seconds before expiry where new positions cannot be opened, and existing positions cannot be modified or closed
  },
  [ChainId.Arbitrum]: {
    chainData: {
      chainName: "Arbitrum",
      nativeCurrency: {
        name: Currency.ETH.name,
        symbol: Currency.ETH.id,
        decimals: Currency.ETH.precision,
      },
      chainId: ChainId.Arbitrum,
      rpcUrls: ["https://arb1.arbitrum.io/rpc"],
      graphUrl:
        "https://api.thegraph.com/subgraphs/name/contango-xyz/master-graph-arbitrum",
    } as AddEthereumChainParameter,
    addresses: {
      NFT: "0x497931c260a6f76294465f7BBB5071802e97E109",
      contango: "0x30E7348163016B3b6E1621A3Cb40e8CF33CE97db",
      cashSettler: "0x17104eb54776B63301ba5dBBF809250918175433", // cashSettler contract address
      witch: "0x89343a24a217172A569A0bD68763Bf0671A3efd8",
      contangoQuoter: "0x807073F955439fa0eF808a9B50007696b5dCE971",
      multicall2: "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2",
      DAI: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      WBTC: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      USDC: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      ETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    },
    expiryBuffer: 60, // time in seconds before expiry where new positions cannot be opened, and existing positions cannot be modified or closed
  },
} as const
export type Network = typeof Network[keyof typeof Network]

export const NetworkInstruments: Record<ChainId, Instrument[]> = {
  [ChainId.Arbitrum]: [
    // December 2022
    Instrument.yETHUSDC2212,
    Instrument.yUSDCETH2212,
    Instrument.yETHDAI2212,
    Instrument.yDAIETH2212,
    Instrument.yDAIUSDC2212,
    Instrument.yUSDCDAI2212,
    // March 2023
    Instrument.yETHUSDC2303,
    Instrument.yUSDCETH2303,
    Instrument.yDAIUSDC2303,
    Instrument.yUSDCDAI2303,
    Instrument.yETHDAI2303,
    Instrument.yDAIETH2303,
    // June  2023
    Instrument.yETHUSDC2306,
    Instrument.yUSDCETH2306,
    Instrument.yETHDAI2306,
    Instrument.yDAIETH2306,
    Instrument.yETHUSDT2306,
    Instrument.yDAIUSDC2306,
    Instrument.yUSDCDAI2306,
    Instrument.yUSDCUSDT2306,
    Instrument.yDAIUSDT2306,
    // September 2023
    Instrument.yETHUSDC2309,
    Instrument.yUSDCETH2309,
    Instrument.yETHDAI2309,
    Instrument.yDAIETH2309,
    Instrument.yETHUSDT2309,
    Instrument.yDAIUSDC2309,
    Instrument.yUSDCDAI2309,
    Instrument.yUSDCUSDT2309,
    Instrument.yDAIUSDT2309,
    // December 2023
    Instrument.yETHUSDC2312,
    Instrument.yUSDCETH2312,
    Instrument.yETHDAI2312,
    Instrument.yDAIETH2312,
    Instrument.yETHUSDT2312,
    Instrument.yDAIUSDC2312,
    Instrument.yUSDCDAI2312,
    Instrument.yUSDCUSDT2312,
    Instrument.yDAIUSDT2312,
  ],
  [ChainId.Localhost8546]: [
    // December 2022
    Instrument.yETHUSDC2212,
    Instrument.yUSDCETH2212,
    Instrument.yETHDAI2212,
    Instrument.yDAIETH2212,
    Instrument.yDAIUSDC2212,
    Instrument.yUSDCDAI2212,
    // March 2023
    Instrument.yETHUSDC2303,
    Instrument.yUSDCETH2303,
    Instrument.yDAIUSDC2303,
    Instrument.yUSDCDAI2303,
    Instrument.yETHDAI2303,
    Instrument.yDAIETH2303,
    // June  2023
    Instrument.yETHUSDC2306,
    Instrument.yUSDCETH2306,
    Instrument.yETHDAI2306,
    Instrument.yDAIETH2306,
    Instrument.yETHUSDT2306,
    Instrument.yDAIUSDC2306,
    Instrument.yUSDCDAI2306,
    Instrument.yUSDCUSDT2306,
    Instrument.yDAIUSDT2306,
    // September 2023
    Instrument.yETHUSDC2309,
    Instrument.yUSDCETH2309,
    Instrument.yETHDAI2309,
    Instrument.yDAIETH2309,
    Instrument.yETHUSDT2309,
    Instrument.yDAIUSDC2309,
    Instrument.yUSDCDAI2309,
    Instrument.yUSDCUSDT2309,
    Instrument.yDAIUSDT2309,
    // December 2023
    Instrument.yETHUSDC2312,
    Instrument.yUSDCETH2312,
    Instrument.yETHDAI2312,
    Instrument.yDAIETH2312,
    Instrument.yETHUSDT2312,
    Instrument.yDAIUSDC2312,
    Instrument.yUSDCDAI2312,
    Instrument.yUSDCUSDT2312,
    Instrument.yDAIUSDT2312,
  ],
}
