import { ChainId } from "@/api"
import {
  catchError,
  concatMap,
  defer,
  filter,
  map,
  Observable,
  of,
  startWith,
  take,
} from "rxjs"
import {
  forwardPermit,
  getTransactionReceipt,
  signERC2612Permit,
  unwrapWETH,
  wrapETH,
} from "../chain"
import type {
  Base,
  Quote,
  SignedERC2612Permit,
  TransactionReceipt,
} from "../types"
import { withLatest } from "./withTicks"

export enum TransactionStatusType {
  RequestingPermit = "requestingPermit",
  PermitSigned = "permitSigned",
  PermitNotSigned = "permitNotSigned",
  Submitting = "submitting",
  Rejected = "rejected",
  Submitted = "submitted",
  Completed = "completed",
}

interface RequestingPermit {
  type: TransactionStatusType.RequestingPermit
}

interface PermitSigned {
  type: TransactionStatusType.PermitSigned
  permit: SignedERC2612Permit
}

interface PermitNotSigned {
  type: TransactionStatusType.PermitNotSigned
}

interface Submitting {
  type: TransactionStatusType.Submitting
}

interface NotSubmitted {
  type: TransactionStatusType.Rejected
}

interface Submitted {
  type: TransactionStatusType.Submitted
  transactionHash: string
}

interface Completed {
  type: TransactionStatusType.Completed
  ok: boolean
  meta: TransactionReceipt
}

export type TransactionStatus =
  | RequestingPermit
  | PermitSigned
  | PermitNotSigned
  | Submitting
  | Submitted
  | NotSubmitted
  | Completed

export const followTransaction =
  <A extends Array<any>>(
    fn: (fromAddress: string, ...args: A) => Promise<string>,
  ) =>
  (
    spending: { token: Quote | Base; value: bigint; chainId: ChainId } | null,
    fromAddress: string,
    ...args: A
  ) => {
    if (spending?.token === "ETH") {
      return followTransactionWithoutPermit(
        (fromAddress: string, ...args: A) => {
          if (spending.value > 0n) wrapETH(fromAddress, spending.value)
          const result = fn(fromAddress, ...args)
          if (spending.value <= 0n) unwrapWETH(fromAddress, fromAddress)
          return result
        },
        fromAddress,
        ...args,
      )
    }

    if ((spending?.value ?? 0n) > 0n) {
      return followTransactionWithPermit(
        fn,
        spending as any,
        fromAddress,
        ...args,
      )
    } else {
      return followTransactionWithoutPermit(fn, fromAddress, ...args)
    }
  }

const getTransactionReceipt$ = withLatest((hash: string) =>
  getTransactionReceipt(hash),
)

export const followTransactionWithoutPermit = <A extends Array<any>>(
  fn: (...args: A) => Promise<string>,
  ...args: A
): Observable<TransactionStatus> => {
  const transaction$: () => Observable<
    Submitting | NotSubmitted | Submitted | Completed
  > = () =>
    defer(() => {
      return fn(...args)
    }).pipe(
      startWith({ type: TransactionStatusType.Submitting } as const),
      catchError(() => of({ type: TransactionStatusType.Rejected as const })),
      concatMap((hash) => {
        if (typeof hash !== "string") return [hash]
        return getTransactionReceipt$(hash).pipe(
          filter(Boolean),
          map(
            (x) =>
              ({
                type: TransactionStatusType.Completed as const,
                ok: parseInt(x.status, 16) === 1,
                meta: x,
              } as const),
          ),
          take(1),
          startWith({
            type: TransactionStatusType.Submitted as const,
            transactionHash: hash,
          }),
        )
      }),
    )

  return transaction$()
}

const followTransactionWithPermit = <A extends Array<any>>(
  fn: (...args: A) => Promise<string>,
  spending: {
    token: Exclude<Quote | Base, "ETH">
    value: bigint
    chainId: ChainId
  },
  ...args: A
): Observable<TransactionStatus> => {
  const permit$: Observable<RequestingPermit | PermitSigned | PermitNotSigned> =
    defer(async () => {
      const permit = await signERC2612Permit(spending.token, spending.value)
      await new Promise((resolve) => setTimeout(resolve, 300)) // this timeout is necessary to prevent rabby from rejecting the transaction
      return {
        type: TransactionStatusType.PermitSigned,
        permit,
      } as const
    }).pipe(
      catchError((error) => {
        console.log("permit not signed", error)
        return of({ type: TransactionStatusType.PermitNotSigned as const })
      }),
      startWith({
        type: TransactionStatusType.RequestingPermit as const,
      }),
    )

  const transaction$: (
    permit: SignedERC2612Permit,
  ) => Observable<Submitting | NotSubmitted | Submitted | Completed> = (
    permit,
  ) =>
    defer(() => {
      const { owner, verifyingContract, spender, value, deadline, v, r, s } =
        permit
      // Another good spot to check whether we actually have to do this
      forwardPermit(owner, verifyingContract, spender, value, deadline, v, r, s)
      return fn(...args)
    }).pipe(
      startWith({ type: TransactionStatusType.Submitting } as const),
      catchError((error) => {
        console.log("transaction rejected", error)
        return of({ type: TransactionStatusType.Rejected as const })
      }),
      concatMap((hash) => {
        if (typeof hash !== "string") return [hash]
        return getTransactionReceipt$(hash).pipe(
          filter(Boolean),
          map(
            (x) =>
              ({
                type: TransactionStatusType.Completed as const,
                ok: parseInt(x.status, 16) === 1,
                meta: x,
              } as const),
          ),
          take(1),
          startWith({
            type: TransactionStatusType.Submitted as const,
            transactionHash: hash,
          }),
        )
      }),
    )

  return permit$.pipe(
    concatMap((permitResponse) =>
      permitResponse.type === TransactionStatusType.PermitSigned
        ? transaction$(permitResponse.permit)
        : of(permitResponse),
    ),
  )
}
