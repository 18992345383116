import { Side } from "@/api"
import { PrimaryButton } from "@/components/PrimaryButton"
import { useStateObservable } from "@react-rxjs/core"
import { merge } from "rxjs"
import { ApprovalType, approvalType$ } from "../state/approvals"
import { instrument$ } from "./state"
import {
  isSubmittingApproval$,
  onUserSubmitApprovalTx,
} from "./state/approvals"
import {
  isFormValid$,
  needsApproval$,
  onUserSubmitPosition,
} from "./state/submitting"

const ApproveSpending: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const isSubmitting = useStateObservable(isSubmittingApproval$)

  return (
    <PrimaryButton
      data-testid="approve-spending"
      className="w-full"
      disabled={disabled || isSubmitting}
      onClick={onUserSubmitApprovalTx}
    >
      {isSubmitting ? "Awaiting Approval" : "Approve Spending"}
    </PrimaryButton>
  )
}

export const Submit$ = merge(
  isFormValid$,
  approvalType$,
  needsApproval$,
  instrument$,
  isSubmittingApproval$,
)

export const Submit: React.FC = () => {
  const isValid = useStateObservable(isFormValid$)
  const approvalType = useStateObservable(approvalType$)
  const needsApproval =
    useStateObservable(needsApproval$) && approvalType !== ApprovalType.Permit
  const { side } = useStateObservable(instrument$)

  return needsApproval ? (
    <ApproveSpending disabled={!isValid} />
  ) : (
    <PrimaryButton
      data-testid="create-ticket--submit"
      className={
        "w-full " +
        (side === Side.Long
          ? "bg-functional-buy-500"
          : "bg-functional-sell-500")
      }
      disabled={!isValid}
      onClick={onUserSubmitPosition}
    >
      Open position
    </PrimaryButton>
  )
}
