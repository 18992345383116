import {
  getOpeningCostByLeverage$,
  OpeningCostError,
  OpeningCostErrorType,
} from "@/api"
import { onUncaughtError } from "@/utils/error-utils"
import { calculateMinQuantity } from "@/utils/financial-utils"
import { baseToMinQty } from "@/utils/mappers"
import {
  justWait,
  mapDistinct,
  suspenseToNull,
  unsuspended,
} from "@/utils/observable-utils"
import { sinkSuspense, state, SUSPENSE } from "@react-rxjs/core"
import {
  combineLatest,
  concat,
  map,
  of,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs"
import { setFormError } from "./error/formErrors"
import { isQtyBlurred$, quantity$ } from "./inputs"
import { formChangeEvents$ } from "./inputsChange"
import { instrument$ } from "./instrument"

export const benchmarkMinQuantityCost$ = state(
  instrument$.pipe(
    switchMap(({ id, base, ltvRatio }) => {
      const minQuantity = baseToMinQty(base)

      return concat(
        of(SUSPENSE),
        justWait(200),
        getOpeningCostByLeverage$(id, minQuantity, BigInt(11e18)).pipe(
          take(1),
          map((benchmarkCost) => ({
            cost: benchmarkCost.cost,
            quantity: minQuantity,
            minDebt: benchmarkCost.minDebt,
            liquidationRatio: benchmarkCost.liquidationRatio,
            ltvRatio: ltvRatio,
          })),
        ),
      )
    }),
    onUncaughtError((error) => {
      if (
        error instanceof OpeningCostError &&
        error.type === OpeningCostErrorType.InsufficientLiquidity
      ) {
        return setFormError("noLiquidity", error)
      } else {
        setFormError("noLiquidity", null)
        if (error) setFormError("cannotRetrieveQuote", error)
        else setFormError("cannotRetrieveQuote", null)
      }
    })(formChangeEvents$),
    sinkSuspense(),
  ),
)

export const minQuantity$ = state(
  benchmarkMinQuantityCost$.pipe(
    withLatestFrom(instrument$),
    mapDistinct(([benchmark, { base, quote }]) =>
      calculateMinQuantity(
        benchmark.minDebt,
        benchmark.cost,
        benchmark.quantity,
        base,
        quote,
      ),
    ),
  ),
  0n,
)

export const isBelowMinQuantity$ = state(
  combineLatest([
    quantity$,
    minQuantity$.pipe(suspenseToNull()),
    isQtyBlurred$,
  ]).pipe(
    mapDistinct(([quantity, minQuantity, isQtyBlurred]) =>
      Boolean(
        quantity && minQuantity && quantity < minQuantity && isQtyBlurred,
      ),
    ),
  ),
)

export const validQuantity$ = state(
  combineLatest([quantity$, unsuspended(minQuantity$)]).pipe(
    mapDistinct(([quantity, minQuantity]) => {
      if (quantity === null || minQuantity === null) return null
      return quantity >= minQuantity ? quantity : null
    }),
  ),
)
