import type { TransactionReceipt } from "@/api"
import { state } from "@react-rxjs/core"
import { withMulticall } from "@unstoppablejs/solidity-bindings"
import {
  distinctUntilChanged,
  filter,
  pluck,
  repeat,
  share,
  switchMap,
} from "rxjs"
import { client as rawClient } from "../wallet"
import { batcher } from "./batcher"
import { getCurrentNetwork } from "./latestChain"

export const client = withMulticall(
  () => getCurrentNetwork().addresses.multicall2,
  batcher,
  rawClient,
)

export const getTransactionReceipt = (transactionHash: string) =>
  client.request<TransactionReceipt | null>("eth_getTransactionReceipt", [
    transactionHash,
  ])

export const newHeads$ = client.currentBlockNumber$.pipe(repeat(), share())

export const eventsProcessed$ = client.eventsProcessor$.pipe(share())
eventsProcessed$.subscribe()

export const getLatestBlockTimestamp = async (blockNumber: number) => {
  const block = (await client.request("eth_getBlockByNumber", [
    `0x${blockNumber.toString(16)}`,
    false,
  ])) as { timestamp: string } // hex
  if (block && block.timestamp) {
    return Number(block.timestamp)
  } else return null
}

// this timestamp is in seconds
export const latestBlockTimestamp$ = state(
  newHeads$.pipe(
    pluck("blockNumber"),
    distinctUntilChanged(),
    switchMap(getLatestBlockTimestamp),
    filter(Boolean),
  ),
)
