import { Suspense } from "react"

export const withSuspense =
  <T extends {}>(fallback: React.ReactNode, Base: React.FC<T>): React.FC<T> =>
  (p) =>
    (
      <Suspense fallback={fallback}>
        <Base {...p} />
      </Suspense>
    )
