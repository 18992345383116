import { SUSPENSE } from "@react-rxjs/core"
import {
  catchError,
  EMPTY,
  merge,
  Observable,
  switchMap,
  take,
  tap,
  timer,
} from "rxjs"

// important: resetErrorStream$ CANNOT be a state observable.
// It needs to be a hot, non-state observable (does not emit on subscribe)
export const onUncaughtError = (setError: (error: any) => void) => {
  return (resetErrorStream$: Observable<any> = EMPTY, retryInterval = 10000) =>
    <T extends any | SUSPENSE>(source$: Observable<T>) =>
      source$.pipe(
        catchError((error, caught$) => {
          if (error === SUSPENSE) throw SUSPENSE
          setError(error)
          // delays re-subscription to prevent error spam
          return merge(resetErrorStream$, timer(retryInterval)).pipe(
            take(1),
            switchMap(() => caught$),
          )
        }),
        tap((event) => {
          event !== SUSPENSE && setError(null)
        }),
      )
}
