import { state, SUSPENSE } from "@react-rxjs/core"
import { createSignal, mergeWithKey } from "@react-rxjs/utils"
import {
  combineLatest,
  defer,
  map,
  merge,
  of,
  pluck,
  scan,
  share,
  startWith,
  switchMap,
} from "rxjs"
import {
  getPositions,
  listenToPositionTransfers,
  positionClosedEvent,
  positionDeliveredEvent,
} from "../chain"
import { account$, accountOrError$, networkOrError$ } from "../wallet"
import { getPosition$ } from "./position"

export const removedPositions$ = account$.pipe(
  switchMap((trader) =>
    merge(positionClosedEvent({ trader }), positionDeliveredEvent({ trader })),
  ),
  share(),
)

const transferTokenId$ = (src$: ReturnType<typeof listenToPositionTransfers>) =>
  src$.pipe(map(({ filters }) => filters.tokenId))

const [hasPendingPosition$, setHasPendingPosition] = createSignal<boolean>()
export { setHasPendingPosition }

const pending = 1_000_000_000n

export const sent$ = account$.pipe(
  switchMap((trader) => listenToPositionTransfers({ from: trader })),
  share(),
)

const positions = (owner: string) => {
  // TODO: in the future we will be consuming indexed data, so the hardcoded
  // value is a temporary work-around... ;-) (famous last words!)
  const init$ = defer(() => getPositions(owner, 0n, 10000n))
  const received$ = transferTokenId$(listenToPositionTransfers({ to: owner }))

  return mergeWithKey({
    i: init$,
    r: received$,
    s: merge(
      transferTokenId$(sent$),
      removedPositions$.pipe(pluck("filters", "positionId")),
    ),
    p: hasPendingPosition$,
  }).pipe(
    scan((acc, { type, payload }) => {
      if (type === "i") return new Set(payload)
      if (type === "s") acc.delete(payload)
      if (type === "p") {
        if (payload === true) acc.add(pending)
        else acc.delete(pending)
      }
      if (type === "r") {
        acc.add(payload)
        acc.delete(pending)
      }
      return acc
    }, new Set<bigint>()),
    map((positionsSet) => [...positionsSet].reverse()),
  )
}

// returns position ids for currently open positions
export const getOpenPositionIds$ = state(
  combineLatest([accountOrError$, networkOrError$]).pipe(
    switchMap(([account, network]) =>
      account instanceof Error || network instanceof Error
        ? of(SUSPENSE)
        : positions(account).pipe(
            switchMap((ids) =>
              ids.length === 0
                ? of([] as bigint[])
                : combineLatest(
                    ids.map((id) =>
                      id === pending
                        ? of({ positionId: id, quantity: 1n })
                        : getPosition$(id),
                    ),
                  ).pipe(
                    map((positions) =>
                      positions
                        .filter(({ quantity }) => quantity !== 0n)
                        .map(({ positionId }) => positionId),
                    ),
                  ),
            ),
            startWith(SUSPENSE),
          ),
    ),
  ),
)
