import { CurrencyId } from "@/api"
import { FC, ImgHTMLAttributes } from "react"

export const CurrencyIcon: FC<
  { currency: CurrencyId } & ImgHTMLAttributes<HTMLImageElement>
> = ({ currency, ...rest }) => {
  return (
    <img
      alt={`${currency} icon`}
      {...{ height: 16, width: 16, ...rest }} // default the size to 16x16
      src={import.meta.env.BASE_URL + `currencyIcons/${currency}/icon.svg`}
    />
  )
}

export const ProtocolIcon: FC<
  {
    protocol: "yield" | "contango" | "uniswap"
  } & ImgHTMLAttributes<HTMLImageElement>
> = ({ protocol, ...rest }) => {
  return (
    <img
      alt={`${protocol} icon`}
      {...{ height: 16, width: 16, ...rest }} // default the size to 16x16
      src={import.meta.env.BASE_URL + `protocolIcons/${protocol}.svg`}
    />
  )
}
