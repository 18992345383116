import { buildSDK } from "@mean-finance/sdk"
import { ChainId } from "../generated"

const sdk = buildSDK()

export const getAllQuotes = (
  sellToken: string,
  buyToken: string,
  sellAmount: bigint,
  slippagePercentage: number, // 1 == 1%
  takerAddress: string,
) => {
  return sdk.quoteService.getAllQuotes({
    request: {
      chainId: Number(ChainId.Arbitrum),
      sellToken,
      buyToken,
      order: {
        type: "sell",
        sellAmount,
      },
      slippagePercentage,
      takerAddress,
      // Optional gas speed
      gasSpeed: {
        speed: "instant",
      },
      filters: {
        // limiting to the most reliable ones for now. mostly due to speed issues with some of the other ones.
        includeSources: ["1inch", "paraswap", "kyberswap"],
      },
    },
    // Optional config
    config: {
      sort: {
        by: "most-swapped-accounting-for-gas",
      },
      ignoredFailed: true,
    },
  })
}
