import {
  account$,
  allowance$,
  balance$,
  getModifyCostByLeverage$,
  getPositionClosingCostData$,
  getPositionProp$,
  network$,
  Quote,
} from "@/api"
import { modifyCollateral, modifyPosition } from "@/api/chain"
import {
  followTransaction,
  TransactionStatus,
  TransactionStatusType,
} from "@/api/utils"
import { applySlippage, decimalProduct } from "@/utils/financial-utils"
import { isTxPending } from "@/utils/isTxPending"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import {
  combineLatest,
  concatMap,
  exhaustMap,
  filter,
  ignoreElements,
  map,
  merge,
  Observable,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs"
import { EditType, onEditOrCancel } from "./base"
import { deltaCost$ } from "./cost"
import { slippage$ } from "./inputs"
import { collateralUsed$ } from "./leverage"
import { isClosingPosition$, quantityDelta$, quantityEdit$ } from "./quantity"
import {
  areInputsValidAndHasDeltas$,
  isMinQuantityValidationMet$,
} from "./validations"

const [userSubmitEdit$, onUserSubmitEdit] = createSignal()
export { onUserSubmitEdit }

export const needsApproval$ = state(
  (id: bigint) =>
    combineLatest([
      collateralUsed$(id),
      getPositionProp$(id, "quote").pipe(
        switchMap((quote) => allowance$(quote)),
      ),
    ]).pipe(map(([equity, approved]) => equity > approved)),
  false,
)

export const hasEnoughBalance$ = state(
  (positionId: bigint) =>
    combineLatest([
      getPositionProp$(positionId, "quote").pipe(
        switchMap((quote) => balance$(quote)),
      ),
      collateralUsed$(positionId),
    ]).pipe(mapDistinct(([balance, delta]) => balance >= delta)),
  null,
)

export const isFormValid$ = state(
  (positionId: bigint) =>
    areInputsValidAndHasDeltas$(positionId).pipe(
      switchMap((isOk) =>
        isOk
          ? combineLatest([
              isMinQuantityValidationMet$(positionId),
              hasEnoughBalance$(positionId),
            ]).pipe(
              map(([isMinQuantityMet, hasEnoughBalance]) => {
                return !(isMinQuantityMet !== true || hasEnoughBalance !== true)
              }),
            )
          : isClosingPosition$(positionId),
      ),
    ),
  false,
)

const modifyCollateral$ = (positionId: bigint) => {
  return combineLatest({
    equityDelta: collateralUsed$(positionId),
    quote: getPositionProp$(positionId, "quote"),
    deltaCost: deltaCost$(positionId),
    slippage: slippage$(positionId),
    needsApproval: needsApproval$(positionId),
    account: account$,
    network: network$,
  }).pipe(
    take(1),
    concatMap(
      ({
        equityDelta,
        deltaCost: { quoteLendingLiquidity, debtDelta },
        slippage,
        quote,
        account,
        network,
        needsApproval,
      }) =>
        followTransaction(modifyCollateral)(
          {
            token: quote,
            value: needsApproval || quote === "ETH" ? equityDelta : 0n,
            chainId: network.chainData.chainId,
          },
          account,
          positionId,
          equityDelta,
          applySlippage(debtDelta, slippage),
          quote === "ETH" ? network.addresses.contango : account,
          quoteLendingLiquidity,
        ),
    ),
  )
}

export type ObservableType<T extends Observable<any>> = T extends Observable<
  infer R
>
  ? R
  : never

const modifyPositionCall = (
  positionId: bigint,
  quantityDelta: bigint,
  {
    needsBatchedCall,
    baseLendingLiquidity,
    quoteLendingLiquidity,
    cost,
    financingCost,
    debtDelta,
    collateralUsed,
    uniswapFee,
  }: ObservableType<ReturnType<typeof getModifyCostByLeverage$>>,
  slippage: number,
  quote: Quote,
  network: ObservableType<typeof network$>,
  account: string,
  needsApproval: boolean,
) => {
  return followTransaction(() => {
    cost = needsBatchedCall ? cost + financingCost : cost
    const direction = cost > 0 ? -1 : 1
    const costWithSlippage = decimalProduct(
      cost,
      1 + (slippage * direction) / 100,
    )

    const payer = quote === "ETH" ? network.addresses.contango : account
    const lendingLiquidity =
      quantityDelta >= 0n ? baseLendingLiquidity : quoteLendingLiquidity

    if (!needsBatchedCall) {
      return modifyPosition(
        account,
        positionId,
        quantityDelta,
        costWithSlippage,
        collateralUsed,
        payer,
        lendingLiquidity,
        uniswapFee,
      )
    }

    const modifyCollateralAmount = financingCost - debtDelta

    const modifyPositionCollateralAmount =
      collateralUsed - modifyCollateralAmount

    const debtDeltaWithSlippage = applySlippage(debtDelta, slippage)

    modifyPosition(
      account,
      positionId,
      quantityDelta,
      costWithSlippage,
      modifyPositionCollateralAmount,
      payer,
      lendingLiquidity,
      uniswapFee,
    )
    return modifyCollateral(
      account,
      positionId,
      modifyCollateralAmount,
      debtDeltaWithSlippage,
      payer,
      lendingLiquidity,
    )
  })(
    {
      token: quote,
      value: needsApproval || quote === "ETH" ? collateralUsed : 0n,
      chainId: network.chainData.chainId,
    },
    account,
  )
}

const modifyPosition$ = (positionId: bigint, quantityDelta: bigint) => {
  return combineLatest({
    deltaCost: deltaCost$(positionId),
    slippage: slippage$(positionId),
    quote: getPositionProp$(positionId, "quote"),
    needsApproval: needsApproval$(positionId),
    account: account$,
    network: network$,
  }).pipe(
    take(1),
    concatMap(
      ({ deltaCost, slippage, quote, account, network, needsApproval }) =>
        modifyPositionCall(
          positionId,
          quantityDelta,
          deltaCost,
          slippage,
          quote,
          network,
          account,
          needsApproval,
        ),
    ),
  )
}

const closePosition$ = (positionId: bigint, quantityDelta: bigint) => {
  return combineLatest({
    cost: getPositionClosingCostData$(positionId),
    slippage: slippage$(positionId),
    quote: getPositionProp$(positionId, "quote"),
    account: account$,
    network: network$,
  }).pipe(
    take(1),
    concatMap(({ cost, slippage, quote, account, network }) =>
      modifyPositionCall(
        positionId,
        quantityDelta,
        cost,
        slippage,
        quote,
        network,
        account,
        false,
      ),
    ),
  )
}

const submit$ = state((positionId: bigint) =>
  userSubmitEdit$.pipe(
    withLatestFrom(
      isFormValid$(positionId),
      quantityDelta$(positionId),
      quantityEdit$(positionId),
      getPositionProp$(positionId, "quantity"),
    ),
    exhaustMap(
      ([_, isValid, quantityDelta, quantityEdit, positionQuantity]) => {
        if (!isValid) return []
        if (quantityEdit === 0n)
          return closePosition$(positionId, -positionQuantity)
        return quantityDelta === 0n
          ? modifyCollateral$(positionId)
          : modifyPosition$(positionId, quantityDelta)
      },
    ),
  ),
)

const resetForm$ = (positionId: bigint) =>
  submit$(positionId).pipe(
    filter(
      (
        status,
      ): status is TransactionStatus & {
        type: TransactionStatusType.Completed
      } => status.type === TransactionStatusType.Completed && status.ok,
    ),
    tap(() => {
      onEditOrCancel(positionId, EditType.none)
    }),
    ignoreElements(),
  )

export const isSubmitting$ = state(
  (positionId: bigint) =>
    merge(submit$(positionId), resetForm$(positionId)).pipe(
      mapDistinct(isTxPending),
    ),
  false,
)
