import { InstrumentId } from "@/api"
import { absolute } from "@/utils/maths-utils"
import { mapDistinct } from "@/utils/observable-utils"
import { ObservableType } from "@/utils/types"
import { DefaultedStateObservable, state } from "@react-rxjs/core"
import { createKeyedSignal, createSignal } from "@react-rxjs/utils"
import { combineLatest, distinctUntilChanged, filter, map, merge } from "rxjs"
import { resetable } from "./reset"

// ------- QUANTITY ------- //

const [quantityChanged$, onQuantityChanged] = createSignal(
  (value: bigint | null) => {
    if (value === null) return null
    return absolute(value)
  },
)

const quantity$ = state(resetable(quantityChanged$, null), null)

const [_isQtyBlurred$, onIsQuantityBlurred] = createSignal<boolean>()

export const isQtyBlurred$ = state(
  _isQtyBlurred$.pipe(distinctUntilChanged()),
  true,
)

export const isQtyNullAndBlurred$ = state(
  combineLatest([quantity$, isQtyBlurred$]).pipe(
    mapDistinct(([qty, isBlurred]) => qty === null && isBlurred),
  ),
)

// ------- LEVERAGE ------- //

const [leverageInputChanged$, onLeverageInputChanged] = createSignal<
  number | null
>()

const leverageInput$: DefaultedStateObservable<
  ObservableType<typeof leverageInputChanged$>
> = state(resetable(leverageInputChanged$, null), null)

// ------- SLIPPAGE ------- //

const [slippageChanged$, onSlippageChanged] = createSignal(
  (e: React.ChangeEvent<HTMLInputElement>) => Math.abs(Number(e.target.value)),
)

const slippage$ = state(resetable(slippageChanged$, 0.15), 0.15)

// ------- MATURITY SELECT ------- //
const [_isExpanded$, setIsExpanded] = createKeyedSignal(
  ({ id }) => id,
  (id: InstrumentId, value: boolean) => ({ id, value }),
)

const isExpanded$ = state(
  (id: InstrumentId) =>
    merge(
      _isExpanded$(id).pipe(map(({ value }) => value)),
      isQtyNullAndBlurred$.pipe(
        filter(Boolean),
        map(() => false),
      ),
    ).pipe(distinctUntilChanged()),
  false,
)

export {
  quantity$,
  onQuantityChanged,
  onLeverageInputChanged,
  onIsQuantityBlurred,
  leverageInput$,
  onSlippageChanged,
  slippage$,
  setIsExpanded,
  isExpanded$,
}
