import { Base, Currency, Instrument, InstrumentId, Quote } from "@/api"
import { getPositionStatus } from "@/api/chain"
import { divideCurrency } from "./currency-utils"
import { absolute } from "./maths-utils"
import { roundToTwo } from "./rounding"
import { ReturnPromiseType } from "./types"

export const decimalProduct = (
  value: bigint,
  decimalValue: number,
  relevantDecimals: number = 4,
): bigint => {
  let tmp = value * BigInt(Math.round(decimalValue * 10 ** relevantDecimals))
  tmp /= BigInt(10 ** (relevantDecimals - 1))
  const roundingDigit = tmp % 10n
  tmp /= 10n
  return tmp + (roundingDigit > 4n ? 1n : roundingDigit < -4n ? -1n : 0n)
}

interface CostQty {
  cost: bigint
  quantity: bigint
}

export const calculateMarketImpact = (benchmark: CostQty, quote: CostQty) => {
  if (absolute(benchmark.quantity) > absolute(quote.quantity)) return 0
  const direction = quote.quantity < 0 ? -1n : 1n
  const precisionMultiplier = absolute(benchmark.quantity)
  const costOnPerUnitBasis =
    (direction * (benchmark.cost * quote.quantity)) / precisionMultiplier
  const diff = quote.cost - costOnPerUnitBasis

  const marketImpact =
    (direction * (diff * precisionMultiplier)) / costOnPerUnitBasis

  return Number(marketImpact) / Number(precisionMultiplier)
}

const minIncrements: Record<Base, bigint> = {
  ETH: BigInt(0.05e18),
  DAI: BigInt(10e18),
  USDC: BigInt(10e6),
}

export const calculateMinQuantity = (
  minDebt: bigint,
  benchmarkCost: bigint,
  benchmarkQuantity: bigint,
  base: Base,
  quote: Quote,
) => {
  const basePrecisionMultiplier = Currency[base].precisionMultiplier
  const quotePrecisionMultiplier = Currency[quote].precisionMultiplier

  const minCost = 5n * minDebt

  const benchmarkPrice = divideCurrency(
    { value: absolute(benchmarkCost), currency: quote },
    { value: benchmarkQuantity, currency: base },
  )
  const minQuantity =
    (minCost * basePrecisionMultiplier * quotePrecisionMultiplier) /
    benchmarkPrice /
    quotePrecisionMultiplier

  const res = (minQuantity * 11n) / 10n // 10% over the approximated value
  const minIncrement = minIncrements[base]

  return (res / minIncrement + 1n) * minIncrement
}

export const calculateMaxOpeningLeverage = (
  liquidationRatio: bigint,
  ltvRatio: number,
) =>
  Math.min(
    calculateLeverage(1e6, liquidationRatio),
    calculateLeverage(ltvRatio, 100),
  )

export const defaultLevarage = (instrumentId: InstrumentId) => {
  const a = calculateLeverage(Instrument[instrumentId].ltvRatio, 100)
  const b = (a + 1) / 2
  const c = Math.floor(b * 20) / 20 // precision of 0.05 (example: 3.36 -> 3.35)
  return c
}

type PositionStatus = ReturnPromiseType<typeof getPositionStatus>

export const calculateMarginAndLeverage = ({
  underlyingDebt,
  underlyingCollateral,
  liquidationRatio,
}: Pick<
  PositionStatus,
  "underlyingDebt" | "underlyingCollateral" | "liquidationRatio"
>) => {
  // liquidationRatio is always an e6 number, no matter the instrument. So these are safe
  const minMargin = calculateMargin(1e6, liquidationRatio)
  const maxLeverage = calculateLeverage(1e6, liquidationRatio)

  const margin = calculateMargin(underlyingDebt, underlyingCollateral)
  const leverage = calculateLeverage(underlyingDebt, underlyingCollateral)

  return { leverage, margin, minMargin, maxLeverage }
}

export const calculateMargin = (
  _debt: bigint | number,
  _collateral: bigint | number,
) => {
  const [debt, collateral] = [_debt, _collateral].map(Number)
  const equity = collateral - debt
  const _margin = equity / collateral
  return roundToTwo(_margin * 100)
}

export const calculateLeverage = (
  _debt: bigint | number,
  _collateral: bigint | number,
) => {
  const [debt, collateral] = [_debt, _collateral].map(Number)
  const equity = collateral - debt

  return collateral / equity
}

export const calculateLiquidationPrice = (
  {
    underlyingDebt,
    liquidationRatio,
  }: Pick<PositionStatus, "underlyingDebt" | "liquidationRatio">,
  { base, quote }: Pick<Instrument, "base" | "quote">,
  quantity: bigint,
) => {
  const debtTimesRatio = (underlyingDebt * liquidationRatio) / BigInt(1e6)
  return divideCurrency(
    { value: debtTimesRatio, currency: quote },
    { value: quantity, currency: base },
  )
}

export const calculateMarkPrice = (
  underlyingCollateral: bigint,
  { base, quote }: Pick<Instrument, "base" | "quote">,
  quantity: bigint,
) => {
  return divideCurrency(
    { value: underlyingCollateral, currency: quote },
    { value: quantity, currency: base },
  )
}

export const add = (a: bigint, b: bigint) => a + b

export const calculatePnlPercentage = (openCost: bigint, pnl: bigint) => {
  const absOpenCost = Number(absolute(openCost))
  const res = roundToTwo(((absOpenCost + Number(pnl)) / absOpenCost - 1) * 100)
  return Number.isNaN(res) ? 0 : res
}

export const applySlippage = (value: bigint, slippage: number) => {
  const direction = value > 0 ? 1 : -1
  return decimalProduct(absolute(value), 1 + (slippage * direction) / 100)
}
