import {
  account$,
  balance$,
  Currency,
  followTransaction,
  getDeliveryCostForPosition$,
  getPositionProp$,
  network$,
} from "@/api"
import { deliverPosition, unwrapWETH } from "@/api/chain"
import { decimalProduct } from "@/utils/financial-utils"
import { isTxPending } from "@/utils/isTxPending"
import { mapDistinct } from "@/utils/observable-utils"
import { state } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import {
  combineLatest,
  concatMap,
  exhaustMap,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs"
import { approvalNeeded$ } from "./approvals"

const [userSubmitDeliver$, onUserSubmitDeliver] = createSignal()

export { onUserSubmitDeliver }

export const hasSufficientBalance$ = state((id: bigint) =>
  getPositionProp$(id, "quote").pipe(
    switchMap((quote) =>
      combineLatest([getDeliveryCostForPosition$(id), balance$(quote)]).pipe(
        mapDistinct(([{ deliveryCost }, balance]) => balance >= deliveryCost),
      ),
    ),
  ),
)

const deliverPosition$ = (positionId: bigint) => {
  return combineLatest({
    account: account$,
    network: network$,
    quote: getPositionProp$(positionId, "quote"),
    base: getPositionProp$(positionId, "base"),
    deliveryCost: getDeliveryCostForPosition$(positionId),
    needsApproval: approvalNeeded$(positionId),
  }).pipe(
    take(1),
    concatMap(
      ({ account, network, quote, base, deliveryCost, needsApproval }) => {
        const deliveryCostWithPadding = decimalProduct(
          deliveryCost.deliveryCost,
          1.00015,
          Currency[quote].precision,
        )
        // TODO: put delivery summary in modal + allow user to manually set the slippage.
        return followTransaction(() => {
          const result = deliverPosition(
            account,
            positionId,
            quote === "ETH" ? network.addresses.contango : account,
            base === "ETH" ? network.addresses.contango : account,
          )
          if (base == "ETH" || quote === "ETH") {
            return unwrapWETH(account, account)
          }
          return result
        })(
          {
            token: quote,
            value:
              needsApproval || quote === "ETH" ? deliveryCostWithPadding : 0n,
            chainId: network.chainData.chainId,
          },
          account,
        )
      },
    ),
  )
}

const submit$ = state((positionId: bigint) =>
  userSubmitDeliver$.pipe(
    withLatestFrom(hasSufficientBalance$(positionId)),
    exhaustMap(([_, isValid]) => {
      if (!isValid) return []
      return deliverPosition$(positionId)
    }),
  ),
)

export const isSubmittingDelivery$ = state(
  (positionId: bigint) => submit$(positionId).pipe(mapDistinct(isTxPending)),
  false,
)
