import {
  Currency,
  getOpeningCostByLeverage$,
  Instrument,
  InstrumentId,
  OpeningCostError,
} from "@/api"
import { onUncaughtError } from "@/utils/error-utils"
import {
  calculateMarginAndLeverage,
  calculateMarketImpact,
  defaultLevarage,
} from "@/utils/financial-utils"
import { baseToMinQty } from "@/utils/mappers"
import { absolute } from "@/utils/maths-utils"
import { justWait, unsuspended, withNullFrom } from "@/utils/observable-utils"
import { sinkSuspense, state, SUSPENSE } from "@react-rxjs/core"
import { combineLatest, concat, finalize, map, of, switchMap } from "rxjs"
import { onFormError, setFormError } from "./error/formErrors"
import { leverageInput$, quantity$ } from "./inputs"
import { formChangeEvents$ } from "./inputsChange"
import { instrument$ } from "./instrument"
import { validQuantity$ } from "./validations"

export const costDataBySymbol$ = state((id: InstrumentId) =>
  combineLatest([leverageInput$, validQuantity$]).pipe(
    switchMap(([leverage, quantity]) => {
      if (!quantity) return of(SUSPENSE)
      return concat(
        of(SUSPENSE),
        justWait(200),
        getOpeningCostByLeverage$(
          id,
          quantity,
          BigInt(Math.round((leverage || defaultLevarage(id)) * 1e18)),
        ).pipe(
          map((openingCost) => {
            const { base } = Instrument[id]
            const spotPrice = absolute(
              (openingCost.spotCost * Currency[base].precisionMultiplier) /
                quantity,
            )
            const price = absolute(
              (openingCost.cost * Currency[base].precisionMultiplier) /
                quantity,
            )
            return {
              ...openingCost,
              quantity,
              spotPrice,
              price,
              instrument: Instrument[id],
              ...Instrument[id],
              ...calculateMarginAndLeverage(openingCost),
            }
          }),
        ),
      )
    }),
    finalize(() => {
      console.log(`teardown ${id}`)
    }),
    onUncaughtError((error) => {
      if (
        error instanceof OpeningCostError &&
        error.name === "InsufficientLiquidity"
      )
        setFormError("insufficientLiquidity", error)
      else {
        setFormError("insufficientLiquidity", null)
        if (error) setFormError("cannotRetrieveQuote", error)
        else setFormError("cannotRetrieveQuote", null)
      }
    })(formChangeEvents$),
    sinkSuspense(),
  ),
)

export const costData$ = instrument$.pipeState(
  switchMap(({ id }) => costDataBySymbol$(id)),
)

export const basisRateBySymbol$ = state((symbol: InstrumentId) =>
  costDataBySymbol$(symbol).pipe(
    map(({ cost, spotCost }) => {
      const { side } = Instrument[symbol]
      const multiplier = side === "Short" ? -1 : 1
      return (Number(cost - spotCost) / Number(spotCost)) * 100 * multiplier
    }),
  ),
)

export const marketImpact$ = state(
  unsuspended(costData$).pipe(
    switchMap((costData) => {
      const { instrument, leverage, base } = costData
      const quantity = baseToMinQty(base)
      return concat(
        getOpeningCostByLeverage$(
          instrument.id,
          quantity,
          BigInt(Math.round(leverage * 1e18)),
        ).pipe(
          map(({ cost }) =>
            calculateMarketImpact({ cost, quantity }, costData),
          ),
        ),
      )
    }),
    withNullFrom(quantity$),
    map(Number),
    onFormError("marketImpact")(formChangeEvents$),
  ),
  0,
)
