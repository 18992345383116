import type { ReturnPromiseType } from "@/utils/types"
import { state } from "@react-rxjs/core"
import { batchable, withContract } from "@unstoppablejs/solidity-bindings"
import { withTicks } from "../utils"
import { batcher } from "./batcher"
import { client } from "./common"
import {
  ContangoPositionNFT,
  positions,
  safeTransferFrom1,
  Transfer,
} from "./contracts/ContangoPositionNFT"
import {
  batch,
  ContangoYield,
  createPosition as createPositionContract,
  deliver as deliverPositionContract,
  forwardPermit as forwardPermitContract,
  modifyCollateral as modifyCollateralContract,
  modifyPosition as modifyPositionContract,
  position,
  unwrapWETH as unwrapWETHContract,
  wrapETH as wrapETHContract,
} from "./contracts/ContangoYield"
import {
  ExecutionProcessorLib,
  PositionClosed,
  PositionDelivered,
  PositionLiquidated,
  PositionUpserted,
} from "./contracts/ExecutionProcessorLib"
import {
  deliveryCostForPosition,
  IContangoQuoter,
  InsufficientLiquidity,
  modifyCostForPositionWithLeverage,
  openingCostForPositionWithLeverage,
  positionStatus,
} from "./contracts/IContangoQuoter"
import { getCurrentNetwork } from "./latestChain"

const contango = batchable(
  batch,
  batcher,
  withContract<ContangoYield | ExecutionProcessorLib>(
    () => getCurrentNetwork().addresses.contango,
    client,
  ),
)

const contangoQuery = batchable(
  batch,
  batcher,
  withContract<ContangoYield | ExecutionProcessorLib>(
    () => getCurrentNetwork().addresses.contango,
    client,
  ),
)

const getRawPosition = contangoQuery.call(position)
export type RawPosition = ReturnPromiseType<typeof getRawPosition>

export const getPosition = async (positionId: bigint): Promise<RawPosition> => {
  const { symbol, protocolFees, ...raw } = await getRawPosition(positionId)
  if (symbol === "") throw new Error()
  return {
    symbol,
    protocolFees,
    ...raw,
  }
}

export const forwardPermit = contango.tx(forwardPermitContract)
export const createPosition = contango.tx(createPositionContract)
export const modifyPosition = contango.tx(modifyPositionContract)
export const modifyCollateral = contango.tx(modifyCollateralContract)
export const wrapETH = contango.tx(wrapETHContract)
export const unwrapWETH = contango.tx(unwrapWETHContract)
export const deliverPosition = contango.tx(deliverPositionContract)

export const positionClosedEvent = contangoQuery.event(PositionClosed)
export const positionDeliveredEvent = contangoQuery.event(PositionDelivered)
export const positionLiquidatedEvent = contangoQuery.event(PositionLiquidated)
export const positionUpsertedEvent = contangoQuery.event(PositionUpserted)

const contangoQuoter = withContract<IContangoQuoter>(
  () => getCurrentNetwork().addresses.contangoQuoter,
  client,
)

export const getOpeningCostForPositionWithLeverage$ = state(
  withTicks(
    contangoQuoter.call(
      openingCostForPositionWithLeverage,
      InsufficientLiquidity,
    ),
  ),
)

export const getModifyPositionCostByLeverage$ = state(
  withTicks(
    contangoQuoter.call(
      modifyCostForPositionWithLeverage,
      InsufficientLiquidity,
    ),
  ),
)

export const getDeliveryCostForPosition = contangoQuoter.call(
  deliveryCostForPosition,
)

export const getPositionStatus = contangoQuoter.call(positionStatus)

const contangoPositionNft = withContract<ContangoPositionNFT>(() => {
  return getCurrentNetwork().addresses.NFT
}, client)

const contangoPositionNftQuery = withContract<ContangoPositionNFT>(() => {
  return getCurrentNetwork().addresses.NFT
}, client)

export const listenToPositionTransfers =
  contangoPositionNftQuery.event(Transfer)
export const getPositions = contangoPositionNftQuery.call(positions)

export const nftSafeTransferFrom = contangoPositionNft.tx(safeTransferFrom1)
