import { onUncaughtError } from "@/utils/error-utils"
import { state } from "@react-rxjs/core"
import { createKeyedSignal } from "@react-rxjs/utils"
import { map, merge, share } from "rxjs"
import { leverageInputChanged$, quantityChanged$ } from "./inputs"

export type FormErrorOrigin =
  // | "marketImpact" // TODO: Add market impact error
  "deltaCost" | "leverageBoundariesForQuantity" | "benchmarkMinQtyCost"

const toErrorKey = (positionId: bigint, origin: FormErrorOrigin) =>
  `positionId: ${positionId} origin: ${origin}`

const [errors$, setFormError] = createKeyedSignal(
  ({ positionId, origin }) => toErrorKey(positionId, origin),
  (positionId: bigint, origin: FormErrorOrigin, error: any) => ({
    positionId,
    origin,
    error,
  }),
)

export const formErrors$ = state(
  (positionId: bigint, origin: FormErrorOrigin) =>
    errors$(toErrorKey(positionId, origin)),
)

export const onEditFormError = (positionId: bigint, origin: FormErrorOrigin) =>
  onUncaughtError((error) => {
    if (error)
      console.error(
        "Uncaught error for editing positionId: ",
        positionId,
        origin,
        error,
      )
    setFormError(positionId, origin, error)
  })

export const formChangeEvents$ = (id: bigint) =>
  merge(quantityChanged$(id), leverageInputChanged$(id)).pipe(share())

export const hasCriticalError$ = state(
  (id: bigint) =>
    formErrors$(id, "benchmarkMinQtyCost").pipe(
      map((error) => error.error !== null),
    ),
  false,
)
