import { InstrumentId } from "./generated"

export interface Position {
  positionId: bigint
  instrumentId: InstrumentId
  quantity: bigint
  collateral: bigint
  openCost: bigint
  fees: bigint
  maturity: bigint
}
export * from "./generated"

export enum PositionExpiryStatus {
  Active = "Active",
  Expiring = "Expiring",
  Expired = "Expired",
}

export interface ERC2612PermitMessage {
  owner: string
  spender: string
  value: bigint
  nonce: bigint
  deadline: bigint
}

export interface RSV {
  r: Uint8Array
  s: Uint8Array
  v: bigint
}

export interface ERC2612PermitDomain {
  name: string
  version: "1" | "2"
  chainId: any
  verifyingContract: string
}

export type SignedERC2612Permit = ERC2612PermitMessage &
  RSV &
  ERC2612PermitDomain

export interface TransactionReceipt {
  transactionHash: string
  transactionIndex: string
  blockNumber: string
  blockHash: string
  cumulativeGasUsed: string // '0x33bc', // 13244
  gasUsed: string // '0x4dc', // 1244
  contractAddress: string // '0xb60e8dd61c5d32be8058bb8eb970870f07233155', // or null, if none was created
  logs: string[]
  logsBloom: string // "0x00...0", // 256 byte bloom filter
  status: string // '0x1'
}
